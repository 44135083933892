import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import { createRoot } from 'react-dom/client'
import 'antd/dist/reset.css'
import { Provider } from 'react-redux'
import { store } from './store'
import { UserProvider } from './contexts/UserContext';  // Import UserProvider


// Replace this with your real Google Client ID

const container = document.getElementById('root');
const root = createRoot(container!); // The '!' asserts that 'container' is not null

root.render(
  <Provider store={store}>
    <UserProvider>
      <App />
      </UserProvider>
  </Provider>
);
