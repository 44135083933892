import React from 'react';
import SectionOrganizer from './SectionOrganizer';


interface PageOrganizerProps {
  sections: any[];
  setSections: React.Dispatch<React.SetStateAction<any[]>>;
  onFieldUpdate: (sectionId: string, fieldId: string, updatedField: any) => void;
  onFieldDelete: (sectionId: string, fieldId: string) => void;
  onRenameSection?: (sectionId: string, newTitle: string) => void;
  pageType: string;
  newlyAddedFieldId?: string | null; // Optional
  setNewlyAddedFieldId?: (id: string | null) => void; // Optional
}

const PageOrganizer: React.FC<PageOrganizerProps> = ({
  sections, setSections, 
  onFieldUpdate, 
  onFieldDelete, 
  onRenameSection = () => {}, 
  pageType,
  newlyAddedFieldId, // Receive the ID
  setNewlyAddedFieldId, // Receive the setter
  }) => {

  // console.log('calling page organizer')
  
  return (
    <div>
      {sections.map((section, index) => (
        <SectionOrganizer
          key={section.id + (section.fields?.length || 0)}
          section={section}
          onFieldUpdate={onFieldUpdate}
          onFieldDelete={onFieldDelete}
          onRenameSection={onRenameSection}
          pageType={pageType}
          newlyAddedFieldId={newlyAddedFieldId} // Pass the ID
          setNewlyAddedFieldId={setNewlyAddedFieldId} // Pass the setter
        />
      ))}
    </div>
  );
};

export default PageOrganizer;