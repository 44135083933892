import React, {useEffect, useState} from 'react';
import { Select, Form } from 'antd';
import axios from "axios";
import dataAll from "../../../utils/dataAll";
import {useTenant} from "../../../contexts/TenantContext";

interface ReferenceLinkFieldRenderProps {
  field: any;
  form?: any;
  referenceData: any;
  handleFieldChange: Function;
  renderType: 'table' | 'section';
  value?: any;
  rowId?: string;
  sectionId?: string;
  dataIndex?: string;
  required?: boolean
}

const ReferenceLinkFieldRender: React.FC<ReferenceLinkFieldRenderProps> = ({
  field,
  form,
  referenceData,
  handleFieldChange,
  renderType,
  value,
  required
}) => {
  const [refData, setRefData] = useState<any[]>([]); // State to hold the fetched data
  const [loading, setLoading] = useState<boolean>(false);
  const { tenantId, companyId } = useTenant();
  const moduleOptions = field.fieldOptions?.modules || [];
  const moduleId =
      moduleOptions.length === 1
          ? moduleOptions[0]._id || moduleOptions[0].moduleId || null
          : null;


  useEffect(() => {
    if (!moduleId) return;
    const fetchReferenceData = async () => {
      setLoading(true);
      try {
        const refModuleData = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, moduleId)
        setRefData(refModuleData || [])
      } catch (error) {
        console.error('Error fetching reference data:', error);
        setRefData([]); // Fallback to an empty array if the API call fails
      } finally {
        setLoading(false);
      }
    };

    fetchReferenceData(); // Call the async function inside the useEffect
  }, [moduleId]);

  if (!field || !referenceData) {
    console.warn("Missing field or referenceData in ReferenceLinkFieldRender.", field);
    return null;
  }

  // const refData = moduleId ? referenceData[moduleId] || [] : [];
  const selectedFields = moduleOptions[0]?.selectedFields || moduleOptions[0]?.selectFields || [];
  const returnFields = moduleOptions[0]?.returnFields || [];

  if (!moduleId || !refData) {
    console.warn(`Module or reference data missing for moduleId: ${moduleId}`);
    return null;
  }

  // Display value using selectedFields
  const getDisplayValue = (item: any) => {
    return selectedFields
        .map((fieldId) => {
          let matchingField;

          // Check if data contains fields directly (first schema type)
          if (item.data?.[fieldId]) {
            matchingField = item.data[fieldId];
          }
          // Check if data has field entries as objects (second schema type)
          else if (typeof item.data === 'object') {
            matchingField = Object.values(item.data).find(
                (field: any) => field.label === fieldId
            );
          }

          if (matchingField && matchingField.value !== undefined && matchingField.value !== null) {
            return matchingField.value.toString().trim();
          }
          return ''; // Fallback if no matching field or value
        })
        .filter((value) => value !== '') // Remove empty fields
        .join(' - ');
  };

  // Handle selection change in Select component
  const handleSelectChange = (selectedId: any) => {
    const selectedItem = refData.find((item: any) => item._id === selectedId);

    if (!selectedItem) {
      console.error('Selected item not found in reference data');
      return;
    }

    const selectedData = returnFields.reduce((acc: any, fieldName: string) => {
      acc[fieldName] = selectedItem[fieldName] || null;
      return acc;
    }, {});

    selectedData.uuid = selectedId;
    selectedData.label = getDisplayValue(selectedItem);

    if (renderType === 'table') {
      handleFieldChange(selectedData);
    } else {
      form.setFieldsValue({ [field.name]: selectedData });
    }
  };

  // Selected value handling
  // Ensure selectedValue matches the value prop of the Option components (item._id)
  let selectedValue;
  if (value) {
    if (typeof value === 'object') {
      selectedValue = value.uuid || value._id;
    } else {
      selectedValue = value;
    }
  } else {
    selectedValue = undefined;
  }

  const label = renderType === 'table' ? undefined : field.name;

  const selectComponent = (
    <Select
      value={selectedValue}
      onChange={handleSelectChange}
      placeholder="Select a record"
      style={{ width: '100%' }}
      optionFilterProp="children"
      showSearch
    >
      {
      //   refData.length === 0 ? (
      //   <Select.Option disabled>No Records Found</Select.Option>
      // ) :
            (
        refData.map((item: any) => (
          <Select.Option key={item._id} value={item._id}>
            {getDisplayValue(item)}
          </Select.Option>
        ))
      )}
    </Select>
  );

  return renderType === 'table' ? (
    selectComponent
   )
    : (
    <Form.Item 
    name={field.name} 
    label={label}
    required={required}
    initialValue={value}
    >
      {selectComponent}
    </Form.Item>
  );
};

export default ReferenceLinkFieldRender;