import React from 'react';
import { Input, Form } from 'antd';

interface TextFieldRenderProps {
  field: any;
  handleFieldChange: Function;
  value?: string;
  renderType?: 'table' | 'section';
  required?: boolean
}

const TextFieldRender: React.FC<TextFieldRenderProps> = ({
  field,
  handleFieldChange,
  value,
  renderType,
  required
}) => {
  if (renderType === 'table') {
    return (
      <Input
        value={value}
        onChange={(e) => handleFieldChange(e.target.value)}
        style={{ width: '100%' }}
      />
    );
  } else {
    return (
      <Form.Item 
        name={field.name} 
        label={field.name}
        required={required}
        initialValue={value}
      >
        <Input onChange={(e) => handleFieldChange(e.target.value)} />
      </Form.Item>
    );
  }
};

export default TextFieldRender;