// StageBuilder.tsx

import React, { useState } from 'react';
import {
  Button,  Input,  Select,  Table,
  message,  Modal,  Form,  Space,
} from 'antd';
import { PlusOutlined, DeleteOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import {reorderTable} from '../../../utils/reorder'
import '../../../styles/stageBuilderModal.css'

interface Field {
  id: string;
  name: string;
  fieldType: string; // Ensure fieldType is included
}

type FieldConfig = 'Hide' | 'View' | 'Edit' | 'Require';

interface Stage {
  id: string;
  name: string;
  fieldConfigs: { [fieldId: string]: FieldConfig };
}

interface StageBuilderProps {
  existingStages?: Stage[];
  allFields: Field[];
  onSaveStages: (stages: Stage[]) => void;
}

const StageBuilder: React.FC<StageBuilderProps> = ({
  existingStages = [],
  allFields,
  onSaveStages,
}) => {
  const [stages, setStages] = useState<Stage[]>(
    existingStages.map((stage) => ({
      ...stage,
      fieldConfigs: allFields.reduce((acc, field) => ({
        ...acc,
        [field.id]: stage.fieldConfigs?.[field.id] || (field.fieldType === 'AutoIncrementId' ? 'View' : 'Edit'),
      }), {} as { [fieldId: string]: FieldConfig }),
    }))
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [copyFromStageId, setCopyFromStageId] = useState<string | null>(null);
  const [newStageName, setNewStageName] = useState('');

  const showAddStageModal = () => {
    setCopyFromStageId(stages.length > 0 ? stages[0].id : null);
    setIsModalVisible(true);
  };

  const handleAddStage = () => {
    if (newStageName.trim() === '') {
      message.error('Stage name cannot be empty.');
      return;
    }

    let fieldConfigs: { [fieldId: string]: FieldConfig } = {};
    if (copyFromStageId) {
      const copyStage = stages.find((s) => s.id === copyFromStageId);
      if (copyStage) {
        fieldConfigs = allFields.reduce((acc, field) => ({
          ...acc,
          [field.id]: copyStage.fieldConfigs[field.id] || 'Edit',
        }), {});
      }
    } else {
      fieldConfigs = allFields.reduce((acc, field) => ({
        ...acc,
        [field.id]: field.fieldType === 'AutoIncrementId' ? 'View' : 'Edit',
      }), {});
    }  

    const newStage: Stage = {
      id: `stage-${Date.now()}`,
      name: newStageName.trim(),
      fieldConfigs,
    };
    setStages([...stages, newStage]);
    setNewStageName('');
    setIsModalVisible(false);
    message.success('Stage added successfully!');
  };

  const handleRemoveStage = (stageId: string) => {
    if (stages.length === 1) {
      message.error('At least one stage is required.');
      return;
    }
    setStages(stages.filter((stage) => stage.id !== stageId));
    message.success('Stage removed successfully!');
  };

  const handleFieldConfigChange = (
    stageId: string,
    fieldId: string,
    value: FieldConfig
  ) => {
    const field = allFields.find(f => f.id === fieldId);
    const isAutoIncrementId = field?.fieldType === 'AutoIncrementId';

    if (isAutoIncrementId && !['Hide', 'View'].includes(value)) {
      message.error('Auto Increment fields can only be View or Hide.');
      return;
    }  

    const updatedStages = stages.map((stage) => {
      if (stage.id === stageId) {
        return {
          ...stage,
          fieldConfigs: {
            ...stage.fieldConfigs,
            [fieldId]: value,
          },
        };
      }
      return stage;
    });
    setStages(updatedStages);
  };

  const moveStageUp = (index: number) => {
    if (index === 0) return; // Already at the top
    const reorderedStages = reorderTable<Stage>(stages, index, index - 1); // Explicitly specify <Stage>
    setStages(reorderedStages);
  };

  const moveStageDown = (index: number) => {
    if (index === stages.length - 1) return; // Already at the bottom
    const reorderedStages = reorderTable<Stage>(stages, index, index + 1); // Explicitly specify <Stage>
    setStages(reorderedStages);
  };

  const saveStages = () => {
    onSaveStages(stages);
    message.success('Stages saved successfully!');
  };

  const columns = [
    {
      title: 'Field Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left' as const,
      render: (text: string) => <div className='stage-field-names'>{text}</div>,
    },
    // Dynamically add a column for each stage
    ...stages.map((stage, index) => ({
      title: (
        <Space direction="vertical"
          className='stage-columns'
        >
          <Input
            value={stage.name}
            onChange={(e) => {
              const updatedStages = stages.map((s) =>
                s.id === stage.id ? { ...s, name: e.target.value } : s
              );
              setStages(updatedStages);
            }}
            placeholder="Stage Name"
            className='stage-name-input'
            // style={{ width: 200, marginBottom: 8 }}
          />
          <Space className='test-2'>
            <div className='stage-columns-header-cell'>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => moveStageUp(index)}
              disabled={index === 0}
              title="Move Up"
            />
            <Button  className='test-3'
              type="link"
              icon={<ArrowRightOutlined />}
              onClick={() => moveStageDown(index)}
              disabled={index === stages.length - 1}
              title="Move Down"
            />
            <Button
              type="link"
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveStage(stage.id)}
              danger
              title="Delete Stage"
            />
            </div>
          </Space>
        </Space>
      ),
      dataIndex: stage.id,
      key: stage.id,
      render: (_: any, record: Field) => {
        const field = allFields.find(f => f.id === record.id);
        const isAutoIncrementId = field?.fieldType === 'AutoIncrementId';
        
        // Debugging Log
        console.log(`Field: ${field?.name}, Type: ${field?.fieldType}, isAutoIncrementId: ${isAutoIncrementId}`);
        
        return (
          <div className='stage-builder-cell'>
            <Select<FieldConfig>
              value={stage.fieldConfigs[record.id] || (isAutoIncrementId ? 'View' : 'Edit')}
              onChange={(value) =>
                handleFieldConfigChange(stage.id, record.id, value)
              }
              options={
                isAutoIncrementId
                  ? [
                      { label: 'Hide', value: 'Hide' },
                      { label: 'View', value: 'View' },
                    ]
                  : [
                      { label: 'Hide', value: 'Hide' },
                      { label: 'View', value: 'View' },
                      { label: 'Edit', value: 'Edit' },
                      { label: 'Require', value: 'Require' },
                    ]
              }
            />
          </div>
        );
      },
      // width: 150,
    })),
  ];

  const dataSource = allFields.map((field) => ({
    key: field.id,
    id: field.id,
    name: field.name,
    fieldType: field.fieldType
  }));

  return (
    <div className='stage-modal-container'>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={showAddStageModal}
        style={{ marginBottom: '20px' }}
      >
        Add Stage
      </Button>

      <Button
        type="primary"
        onClick={saveStages}
        style={{ marginTop: '20px', marginLeft: '10px' }}
      >
        Save Stages
      </Button>

      <Table
        className='stage-builder-table'
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        rowKey="id"
        scroll={{ x: 'max-content', y: '60vh' }} // Added y for vertical scroll
        bordered
        rowClassName='stage-builder-row'
        tableLayout='fixed'
      />

      <Modal
        title="Add New Stage"
        open={isModalVisible}
        onOk={handleAddStage}
        onCancel={() => setIsModalVisible(false)}
        okText="Add Stage"
      >
        <Form layout="vertical">
          <Form.Item label="Stage Name" required>
            <Input
              value={newStageName}
              onChange={(e) => setNewStageName(e.target.value)}
              placeholder="Enter Stage Name"
            />
          </Form.Item>
          {stages.length > 0 && (
            <Form.Item label="Copy Field Configurations From">
              <Select
                allowClear
                placeholder="Select a stage to copy from"
                value={copyFromStageId}
                onChange={(value) => setCopyFromStageId(value)}
              >
                {stages.map((stage) => (
                  <Select.Option key={stage.id} value={stage.id}>
                    {stage.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {stages.length === 0 && (
            <Form.Item>
              <p>No existing stages to copy from. All fields will default to Edit.</p>
            </Form.Item>
          )}
        </Form>
      </Modal>
    </div>
  );
};

export default StageBuilder;