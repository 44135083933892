export const renderFieldObjects = (fieldType, valueArray) => {
    // If valueArray is not an array, treat it as a direct value
    if (!Array.isArray(valueArray)) {
        return handleDirectValue(fieldType, valueArray);
    }

    switch (fieldType) {
        case 'ReferenceLinkField': {
            const valueEntry = valueArray.find((v) => v.Key === 'value');
            const values = Array.isArray(valueEntry?.Value) ? valueEntry.Value : valueArray;
            const uuid = values.find((v) => v.Key === 'uuid')?.Value || null;
            const label = values.find((v) => v.Key === 'label')?.Value || '';
            return { uuid, label };
        }
        case 'DateField': {
            const dateEntry = valueArray.find((v) => v.Key === 'value');
            return dateEntry ? dateEntry.Value : null;
        }
        case 'Dropdown': {
            return valueArray.find((v) => v.Key === 'value')?.Value || '';
        }
        case 'PhoneNumber': {
            const phone = valueArray.find((v) => v.Key === 'phone')?.Value || '';
            const ext = valueArray.find((v) => v.Key === 'ext')?.Value || '';
            return { phone, ext };
        }
        default: {
            return valueArray.find((v) => v.Key === 'value')?.Value ?? '';
        }
    }
};

// Handle cases where valueArray is a direct value instead of key-value pairs
const handleDirectValue = (fieldType, value) => {
    switch (fieldType) {
        case 'ReferenceLinkField':
            return { uuid: null, label: value || '' };
        case 'DateField':
            return value || null;
        case 'Dropdown':
            return value || '';
        case 'PhoneNumber':
            return { phone: value || '', ext: '' };
        default:
            return value ?? '';
    }
};