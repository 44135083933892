import React, { useState } from "react";
import {Button, InputNumber, Card, Typography, Radio, Modal} from "antd";
import '../styles/paddle.css'

const { Title, Text } = Typography;

declare global {
    interface Window {
        Paddle: any;
    }
}

const PaddleIntegration: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [licenseCount, setLicenseCount] = useState(1);
    const [billingPlan, setBillingPlan] = useState<"monthly" | "annual">("monthly");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);


    const initializePaddle = async () => {
        if (!window.Paddle) {
            try {
                const script = document.createElement("script");
                script.src = "https://cdn.paddle.com/paddle/v2/paddle.js";
                script.async = true;
                document.body.appendChild(script);

                await new Promise((resolve, reject) => {
                    script.onload = () => resolve(window.Paddle);
                    script.onerror = () => reject(new Error("Paddle script failed to load."));
                });
            } catch (error) {
                console.error("Failed to load Paddle:", error);
                setErrorMessage("Failed to initialize Paddle. Please try again later.");
                return false;
            }
        }

        try {
            const Paddle = window.Paddle;
            Paddle.Environment.set("sandbox"); // Switch to "production" in live mode
            Paddle.Initialize({
                token: "test_cb2eb658e7cc6ae40b76cf34301", // Replace with your token
            });
            return true;
        } catch (error) {
            console.error("Failed to initialize Paddle:", error);
            setErrorMessage("Failed to initialize Paddle. Please try again later.");
            return false;
        }
    };

    const showSuccessModal = (data: any) => {
        Modal.success({
            title: "Thank You for Your Purchase!",
            content: (
                <>
                    <Text>Your purchase was successful. Here are the details:</Text>
                    <div style={{ marginTop: "1rem", textAlign: "left" }}>
                        <p><strong>Checkout ID:</strong> {data.checkout_id}</p>
                        <p><strong>Quantity:</strong> {licenseCount}</p>
                    </div>
                </>
            ),
            onOk: () => {
                console.log("Modal closed");
                setIsModalOpen(false);
            },
        });
    };

    const handleCheckout = async () => {
        if (!(await initializePaddle())) return;

        const priceId =
            billingPlan === "monthly"
                ? "pri_01jgznhwmjx3w7rxdehqes1gmk" // Replace with your monthly price ID
                : "pri_01jh0s0mm4mr9m9ghq8jv3zvtf"; // Replace with your annual price ID

        window.Paddle.Checkout.open({
            items: [
                {
                    priceId,
                    quantity: licenseCount,
                },
            ],
            // successUrl: "https://localhost:3000/home", // Replace this with your backend success endpoint
            successCallback: (data: any) => {
                console.log("Checkout success:", data);
                // Open success modal
                showSuccessModal(data);
            },
            closeCallback: () => {
                console.log("Checkout closed");
                setIsModalOpen(false);
            },
        });
        setIsModalOpen(true);
    };

    const handlePaymentSuccess = (data: any) => {
        fetch("/api/paddle/success", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((response) => {
                console.log("Backend synced:", response);
                setIsModalOpen(false);
            })
            .catch(console.error);
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
            <Card style={{ width: 400, textAlign: "center", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                <Title level={3}>Manage Billing</Title>
                <Text style={{ display: "block", marginBottom: "1rem" }}>
                    Select a billing plan and choose the number of licenses (one per user).
                </Text>

                {/* Billing Plan Selector */}
                <Radio.Group
                    value={billingPlan}
                    onChange={(e) => setBillingPlan(e.target.value)}
                    style={{ width: "100%" }}
                    id="billing-plan-group"
                >
                    <Radio.Button value="monthly" id="monthly-plan">
                        <div className="radio-content">
                            <span className="radio-title">Monthly</span>
                            <span className="radio-subtext">$29/month</span>
                        </div>
                    </Radio.Button>
                    <Radio.Button value="annual" id="annual-plan">
                        <div className="radio-content">
                            <span className="radio-title">Annual</span>
                            <span className="radio-subtext">$290/year</span>
                            <span className="radio-extra">(2 months free!)</span>
                        </div>
                    </Radio.Button>
                </Radio.Group>
                <InputNumber
                    min={1}
                    value={licenseCount}
                    onChange={(value) => setLicenseCount(value || 1)}
                    id="license-count"
                    style={{ width: "100%" }}
                />
                <Button type="primary" onClick={handleCheckout} id="checkout-button">
                    Proceed to Checkout
                </Button>

                {isModalOpen && <div id="paddle-checkout-modal"></div>}
            </Card>
        </div>
    );
};

export default PaddleIntegration;