import React, { useState, useEffect } from 'react';
import { Input, Checkbox, List, Select } from 'antd';
import dataAll from "../../../utils/dataAll";
import { ValueType } from 'rc-input/lib/interface';

const {Option} = Select;

export const ReferenceLinkFieldConfig = ({
                                           fieldOptions,
                                           setFieldOptions,
                                           tenantId,
                                           companyId,
                                           currentModuleId,
                                           currentModuleName,
                                         }) => {
  const [activeModules, setActiveModules] = useState([]);
  const [loadingModules, setLoadingModules] = useState(true);
  const [modulesError, setModulesError] = useState(null);

  // Set initial values based on fieldOptions
  const [selectedModuleIds, setSelectedModuleIds] = useState(
      fieldOptions.modules?.map((mod: { moduleId: any; }) => mod.moduleId) || []
  );
  const [selectedModules, setSelectedModules] = useState(fieldOptions.modules || []);

  // Fetch active modules from the backend
  useEffect(() => {
    const fetchAllModules = async () => {
      try {
        const allModules = await dataAll.dataExtractUtility.getAllModules(tenantId, companyId)
        let refModules = allModules.filter((module: { pageType: string; }) => module.pageType === 'Reference');
        setActiveModules(refModules);
        setLoadingModules(false)
      } catch (error) {
        setModulesError(error);
        setLoadingModules(false);
        console.error(error);
      }
    }

    fetchAllModules();
  }, [tenantId, companyId, currentModuleId, currentModuleName]);

  useEffect(() => {
    if (fieldOptions.modules) {
      setSelectedModules(fieldOptions.modules);
      setSelectedModuleIds(fieldOptions.modules.map((mod: { moduleId: any; }) => mod.moduleId));
    }
  }, [fieldOptions.modules]);

  const onModuleSelectionChange = async (newSelectedModuleIds: any[]) => {
    setSelectedModuleIds(newSelectedModuleIds);

    const modulesWithTypes = await Promise.all(
        newSelectedModuleIds.map(async (moduleId: any) => {
          try {
            const modDataRaw = await dataAll.dataExtractUtility.getModuleData(moduleId)
            const moduleData = modDataRaw.moduleData
            // const response = await axios.get(
            //     `${API_URL}/modules/${moduleId}`
            // );
            // const moduleData = response.data;

            return {
              moduleId: moduleData._id,
              moduleName: moduleData.moduleName,
              fields: moduleData.sections.map((section: { id: any; title: any; type: any; fields: any[]; }) => ({
                id: section.id,
                title: section.title,
                type: section.type,
                fields: section.fields.map((field) => ({
                  id: field.id,
                  type: field.type,
                  name: field.name,
                  fieldOptions: field.fieldOptions || {},
                })),
              })),
              label: moduleData.moduleName,
              selectedFields: [],
              returnFields: [],
            };
          } catch (error) {
            console.error('Error fetching module data:', error);
            return null;
          }
        })
    );

    const validModules = modulesWithTypes.filter((mod) => mod !== null);
    setSelectedModules(validModules);
    setFieldOptions((prevFieldOptions: any) => ({
      ...prevFieldOptions,
      modules: validModules,
    }));
  };

  const handleModuleConfigChange = (moduleId: any, key: string, value: string) => {
    setSelectedModules((prevSelectedModules: { moduleId: any; }[]) => {
      const updatedModules = prevSelectedModules.map((mod: { moduleId: any; }) =>
          mod.moduleId === moduleId ? {...mod, [key]: value} : mod
      );

      setFieldOptions((prevFieldOptions: any) => ({
        ...prevFieldOptions,
        modules: updatedModules,
      }));

      return updatedModules;
    });
  };

  return (
      <div>
        <div style={{marginBottom: '10px'}}>
          <label>Select Reference Modules:</label>
          {loadingModules ? (
              <p>Loading modules...</p>
          ) : modulesError ? (
              <p>Error loading modules.</p>
          ) : (
              <Checkbox.Group
                  style={{width: '100%'}}
                  value={selectedModuleIds}
                  onChange={onModuleSelectionChange}
              >
                <List
                    dataSource={activeModules}
                    renderItem={(module) => (
                        <List.Item>
                          <Checkbox value={module._id}>{module.moduleName}</Checkbox>
                        </List.Item>
                    )}
                />
              </Checkbox.Group>
          )}
        </div>

        {selectedModules.map((
              mod: { moduleId: React.Key; moduleName: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal; label: ValueType; selectedFields: any; fields: any[]; returnFields: any; }) => (
        <div
          key={mod.moduleId}
          style={{
            marginBottom: '20px',
            padding: '10px',
            border: '1px solid #ccc',
          }}
        >
          <h4>{mod.moduleName} Configuration</h4>
          <div style={{ marginBottom: '10px' }}>
            <label>Type Label:</label>
            <Input
              value={mod.label}
              onChange={(e) => handleModuleConfigChange(mod.moduleId, 'label', e.target.value)}
              placeholder="Type Label"
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Select Fields:</label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              value={mod.selectedFields}
              onChange={(values) => handleModuleConfigChange(mod.moduleId, 'selectedFields', values)}
              placeholder="Select fields"
            >
              {mod.fields && mod.fields.length > 0 ? (
                  mod.fields.flatMap((section) =>
                      section.fields.map((field: { id: React.Key; name: any; }) => (
                          <Option key={field.id} value={field.id}>
                            {field.name || "Unnamed Field"}
                          </Option>
                      ))
                  )
              ) : (
                  <Option disabled>No fields available</Option>
              )}
            </Select>
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Return Fields:</label>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              value={mod.returnFields}
              onChange={(values) => handleModuleConfigChange(mod.moduleId, 'returnFields', values)}
              placeholder="Return fields"
            >
              {mod.fields && mod.fields.length > 0 ? (
                  mod.fields.flatMap((section) =>
                      section.fields.map((field: { id: React.Key; name: any; }) => (
                          <Option key={field.id} value={field.id}>
                            {field.name || "Unnamed Field"}
                          </Option>
                      ))
                  )
              ) : (
                  <Option disabled>No fields available</Option>
              )}
            </Select>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ReferenceLinkFieldConfig;
