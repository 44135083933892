import React from 'react';
import ReferenceLinkFieldRender from './ReferenceLinkFieldRender';
import TextFieldRender from './TextFieldRender';
import DateFieldRender from './DateFieldRender';
import NumberFieldRender from './NumberFieldRender';
import LineNumberRender from './LineNumberRender';
import CalculationFieldRender from './CalculationFieldRender';
import DropdownRender from './DropdownRender';
import AutoIncrementIDRender from './AutoIncrementIdRender';
import AggregateFieldRender from './AggregateFieldRender';
import EmailFieldRender from './EmailRender';
import PhoneNumberRender from './PhoneNumberRender';
import '../../../styles/fields.css'
import OneToManyFieldRender from './OneToManyFieldRender';
import { render } from 'react-dom';

export const renderField = (
  field: any,
  sectionId: string,
  form: any,
  referenceData: any,
  handleFieldChange: Function,
  moduleData: any,
  tableData: any,
  rowData?: any,
  columns?: any[],
  isEditing?: boolean,
  tenantID?: number,
  companyID?: number,
  moduleID?: string,
  itemId?: string, // Accept itemId as an optional parameter
  required?: boolean,
  renderType: 'table' | 'section' = 'section', // Add renderType parameter
  existingRecord?,
  isNewSchema?: boolean,
  fieldValue?: any,
  rowIndex?: number
) => {
  if (!field || !field.type) {
    console.error('Field is undefined or does not have a type:', field);
    return null;
  }
  //This is just for reference
  //Other field types can't be required because they're automatic. 
  // const requiredFieldTypes = ['TextField', 'Dropdown', 'NumberField', 'ReferenceLinkField', 'PhoneNumber', 'EmailField'] 
  
  const key = field.id || field.name;

  let value;

  if (isNewSchema) {
    // Use `field.id` for new schema
    value = rowData?.[field.id]?.value ?? null;
  } else {
    // Use `field.name` for old schema
    value = rowData?.[field.name] ?? null;
  }

  switch (field.type) {
    case 'TextField':
      return (
        <TextFieldRender
          key={key}
          field={field}
          handleFieldChange={handleFieldChange}
          value={fieldValue}
          renderType={field.renderType}
          required={required}
        />
      );
    case 'DateField':
      const safeHandleFieldChange = rowData ? handleFieldChange : () => {};

      return (
        <DateFieldRender
          key={key}
          field={field}
          form={form}
          handleFieldChange={safeHandleFieldChange}
          value={fieldValue} //{rowData ? rowData[field.dataIndex] : form.getFieldValue(field.name)}
          renderType={field.renderType || 'section'}
          required={required}
        />
      );
    case 'NumberField':
      return (
        <NumberFieldRender
          key={key}
          field={field}
          handleFieldChange={handleFieldChange}
          value= {fieldValue} //{rowData ? rowData[field.dataIndex] : undefined}
          renderType={field.renderType}
          required={required}
        />
      );
    case 'Dropdown':
      return (
        <DropdownRender
          key={key}
          fieldId={field.id}
          fieldname={field.name}
          fieldOptions={field.fieldOptions}
          required={required}
          value={fieldValue} //{rowData ? rowData[field.dataIndex] : undefined} // Ensure this is a string
          onChange={(value: string) => handleFieldChange(value)} // Simplified handler
          renderType={field.renderType}
        />
      );
    case 'ReferenceLinkField':
      return (
        <ReferenceLinkFieldRender
          key={key}
          field={field}
          form={form}
          referenceData={referenceData}
          handleFieldChange={(value: any) => handleFieldChange(value)}
          renderType={field.renderType || 'section'}
          value={fieldValue} //{rowData ? rowData[field.dataIndex] : form.getFieldValue(field.name)}
          required={required}
        />
      );
    case 'EmailField':
      return (
        <EmailFieldRender
          key={key}
          field={field}
          handleFieldChange={(value: any) => handleFieldChange(value)}
          renderType={field.renderType || 'section'}
          value= {fieldValue} //{rowData ? rowData[field.dataIndex] : form.getFieldValue(field.name)}
          required={required}
        />
      );
      case 'PhoneNumber': {
        // Ensure the fieldValue is always in the correct structure
        const normalizedValue =
        fieldValue && typeof fieldValue === 'object'
          ? { phone: fieldValue.phone || '', ext: fieldValue.ext || '' } // Default to empty strings for missing values
          : { phone: '', ext: '' }; // Ensure consistent structure for the render component
    
    
        
        return (
          <PhoneNumberRender
            key={key}
            field={field}
            handleFieldChange={(value) => {
              form.setFieldsValue({ [field.name]: value }); // Update the form value
            }}
            value={normalizedValue} // Pass the full normalized object
            form={form}
            renderType={field.renderType || 'section'}
            required={required}
          />
        );
      }
    case 'AutoIncrementId':
      return (
        <AutoIncrementIDRender
          key={key}
          field={field}
          form={form}
          isEditing={isEditing || false}
          value={fieldValue}
        />
      );
    case 'LineNumber':
      return (
        <LineNumberRender
          key={key}
          field={field}
          rowIndex={rowIndex}
          handleFieldChange={handleFieldChange}
        />
      );
    case 'CalculationField':
      return (
        <CalculationFieldRender
          key={key}
          field={field}
          rowData={rowData}
          columns={columns}
          handleFieldChange={handleFieldChange}
          sectionId={sectionId}
          rowId={rowData.id}
        />
      );
    case 'AggregateField':
      // const itemId = rowData ? rowData['uuid'] : ''; // Use a specific field to fetch itemId
      return (
        <AggregateFieldRender
          key={key}
          tenantId={tenantID}
          companyId={companyID}
          moduleId={moduleID}
          fieldId={field.id}
          itemId={itemId}
          name={field.name}
          // aggModuleId={aggModuleId}
        />
      );
      case 'OneToManyField':
        return (
          <OneToManyFieldRender
            key={key}
            field={field}
            form={form}
            referenceData={referenceData}
            handleFieldChange={handleFieldChange} // Already passing field.id and data array
            moduleData={moduleData}
            tableData={tableData}
            fieldValue={fieldValue || []}
            renderType={renderType} // Pass renderType
            />
        );
    default:
      return null;
  }
};
