import React, { useEffect, useState, useRef } from 'react';
import { Modal, Form, Button, Table, message, Steps } from 'antd';
import dayjs from 'dayjs';
import axios from 'axios';
import { DeleteOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { renderField } from './FieldRender/_RenderField';
import dataAll from "../../utils/dataAll";
import {useTenant} from "../../contexts/TenantContext";
import {initializePage} from "../../utils/initializeRender";

interface TransactionPageRenderProps {
  moduleData: any;
  existingRecord?: any;
  records: any[];
  setRecords: (updated: any[]) => void;
  closeModal: () => void;
}

interface Stage {
  id: string;
  name: string;
  fields: { fieldId: string; isCarryover: boolean }[];
  fieldConfigs?: Record<string, string>;
}

const TransactionPageRender: React.FC<TransactionPageRenderProps> = ({
  moduleData,
  existingRecord,
  records,
  setRecords,
  closeModal
}) => {
  const [form] = Form.useForm();
  const [tableData, setTableData] = useState<Record<string, any[]>>({});
  const [referenceData, setReferenceData] = useState<Record<string, any[]>>({});
  const [currentStage, setCurrentStage] = useState<Stage | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedAggregates, setSelectedAggregates] = useState<Record<string, string>>({});
  const [clickedDropdown, setClickedDropdown] = useState<string | null>(null);
  const { tenantId, companyId } = useTenant();
  const rowCounters = useRef<Record<string, number>>({});


  // ----------------------------
  // 1. useEffect: Initialize
  // ----------------------------
  useEffect(() => {
    // console.log('form vals to start: ', form.getFieldsValue());
    if (!moduleData || isInitialized) return;
    if (!moduleData.stages || moduleData.stages.length === 0) {
      message.warning('You must configure stages before transaction pages are visible.');
      return;
    }

    initializePage.initializeTransactionForm(
        moduleData,
        existingRecord,
        form,
        setTableData,
        rowCounters
    );
    const stage = moduleData.stages.find((s) => s.name === existingRecord?.stageId) || moduleData.stages[0];
    setCurrentStage(stage);
    // console.log('form vals to end: ', form.getFieldsValue());
    console.log('Form Values:', form.getFieldsValue());
    setIsInitialized(true);
  }, [moduleData, existingRecord, isInitialized, form]);


  // useEffect(() => {
  //   if (!moduleData || isInitialized) return;
  //
  //   // Determine stage
  //   const stage = moduleData.stages.find((s: Stage) => s.name === existingRecord?.stageId) || moduleData.stages[0];
  //   setCurrentStage(stage);
  //
  //   // If editing existing record, parse data
  //   if (existingRecord) {
  //     // const existingData  = existingRecord;
  //     const formValues: Record<string, any> = {};
  //
  //     moduleData.sections.forEach((section: any) => {
  //       if (section.type === 'section') {
  //         // Standard fields
  //         section.fields.forEach((fld: any) => {
  //           const savedObj = existingRecord[fld.id];
  //           if (!savedObj) {
  //             formValues[fld.name] = fld.type === 'DateField' ? null : ''; // Default for missing fields
  //             return;
  //           }
  //
  //           // Handle ReferenceLinkField
  //           if (fld.type === 'ReferenceLinkField') {
  //             // existingRecord[fld.id] might look like:
  //             // { label: "ReferenceLinkField_1737687911652", value: { uuid: ..., label: ... } }
  //             const savedObj = existingRecord[fld.id];
  //             if (savedObj?.value) {
  //               formValues[fld.name] = savedObj.value;
  //               // or formValues[fld.id] = savedObj.value — just be consistent with how you do name={...}
  //             } else {
  //               formValues[fld.name] = null;
  //             }
  //             return;
  //           }
  //
  //           // Handle DateField
  //           if (fld.type === 'DateField') {
  //             formValues[fld.name] = dayjs(savedObj).isValid() ? dayjs(savedObj) : null;
  //             return; // Stop further processing for this field
  //           }
  //
  //
  //           // Handle structured object with a "Value" key
  //           if (typeof savedObj === 'object' && 'Value' in savedObj) {
  //             formValues[fld.name] = savedObj.Value || '';
  //             return; // Stop further processing for this field
  //           }
  //
  //           // Handle flat structure for non-Date fields
  //           formValues[fld.name] = savedObj;
  //
  //         });
  //       } else if (section.type === 'table') {
  //         // Table data
  //
  //         const existingRows = existingRecord[section.id];
  //         const flatteningRows = existingRows?.filter((row: any) => row.Key === "value" && Array.isArray(row.Value))
  //         const flattenedRows = flatteningRows.map((rowObj: any, index: number) => {
  //           // if (rowObj.Key === 'label') {
  //           //   return []
  //           // }
  //           const newRow: any = { id: `row_${section.id}_${index}` };
  //           // Ensure rowObj.Value exists and is an array with at least one element
  //           const colArray = Array.isArray(rowObj.Value) && rowObj.Value.length > 0 ? rowObj.Value[0] : [];
  //
  //           if (Array.isArray(colArray)) {
  //             colArray.forEach((colObj: any) => {
  //               if (colObj && colObj.Key && Array.isArray(colObj.Value)) {
  //                 const colId = colObj.Key;
  //                 const colValArr = colObj.Value;
  //                 const colValueObj = colValArr.find((item: any) => item.Key === "value");
  //                 const colValue = colValueObj ? colValueObj.Value : null; // Default to null if not found
  //                 const colDef = findColumnDef(section.id, colId);
  //
  //                 if (!colDef) {
  //                   console.error('Cannot find column for colId:', colId);
  //                   return;
  //                 }
  //
  //                 if (colDef.type === 'DateField' && colValue) {
  //                   newRow[colDef.id] = dayjs(colValue).isValid() ? dayjs(colValue) : null;
  //                 } else if (colDef.type === 'Dropdown' && colValue) {
  //                   newRow[colDef.id] = colValue
  //                 } else if (colDef.type === 'ReferenceLinkField' && colValue) {
  //                   // Extract UUID and label
  //                   const uuid = colValue.find((entry: any) => entry.Key === 'uuid')?.Value || null;
  //                   const label = colValue.find((entry: any) => entry.Key === 'label')?.Value || null;
  //                   newRow[colDef.id] = uuid
  //                       ? {
  //                         uuid,  // The unique identifier
  //                         label, // The display label
  //                       }
  //                       : null; // Default to null if uuid is not found
  //                 } else {
  //                   newRow[colDef.id] = colValue ?? null;
  //                 }
  //               }
  //             });
  //           } else {
  //             console.error('Invalid rowObj.Value structure', rowObj.Value);
  //           }
  //
  //           return newRow;
  //         }) || [];
  //         rowCounters.current[section.id] = flattenedRows.length;
  //         setTableData((prev) => ({ ...prev, [section.id]: flattenedRows }));
  //       }
  //     });
  //
  //     form.setFieldsValue(formValues);
  //   } else {
  //     // New record
  //     const newValues: Record<string, any> = {};
  //     moduleData.sections.forEach((section: any) => {
  //       if (section.type === 'section') {
  //         section.fields.forEach((fld: any) => {
  //           newValues[fld.id] = fld.type === 'DateField' ? null : '';
  //         });
  //       } else if (section.type === 'table') {
  //         setTableData((prev) => ({ ...prev, [section.id]: newValues[section.id] }));
  //       }
  //     });
  //     form.setFieldsValue(newValues);
  //   }
  //
  //   setIsInitialized(true);
  // }, [moduleData, existingRecord, isInitialized, form]);

  /** Utility to find a column definition in the module's tableData */
  const findColumnDef = (sectionId: string, colId: string) => {
    const tableDef = moduleData.tableData?.find((table: any) => table.id === sectionId);
    if (!tableDef) return null;
    return tableDef.columns.find((c: any) => c.id === colId);
  };

  // ----------------------------
  // 2. Collect Reference Data
  // ----------------------------

  useEffect(() => {
    if (!moduleData) return;
    initializePage.fetchReferenceData(moduleData, referenceData, setReferenceData, tenantId, companyId);
  }, [moduleData, referenceData]);

  // useEffect(() => {
  //   if (!moduleData) return;
  //   // Gather all reference modules from both sections + table columns
  //   const modIds: string[] = [];
  //   moduleData.sections.forEach((section: any) => {
  //     if (section.type === 'section') {
  //       section.fields.forEach((fld: any) => {
  //         if (fld.type === 'ReferenceLinkField' && fld.fieldOptions?.modules) {
  //           fld.fieldOptions.modules.forEach((mod: any) => {
  //             if (mod.moduleId && !modIds.includes(mod.moduleId)) {
  //               modIds.push(mod.moduleId);
  //             }
  //           });
  //         }
  //       });
  //     } else if (section.type === 'table') {
  //       const tableDef = moduleData.tableData?.find((table: any) => table.id === section.id);
  //       if (tableDef && tableDef.columns) {
  //         tableDef.columns.forEach((col: any) => {
  //           if (col.type === 'ReferenceLinkField' && col.fieldOptions?.modules) {
  //             col.fieldOptions.modules.forEach((mod: any) => {
  //               if (mod.moduleId && !modIds.includes(mod.moduleId)) {
  //                 modIds.push(mod.moduleId);
  //               }
  //             });
  //           }
  //         });
  //       }
  //     }
  //   });
  //
  //   // For each Reference Link Field moduleId, fetch reference data
  //   modIds.forEach((mId) => {
  //     if (!referenceData[mId]) {
  //       const fetchRefData = async () => {
  //         try {
  //           const refData = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, mId)
  //           const refArray = refData.map((r: any) => ({
  //             ...r.data,
  //             _id: r._id
  //           }))
  //           setReferenceData((prev) => ({ ...prev, [mId]: refArray }));
  //         } catch (error) {
  //           console.error(error);
  //         }
  //       }
  //       fetchRefData();
  //
  //       // const fetchData = async () => {
  //       //   try {
  //       //     const resp = await axios.get(`${API_URL}/referencedata?moduleId=${mId}`);
  //       //     // Typically, each record is { data: { ...fields }, _id: ... }
  //       //     // We'll store an array of data objects for easy searching
  //       //     const arr = resp.data.map((r: any) => ({
  //       //       ...r.data,
  //       //       _id: r._id,
  //       //     }));
  //       //     setReferenceData((prev) => ({ ...prev, [mId]: arr }));
  //       //   } catch (err) {
  //       //     console.error('Error fetching reference data for', mId, err);
  //       //
  //       //   }
  //       // };
  //       // fetchData();
  //     }
  //   });
  // }, [moduleData, referenceData]);

  // ----------------------------
  // 3. Create + Submit Record
  // ----------------------------
  async function fillOneAutoIncrement(
    fieldId: string,
    fieldName: string,
    moduleData: any,
    API_URL: string
  ): Promise<string> {
    try {
      return await dataAll.dataExtractUtility.getNextAutoIncrement(tenantId, companyId, moduleData._id, fieldId);
      // return nextIncrement;
    } catch (err) {
      console.error(`Error fetching auto-increment for field "${fieldName}"`, err);
      return ''; // fallback empty

    }
  }

  async function buildStandardData(formVals: any) {
    const standardData: Record<string, any> = {};

    // Loop over sections in a standard for...of, which can be async
    for (const section of moduleData.sections.filter((s: any) => s.type === 'section')) {
      for (const fld of section.fields) {
        const rawVal = formVals[fld.name];
        let finalVal = rawVal;

        if (fld.type === 'AutoIncrementId') {
          // If new record + empty => fetch
          const isNew = !existingRecord;
          const userVal = rawVal?.value ?? rawVal;
          if (isNew && !userVal) {
            finalVal = await fillOneAutoIncrement(fld.id, fld.name, moduleData, API_URL);
          } else {
            finalVal = userVal;
          }
        } else if (fld.type === 'DateField' && rawVal) {
          finalVal = dayjs(rawVal).isValid() ? dayjs(rawVal).toISOString() : null;
        } else if (rawVal?.value !== undefined) {
          finalVal = rawVal.value;
        }

        standardData[fld.id] = {
          label: fld.name,
          value: finalVal ?? '',
        };
      }
    }

    return standardData;
  }

  const handleSubmit = async () => {
    const formVals = form.getFieldsValue();
    const finalRecord = await initializePage.buildTransactionData(moduleData, formVals, tableData, existingRecord);
    await doSubmit(finalRecord);
    closeModal();
  };

  // const handleSubmit = async () => {
  //   try {
  //     const formVals = form.getFieldsValue();
  //     // console.log('formVals: ', formVals)
  //
  //     // 3A. Required check
  //     const missing: string[] = [];
  //     if (currentStage?.fieldConfigs) {
  //       moduleData.sections
  //         .filter((s: any) => s.type === 'section')
  //         .forEach((section: any) => {
  //           section.fields.forEach((fld: any) => {
  //             const config = currentStage.fieldConfigs[fld.id];
  //             if (config === 'Require') {
  //               const val2 = Object.entries(formVals).find(([key, value]) => key === fld.name)?.[1];
  //               const val = formVals[fld.id];
  //               if (fld.type === 'DateField') {
  //                 // date check
  //                 if (!val || !dayjs(val).isValid()) missing.push(fld.name);
  //               } else {
  //                 // check if empty
  //                 const finalVal = val?.value !== undefined ? val.value : val2;
  //                 if (!finalVal) missing.push(fld.name);
  //               }
  //             }
  //           });
  //         });
  //     }
  //     if (missing.length > 0) {
  //       return Modal.error({
  //         title: 'Missing Required Fields',
  //         content: `Please fill: ${missing.join(', ')}`,
  //       });
  //     }
  //
  //     // 3B. Build standard field data
  //     const standardData = await buildStandardData(formVals);
  //
  //     // 3C. Build table data
  //     const tableBlocks: Record<string, any> = {};
  //     moduleData.sections
  //       .filter((s: any) => s.type === 'table')
  //       .forEach((section: any) => {
  //         const tblId = section.id;
  //         const rows = tableData[tblId] || [];
  //         const tableDef = moduleData.tableData?.find((table: any) => table.id === tblId);
  //         const colDefs = tableDef?.columns || [];
  //
  //         const processedRows = rows.map((r: any) => {
  //           const rowObj: Record<string, any> = {};
  //           colDefs.forEach((col: any) => {
  //             const columnKey = col.id || col.columnid; // Use the correct column key
  //             let val = r[columnKey];
  //             if (col.type === 'DateField' && typeof val === 'string') {
  //               val = dayjs(val).isValid() ? dayjs(val).toISOString() : null;
  //             }
  //             rowObj[columnKey] = {
  //               label: col.name,
  //               value: val ?? '',
  //             };
  //           });
  //           return rowObj;
  //         });
  //
  //         tableBlocks[tblId] = {
  //           label: section.title || tblId,
  //           value: processedRows,
  //         };
  //       });
  //
  //     // 3D. Combine
  //     const transactionId = existingRecord?.transactionId || uuidv4();
  //     const finalRecord = {
  //       ...standardData,
  //       ...tableBlocks,
  //       transactionId: {
  //         label: 'Transaction ID',
  //         value: transactionId,
  //       },
  //     };
  //
  //     // 3E. Post to server
  //     await doSubmit(finalRecord);
  //     message.success('Record saved successfully');
  //     closeModal();
  //   } catch (err) {
  //     console.error('Error saving transaction:', err);
  //     message.error('Failed to save the record');
  //   }
  // };

  async function doSubmit(finalData: any) {
    const { tenantId, companyId, _id: moduleId } = moduleData;
    const payload = {
      _id: "", // add a default value
      data: finalData,
      moduleId,
      tenantId,
      companyId,
      stageId: currentStage?.name,
    };

    if (existingRecord?._id) {
      payload._id = existingRecord._id; // update id if existing record
      await dataAll.dataPostUtility.overwriteData('transaction',existingRecord._id, payload);
      // await axios.put(`${API_URL}/transactiondata/${existingRecord._id}`, payload);
    } else {
      await dataAll.dataPostUtility.postData('transaction',payload);
      // await axios.post(`${API_URL}/transactiondata`, payload);
    }

    // Refresh
    const newTrxData = await dataAll.dataExtractUtility.getTransactionData(tenantId, companyId, moduleId)
    // const resp = await axios.get(
    //   `${API_URL}/transactiondata?moduleId=${moduleId}&tenantId=${tenantId}&companyId=${companyId}`
    // );
    // const fresh = resp.data.filter((r: any) => !r.movedToNextStage);
    setRecords(newTrxData);
  }

  // ----------------------------
  // 4. Table Helpers
  // ----------------------------
  const addTableRow = (sectionId: string) => {
    const tblDef = moduleData.tableData?.find((table: any) => table.id === sectionId);
    if (!tblDef) return console.error('No table definition for', sectionId);

    if (!rowCounters.current[sectionId]) {
      rowCounters.current[sectionId] = 0;
    }
    const newId = `row_${sectionId}_${rowCounters.current[sectionId]++}`;
    const newRow: any = { id: newId };
    tblDef.columns.forEach((col: any) => {
      newRow[col.id] = col.type === 'Dropdown' ? '' : null;
    });
    setTableData((prev) => {
      const newSectionData = [...(prev[sectionId] || [])]; // Ensure new array
      newSectionData.push({ ...newRow }); // Deep copy new row
      return { ...prev, [sectionId]: newSectionData };
    });

    // setTableData((prev) => ({
    //   ...prev,
    //   [sectionId]: [...(prev[sectionId] || []), newRow],
    // }));
  };

  const removeTableRow = (sectionId: string, rowId: string) => {
    setTableData((prev) => ({
      ...prev,
      [sectionId]: prev[sectionId].filter((r) => r.id !== rowId),
    }));
  };

  const findField = (fieldId: string) => {
    return moduleData.sections
      .flatMap((s: any) => s.fields)
      .find((f: any) => f.id === fieldId);
  };

  const handleFormFieldChange = (fieldId: string, val: any) => {
    const fld = findField(fieldId);
    if (!fld) return;
    if (fld.type === 'Dropdown') {
      form.setFieldsValue({ [fieldId]: { label: val, value: val } });
    } else {
      form.setFieldsValue({ [fieldId]: { label: fld.name, value: val } });
    }
  };

  // Called by column fields
  const handleTableCellChange = (
    sectionId: string,
    rowId: string,
    columnId: string,
    value: any
  ) => {
    setTableData((prev) => {
      const updatedSectionData = prev[sectionId]?.map((row) => {
        if (row.id === rowId) {
          return { ...row, [columnId]: value };
        }
        return row;
      });
      return { ...prev, [sectionId]: updatedSectionData };
    });
  };

  const calculateAggregate = (rows: any[], columnId: string, type: string) => {
    const values = rows.map((row) => row[columnId]).filter((val) => typeof val === 'number');
    switch (type) {
      case 'sum':
        return values.reduce((acc, curr) => acc + curr, 0);
      case 'average':
        return values.length ? values.reduce((acc, curr) => acc + curr, 0) / values.length : 0;
      case 'count':
      default:
        return values.length;
    }
  };

  const renderSummary = (tableColumns: any[], sectionId: string) => {
    const rows = tableData[sectionId] || [];

    return (
      <Table.Summary.Row>
        {tableColumns.map((col, index) => {
          if (typeof rows[0]?.[col.columnId] === 'number') {
            const selectedType = selectedAggregates[col.columnId] || 'count';
            const result = calculateAggregate(rows, col.columnId, selectedType);

            return (
              <Table.Summary.Cell key={col.columnId} index={index}>
                <div
                  className="aggregate-cell"
                  style={{
                    height: clickedDropdown === col.columnId ? '80px' : '15px', // Dynamically adjust height
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    const dropdownId = `dropdown-${col.columnId}`;
                    if (clickedDropdown === col.columnId) {
                      setClickedDropdown(null); // Close the dropdown if already open
                    } else {
                      setClickedDropdown(col.columnId); // Open the dropdown
                    }
                  }}
                >
                  <span className="aggregate-result">
                    {`${selectedType.charAt(0).toUpperCase() + selectedType.slice(1)}: ${result}`}
                  </span>
                  {clickedDropdown === col.columnId && (
                    <div className="aggregate-dropdown">
                      {['count', 'sum', 'average'].map((type) => (
                        <div
                          key={type}
                          className="aggregate-dropdown-item"
                          style={{
                            background: selectedType === type ? '#e6f7ff' : 'transparent',
                          }}
                          onClick={() => {
                            setSelectedAggregates((prev) => ({
                              ...prev,
                              [col.columnId]: type,
                            }));
                            setClickedDropdown(null);
                          }}
                        >
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </Table.Summary.Cell>
            );
          }
          return <Table.Summary.Cell key={col.columnId} index={index} />;
        })}
        <Table.Summary.Cell index={tableColumns.length} />
      </Table.Summary.Row>
    );
  };

  // ----------------------------
  // 5. Move to Next Stage (Simplified)
  // ----------------------------
  //

  const buildMovedStageData = async (formValues, nextStage, moduleData) => {
    const newData = {};

    for (const section of moduleData.sections.filter((s) => s.type === 'section')) {
      for (const field of section.fields) {
        const rawValue = formValues[field.name];
        let finalValue = rawValue;

        if (field.type === 'DateField' && rawValue) {
          finalValue = dayjs(rawValue).isValid() ? dayjs(rawValue).toISOString() : null;
        } else if (rawValue?.value !== undefined) {
          finalValue = rawValue.value;
        }

        newData[field.id] = {
          label: field.name,
          value: finalValue ?? '',
        };
      }
    }

    return {
      ...newData,
      stageId: { label: 'Stage', value: nextStage.name },
    };
  };

  const buildTableData = (tableData, moduleData) => {
    return Object.keys(tableData).reduce((acc, sectionId) => {
      const rows = tableData[sectionId] || [];
      const tableDef = moduleData.tableData?.find((table) => table.id === sectionId);
      const colDefs = tableDef?.columns || [];

      const tableRows = rows.map((row) => {
        const rowObj = {};
        colDefs.forEach((col) => {
          let val = row[col.id];
          if (col.type === 'DateField' && val) {
            val = dayjs(val).isValid() ? dayjs(val).toISOString() : null;
          }
          rowObj[col.id] = {
            label: col.name,
            value: val ?? '',
          };
        });
        return rowObj;
      });

      acc[sectionId] = {
        label: moduleData.sections.find((s) => s.id === sectionId)?.title || sectionId,
        value: tableRows,
      };

      return acc;
    }, {});
  };

  const moveToNextStage = async () => {
    if (!currentStage) return;
    const currentStageIndex = moduleData.stages.findIndex((s) => s.id === currentStage.id);
    const nextStage = moduleData.stages[currentStageIndex + 1];

    if (!nextStage) {
      message.info('You are already at the final stage.');
      return;
    }

    try {
      const requiredFields = Object.entries(currentStage.fieldConfigs || {})
          .filter(([_, config]) => config === 'Require')
          .map(([fieldId]) => moduleData.sections.flatMap((s) => s.fields).find((f) => f.id === fieldId)?.name)
          .filter(Boolean);

      await form.validateFields(requiredFields);

      const formValues = form.getFieldsValue();
      const processedFormData = await buildMovedStageData(formValues, nextStage, moduleData);
      const processedTableData = buildTableData(tableData, moduleData);

      const transactionId = existingRecord?.transactionId || uuidv4();

      // Updating existing record
      const updatedExistingRecord = {
        ...processedFormData,
        ...processedTableData,
        transactionId: {
          label: 'Transaction ID',
          value: transactionId,
        },
        movedToNextStage: true,
      };

      await dataAll.dataPostUtility.overwriteData('transaction', existingRecord._id, {
        _id: existingRecord._id,
        data: updatedExistingRecord,
        moduleId: moduleData._id,
        tenantId: moduleData.tenantId,
        companyId: moduleData.companyId,
        movedToNextStage: true,
        stageId: currentStage.name,
        createdAt: existingRecord.createdAt,
        updatedAt: new Date().toISOString(),
      });

      // Creating new record
      const newRecord = {
        data: { ...processedFormData, ...processedTableData },
        moduleId: moduleData._id,
        tenantId: moduleData.tenantId,
        companyId: moduleData.companyId,
        stageId: nextStage.name,
        movedToNextStage: false,
        createdAt: existingRecord?.createdAt || new Date().toISOString(),
      };

      await dataAll.dataPostUtility.postData('transaction', newRecord);

      setRecords([...records, newRecord]);
      setCurrentStage(nextStage);

      message.success('Moved to the next stage successfully.');
      closeModal();
    } catch (err) {
      console.error('Error moving to the next stage:', err);
      message.error('Failed to move to the next stage.');
    }

    // Refresh records
    try {
      const updatedRecords = await dataAll.dataExtractUtility.getTransactionData(moduleData.tenantId, moduleData.companyId, moduleData._id);
      setRecords(updatedRecords);
    } catch (err) {
      console.error('Error fetching updated records:', err);
    }
  };

  // const moveToNextStage = async () => {
  //   if (!currentStage) return;
  //   const currentStageIndex = moduleData.stages.findIndex((s: Stage) => s.id === currentStage.id);
  //   const nextStage = moduleData.stages[currentStageIndex + 1];
  //
  //   if (!nextStage) {
  //     message.info('You are already at the final stage.');
  //     return;
  //   }
  //
  //   // Validate required fields
  //   const requiredFields = Object.entries(currentStage.fieldConfigs || {})
  //       .filter(([fieldId, config]) => config === 'Require') // Only required fields
  //       .map(([fieldId]) => {
  //         const field = moduleData.sections
  //             .filter((section: any) => section.type === 'section')
  //             .flatMap((section: any) => section.fields)
  //             .find((fld: any) => fld.id === fieldId);
  //         return field?.name; // Map to field name
  //       })
  //       .filter((name) => name); // Remove undefined/null names
  //
  //   try {
  //     await form.validateFields(requiredFields);
  //   } catch {
  //     message.error('Please fill all required fields before moving to the next stage.');
  //     return;
  //   }
  //
  //   // Process form and table data
  //   const formValues = form.getFieldsValue();
  //   // const processedFormData = await buildStandardData(formValues); // Standard section data
  //   const processedFormData = await initializePage.buildMovedStageData(formValues, nextStage, moduleData);
  //   const processedTableData = Object.keys(tableData).reduce((acc, sectionId) => {
  //     const rows = tableData[sectionId] || [];
  //     // const colDefs = moduleData.tableData?.[sectionId]?.columns || [];
  //     const tableDef = moduleData.tableData?.find((table) => table.id === sectionId);
  //     const colDefs = tableDef?.columns || [];
  //
  //     const tableRows = rows.map((row) => {
  //       const rowObj: Record<string, any> = {};
  //       colDefs.forEach((col: any) => {
  //         let val = row[col.id];
  //         if (col.type === 'DateField' && val) {
  //           val = dayjs(val).isValid() ? dayjs(val).toISOString() : null;
  //         }
  //         rowObj[col.id] = {
  //           label: col.name,
  //           value: val ?? '',
  //         };
  //       });
  //       return rowObj;
  //     });
  //
  //     acc[sectionId] = {
  //       label: moduleData.sections.find((s) => s.id === sectionId)?.title || sectionId,
  //       value: tableRows,
  //     };
  //
  //     return acc;
  //   }, {});
  //
  //   const transactionId = existingRecord?.transactionId || uuidv4();
  //   const updatedExistingRecord = {
  //     ...processedFormData,
  //     ...processedTableData,
  //     transactionId: {
  //       label: 'Transaction ID',
  //       value: transactionId,
  //     },
  //   };
  //
  //   // const payload = {
  //   //   _id: existingRecord?._id, // add a default value
  //   //   data: updatedExistingRecord,
  //   //   moduleId: moduleData._id,
  //   //   tenantId: moduleData.tenantId,
  //   //   companyId: moduleData.companyId,
  //   //   stageId: currentStage?.name,
  //   //   movedToNextStage: true,
  //   //   createdAt: existingRecord?.createdAt || new Date().toISOString(),
  //   //   updatedAt: new Date().toISOString(),
  //   // };
  //
  //   // Carryover fields
  //   const carryoverData: Record<string, any> = { ...processedFormData, ...processedTableData };
  //
  //   // Persist transactionId
  //   carryoverData.transactionId = processedFormData.transactionId;
  //
  //   try {
  //     // Update the existing record to mark it as moved
  //     if (updatedExistingRecord) {
  //       await dataAll.dataPostUtility.overwriteData('transaction', existingRecord._id, updatedExistingRecord);
  //       // await axios.put(`${API_URL}/transactiondata/${existingRecord._id}`, payload);
  //     }
  //
  //     // Create the new record for the next stage
  //     const newRecord = {
  //       data: carryoverData,
  //       moduleId: moduleData._id,
  //       tenantId: moduleData.tenantId,
  //       companyId: moduleData.companyId,
  //       stageId: nextStage.name, // Update to the next stage ID
  //       movedToNextStage: false, // New record is not moved yet
  //       createdAt: existingRecord?.createdAt || new Date().toISOString(),
  //     };
  //
  //     const updatedRecord = async () => {
  //       try {
  //         await dataAll.dataPostUtility.postData('transaction', newRecord)
  //       } catch (error) {
  //         console.error(error);
  //       }
  //     }
  //     //
  //     // const response = await axios.post(`${API_URL}/transactiondata`, newRecord);
  //     // const updatedRecord = response.data;
  //
  //     // Update records state
  //     setRecords([...records, updatedRecord]);
  //     setCurrentStage(nextStage);
  //
  //     message.success('Moved to the next stage successfully.');
  //     closeModal();
  //   } catch (err) {
  //     console.error('Error moving to the next stage:', err);
  //     message.error('Failed to move to the next stage.');
  //   }
  //
  //   // Fetch updated records to ensure state consistency <-- I don't think this is needed if the previous statement does setRecords, but I could be wrong.
  //   try {
  //     const { tenantId, companyId } = moduleData;
  //     const updatedRecords = await dataAll.dataExtractUtility.getTransactionData(tenantId, companyId, moduleData._id)
  //     // const updatedRecordsResponse = await axios.get(
  //     //     `${API_URL}/transactiondata?moduleId=${moduleData._id}&tenantId=${tenantId}&companyId=${companyId}`
  //     // );
  //     //
  //     // const updatedRecords = updatedRecordsResponse.data.filter(
  //     //     (record: any) => !record.movedToNextStage
  //     // );
  //     setRecords(updatedRecords);
  //   } catch (err) {
  //     console.error('Error fetching updated records:', err);
  //   }
  // };


  // ----------------------------
  // 6. Render
  // ----------------------------
  const stageIdx = moduleData?.stages?.findIndex((s: Stage) => s.id === currentStage?.id);

  return (
    <Modal
      title={`${existingRecord ? 'Edit' : 'New'} ${moduleData.moduleName}`}
      open={true}
      onOk={handleSubmit}
      onCancel={closeModal}
      width={1000}
    >
      {/* Stage + Next Stage */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 16 }}>
        <h2>Current Stage: {currentStage?.name}</h2>
        <Button
          onClick={moveToNextStage}
          type='primary'>
            Move to Next Stage
        </Button>
      </div>

      {/* Steps */}
      <Steps current={stageIdx}>
        {moduleData.stages.map((st: Stage) => (
          <Steps.Step key={st.id} title={st.name} />
        ))}
      </Steps>

      <Form form={form} layout="vertical" style={{ marginTop: 24 }}>
        {moduleData.sections.map((section: any) => {
          if (section.type === 'section') {
            // Renders standard fields
            const fieldsToRender = getVisibleFields(section, currentStage);
            const firstStage = moduleData.stages?.[0] || null;
            const currentStageNew = currentStage?.id
                ? moduleData.stages.find((s) => s.id === currentStage?.id) || firstStage
                : firstStage;

            const requiredFields = currentStageNew?.fieldConfigs
                ? Object.entries(currentStageNew.fieldConfigs)
                    .filter(([_, config]) => config === 'Require')
                    .map(([fieldId]) => fieldId)
                : [];

            return (
              <div key={section.id} style={{ marginBottom: 24 }}>
                <h3>{section.title}</h3>
                <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 16 }}>
                  {fieldsToRender.map((fld: any) => (
                    <React.Fragment key={fld.id}>
                      {renderField(
                        fld,
                        section.id,
                        form,
                        referenceData,
                        (val: any) => handleFormFieldChange(fld.id, val),
                        moduleData,
                        tableData,
                        null, //rowData
                        null, //columns
                        null, //isEditing
                        null, //tenantId
                        null, //companyId
                        null, //moduleId
                        null, //itemId
                        requiredFields.includes(fld.id), // required
                        // 'section',
                        // null, //existingrecord,
                        // null, //new schema,
                        existingRecord ? form.getFieldValue(fld.name) : '', //existingRecord[fld.id], //value
                        null //rowIndex
                      )}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          } else if (section.type === 'table') {
            // Renders table
            const tblDef = moduleData.tableData?.find((table: any) => table.id === section.id);
            if (!tblDef) return null;
            const columnsToRender = getVisibleColumns(tblDef.columns, currentStage);
            const columnsForTable = columnsToRender.map((col: any) => ({
              title: col.name,
              columnId: col.id,
              key: col.id,
              render: (text: any, row: any, rowIndex: number) =>
                renderField(
                  { ...col, renderType: 'table' },
                  section.id,
                  form,
                  referenceData,
                  (val: any) => handleTableCellChange(section.id, row.id, col.id, val),
                  moduleData,
                  tableData,
                  row,
                  columnsToRender, //columns
                  true, //isEditing
                  undefined, //tenantId
                  undefined, //companyId
                  moduleData._id, //moduleId
                  row.id, //itemId
                  currentStage?.fieldConfigs?.[col.id] === 'Require', //required
                  'table', //render type
                  existingRecord,
                  true, //new schema
                  row[col.id], //fieldValue
                  rowIndex
               ),
            }));

            return (
              <div key={section.id} style={{ marginBottom: 24 }}>
                <h3>{section.title}</h3>
                <Button
                  type="dashed"
                  onClick={() => addTableRow(section.id)}
                  style={{ marginBottom: 16 }}
                >
                  Add Row
                </Button>
                <Table
                  columns={[
                    ...columnsForTable,
                    {
                      title: 'Actions',
                      key: 'actions',
                      fixed: 'right',
                      render: (_: any, row: any) => (
                        <Button
                          icon={<DeleteOutlined />}
                          danger
                          onClick={() => removeTableRow(section.id, row.id)}
                        />
                      ),
                    },
                  ]}
                  dataSource={tableData[section.id] || []}
                  pagination={false}
                  rowKey="id"
                  scroll={{ x: 'max-content' }}
                  summary={() => renderSummary(columnsForTable, section.id)} // Add the summary row
                />
              </div>
            );
          }
          return null;
        })}
      </Form>
    </Modal>
  );
};

export default TransactionPageRender;

// Helpers
function getVisibleFields(section: any, currentStage: Stage | null) {
  if (!currentStage?.fieldConfigs) return section.fields;
  return section.fields.filter((fld: any) => {
    const config = currentStage.fieldConfigs[fld.id];
    return config === 'Edit' || config === 'View' || config === 'Require';
  });
}

function getVisibleColumns(allCols: any[], currentStage: Stage | null) {
  if (!currentStage?.fieldConfigs) return allCols;
  return allCols.filter((col: any) => {
    const config = currentStage.fieldConfigs[col.id];
    return config === 'Edit' || config === 'View' || config === 'Require';
  });
}
