import dataExtractUtility from "../utils/dataExtract"

const dataCleanUtility = {

    async cleanTransactionData(rawData: any[], moduleId: string) {
        // Fetch moduleData for field mapping
        const { moduleData } = await dataExtractUtility.getModuleData(moduleId);

        // Create a mapping of field IDs to their labels
        const fieldMapping: Record<string, string> = {};
        moduleData.sections.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                fieldMapping[field.id] = field.name; // Map field ID to its current label
            });
        });

        return rawData.map((record) => {
            const cleanedRecord: any = {
                _id: record._id || null,
                createdBy: record.createdBy || null,
                updatedBy: record.updatedBy || null,
                deletedBy: record.deletedBy || null,
                data: {}, // To hold cleaned data
                tableData: {}, // To hold cleaned table data
            };

            // Process "data" field
            record.data.forEach((field: any) => {
                const fieldID = field.Key;
                const fieldLabel = fieldMapping[fieldID] || field.Value.find((kv: any) => kv.Key === "label")?.Value || null;
                const fieldValue = field.Value.find((kv: any) => kv.Key === "value")?.Value || null;

                // Handle simple key-value pairs
                if (fieldLabel) {
                    cleanedRecord.data[fieldLabel] = fieldValue;
                }
            });

            // Process "data" field for nested and simple arrays
            Object.entries(cleanedRecord.data).forEach(([fieldLabel, fieldValue]) => {
                if (Array.isArray(fieldValue)) {
                    // Check if it's a nested array
                    const isNestedArray = fieldValue.some(
                        (item: any) => Array.isArray(item) || (typeof item === "object" && Array.isArray(item?.Value))
                    );

                    if (isNestedArray) {
                        // Handle Nested Arrays (e.g., tables)
                        cleanedRecord.tableData[fieldLabel] = fieldValue.map((nestedRow: any, index: number) => {
                            const cleanedRow: any = {};
                            nestedRow.forEach((cell: any) => {
                                const cellLabel = cell.Value.find((kv: any) => kv.Key === "label")?.Value || null;
                                const cellValue = cell.Value.find((kv: any) => kv.Key === "value")?.Value || null;

                                if (cellLabel) {
                                    cleanedRow[cellLabel] = cellValue;
                                }
                            });
                            return { [`row ${index + 1}`]: cleanedRow };
                        });

                        // Remove from `data` since it's now processed into `tableData`
                        delete cleanedRecord.data[fieldLabel];
                    } else {
                        // Handle Simple Arrays (e.g., reference link fields)
                        cleanedRecord.data[fieldLabel] = fieldValue.map((item: any) => {
                            const simpleEntry: any = {};
                            if (item.Key && item.Value !== undefined) {
                                simpleEntry[item.Key] = item.Value;
                            }
                            return simpleEntry;
                        });
                    }
                }
            });

            return cleanedRecord;
        });
    },

    async cleanReferenceData(referenceData: any[], moduleId : string) {
        const { moduleData } = await dataExtractUtility.getModuleData(moduleId);

        const fieldMapping: Record<string, string> = {};
        moduleData.sections.forEach((section: any) => {
            section.fields.forEach((field: any) => {
                fieldMapping[field.id] = field.name; // Map field ID to its current label
            });
        });

        // Step 2: Process each record
        return referenceData.map((record) => {
            const cleanedRecord: any = {
                _id: record._id,
                createdAt: record.createdAt,
                updatedAt: record.updatedAt,
                tenantId: record.tenantId,
                companyId: record.companyId,
                moduleId: record.moduleId,
                stageId: record.stageId,
                createdBy: record.createdBy,
                updatedBy: record.updatedBy,
                data: {}
            };

            // Step 3: Match data keys to module definitions
            Object.keys(record.data).forEach((fieldID) => {
                const field = record.data[fieldID];
                const currentLabel = fieldMapping[fieldID] || field.label; // Use latest label if available
                const fieldValue = field.value;
                if (!currentLabel) return; // Skip if no label is found or field is invalid

                // Step 4: Clean data
                if (typeof fieldValue === "object" && fieldValue !== null) {
                    // Handle Reference Link Fields or Nested Objects
                    if (fieldValue.uuid) {
                        cleanedRecord.data[currentLabel] = { label: fieldValue.label || "", value: fieldValue.label || "" };
                    } else {
                        cleanedRecord.data[currentLabel] = fieldValue; // Retain nested objects as-is
                    }
                } else {
                    // Handle Simple Fields
                    cleanedRecord.data[currentLabel] = fieldValue;
                }
            });
            return cleanedRecord;
        });
    },

    async cleanModuleData(moduleData: any) {
        // Fetch moduleData
        // const { moduleData } = await dataExtractUtility.getModuleData(moduleId);

        // Prepare the cleaned module data structure
        const cleanedModuleData: any = {
            _id: moduleData._id || null,
            pageTitle: moduleData.pageTitle || null,
            status: moduleData.status || null,
            tenantId: moduleData.tenantId || null,
            companyId: moduleData.companyId || null,
            sections: [], // To hold cleaned sections
        };

        // Iterate through moduleData.sections to clean each section
        if (Array.isArray(moduleData.sections)) {
            moduleData.sections.forEach((section: any) => {
                const cleanedSection: any = {
                    id: section.id || null,
                    title: section.title || null,
                    type: section.type || null,
                    fields: [], // To hold cleaned fields
                };

                if (Array.isArray(section.fields)) {
                    section.fields.forEach((field: any) => {
                        const cleanedField: any = {
                            id: field.id || null,
                            name: field.name || null,
                            type: field.type || null,
                            fieldOptions: {}, // To hold cleaned field options
                        };

                        // Process field options if available
                        if (field.fieldOptions) {
                            cleanedField.fieldOptions = { ...field.fieldOptions };

                            // If the field contains sections (e.g., OneToMany fields), clean those too
                            if (Array.isArray(field.fieldOptions.sections)) {
                                cleanedField.fieldOptions.sections = field.fieldOptions.sections.map((innerSection: any) => {
                                    return {
                                        title: innerSection.title || null,
                                        fields: Array.isArray(innerSection.fields)
                                            ? innerSection.fields.map((innerField: any) => ({
                                                id: innerField.id || null,
                                                name: innerField.name || null,
                                                type: innerField.type || null,
                                            }))
                                            : [],
                                    };
                                });
                            }
                        }

                        // Add the cleaned field to the section
                        cleanedSection.fields.push(cleanedField);
                    });
                }

                // Add the cleaned section to the cleanedModuleData
                cleanedModuleData.sections.push(cleanedSection);
            });
        }

        return cleanedModuleData;
    }
}
export default dataCleanUtility;