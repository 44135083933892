import axios from "axios";
// const API_URL = 'http://localhost:5001/api/v1'



const dataPostUtility = {
    getEndpoint(type: "reference" | "transaction" | "module", id: string): string {
        switch (type) {
            case "reference":
                return `${API_URL}/referencedata/${id}`;
            case "transaction":
                return `${API_URL}/transactiondata/${id}`;
            case "module":
                return `${API_URL}/modules/${id}`;
            default:
                throw new Error(`Invalid type provided: ${type}`);
        }
    },

    async postData(type: "reference" | "transaction" | "module", payload: any) {
        // Determine the endpoint based on the type
        let endpoint = "";
        switch (type) {
            case "reference":
                endpoint = `${API_URL}/referencedata`;
                break;
            case "transaction":
                endpoint = `${API_URL}/transactiondata`;
                break;
            case "module":
                endpoint = `${API_URL}/modules/save`;
                break;
            default:
                throw new Error(`Invalid type provided: ${type}`);
        }

        try {
            const response = await axios.post(endpoint, payload);
            console.log(`Data posted successfully to ${type}`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Failed to post data to ${type}:`, error);
            throw new Error(`Failed to post ${type} data`);
        }
    },


    async publishModule(moduleId: string) {
        let endpoint = `${API_URL}/modules/publish/${moduleId}`;

        try {
            const response = await axios.put(endpoint);
            console.log(`Module published successfully for ${moduleId}`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Failed to publish module for ${moduleId}:`, error);
            throw new Error(`Failed to update ${moduleId}`);
        }
    },

    async overwriteData(type: "reference" | "transaction" | "module", id: string, payload: any) {
        let endpoint = this.getEndpoint(type, id);

        try {
            const response = await axios.put(endpoint, payload);  // PUT for full overwrite
            console.log(`Data overwritten successfully for ${type}`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Failed to overwrite data for ${type}:`, error);
            throw new Error(`Failed to overwrite ${type} data`);
        }
    },

    async updateDataField(type: "reference" | "transaction" | "module", id: string, updatePayload: any) {
        let endpoint = this.getEndpoint(type, id);

        try {
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(updatePayload),
            });

            if (!response.ok) {
                throw new Error(`Failed to update ${type} data`);
            }

            const result = await response.json();
            console.log(`Data updated successfully for ${type}`, result);
            return result;
        } catch (error) {
            console.error(`Error updating data for ${type}:`, error);
            throw error;
        }
    },

    async deleteData(type: "reference" | "transaction" | "module", id: string) {

        let endpoint = "";
        let lowerType = type.toLowerCase()
        switch (lowerType) {
            case "reference":
                endpoint = `${API_URL}/referencedata/${id}`;
                break;
            case "transaction":
                endpoint = `${API_URL}/transactiondata/${id}`;
                break;
            case "module":
                endpoint = `${API_URL}/modules/${id}`;
                break;
            default:
                throw new Error(`Invalid type provided: ${type}`);
        }

        try {
            const response = await axios.delete(endpoint);
            console.log(`Data deleted successfully for ${type}`);
            return response.data;
        } catch (error) {
            console.error(`Failed to delete data for ${type}:`, error);
            throw new Error(`Failed to delete ${type} data`);
        }
    }
};

export default dataPostUtility;

