import React from 'react';
import { Form, DatePicker } from 'antd';
import dayjs from 'dayjs';

const DateFieldRender = ({ field, form, handleFieldChange, value, renderType, required }) => {
  const dateFormat = field.fieldOptions?.dateFormat || 'YYYY-MM-DD';

  // Ensure the value is either a valid dayjs object or null
    const dateValue = value
        ? dayjs.isDayjs(value)
            ? value
            : dayjs(value, dateFormat).isValid()
                ? dayjs(value, dateFormat)
                : dayjs(value).isValid()
                    ? dayjs(value)
                    : null
        : null;

  const handleDateChange = (date) => {
    handleFieldChange(date ? date.format(dateFormat) : '');
  };

  const datePickerComponent = (
      <DatePicker
          style={{ width: '100%' }}
          onChange={handleDateChange}
          value={dateValue || null}  // Ensure null when there's no value
          format={dateFormat}
          placeholder="Select date"
      />
  );

  if (renderType === 'table') {
    return datePickerComponent;
  }

  return (
      <Form.Item
          name={field.name}  // Use field.id to match form values
          label={field.name}
          rules={required ? [{ required: true, message: `Please select ${field.name}` }] : []}
      >
        {datePickerComponent}
      </Form.Item>
  );
};

export default DateFieldRender;