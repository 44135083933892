import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Form } from 'antd';

interface AggregatedFieldDisplayProps {
  tenantId: number;
  companyId: number;
  moduleId: string;
  fieldId: string;
  itemId: string;
  name: string; // Added a label prop for display
}

const AggregateFieldRender: React.FC<AggregatedFieldDisplayProps> = ({
  tenantId,
  companyId,
  moduleId,
  fieldId,
  itemId,
  name, // Make sure to pass a label when using this component
}) => {
  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    fetchAggregateValue();
  }, [tenantId, companyId, moduleId, fieldId, itemId]);

  const fetchAggregateValue = async () => {
    try {
      const response = await axios.get(`${API_URL}/aggregate/value`, {
        params: { tenantId, companyId, moduleId, fieldId, itemId },
      });
      setValue(response.data.value); // Adjusted to handle possible null or undefined
    } catch (error) {
      console.error('Failed to fetch aggregate value', error);
      setValue(null); // Handle errors by setting the value to null or by displaying an error state
    }
  };

  return (
    <Form.Item
      label={name}
      shouldUpdate={(prevValues, currentValues) => prevValues[fieldId] !== currentValues[fieldId]}
    >
      {/* Display the value or 'Loading...' if the value is still fetching */}
      {value !== null ? value : 'Loading...'}
    </Form.Item>
  );
};

export default AggregateFieldRender;
