import React, { createContext, useState, useContext, ReactNode } from 'react';

// Define the shape of the context (tenantId and setTenantId)
interface TenantContextType {
  tenantId: number | null;
  setTenantId: (id: number) => void;
  companyId: number | null;
  setCompanyId: (id: number) => void;
}

// Create the context with default values
const TenantContext = createContext<TenantContextType | undefined>(undefined);

// Provider component to wrap around the app
export const TenantProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [tenantId, setTenantId] = useState<number | null>(null);
  const [companyId, setCompanyId] = useState<number | null>(null);

  return (
    <TenantContext.Provider value={{ tenantId, setTenantId, companyId, setCompanyId}}>
      {children}
    </TenantContext.Provider>
  );
};

// TODO: Fix issue where user is unable to get info on login
// Hook to use the tenant context in any component
export const useTenant = (): TenantContextType => {
  const context = useContext(TenantContext);
  if (!context) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
};
