import React, { useState, useEffect, useRef } from 'react';
import { Button, Input, Select, notification } from 'antd';
import FieldPalette from '../Fields/_FieldPalette';
import PageOrganizer from '../Organizers/PageOrganizer';
import { useTenant } from '../../../contexts/TenantContext';
import { useLocation } from 'react-router-dom';
import { useUser } from '../../../contexts/UserContext';
import TourManager from '../../../contexts/tour/tourManager';
import { tourSteps } from '../../../contexts/tour/tourSteps';
import { useTour } from '../../../contexts/tour/tourContext';
import '../../../styles/refPage.css';
import '../../../styles/tourStyles.css'; // Import tour styles
import { DeleteOutlined, PlusSquareOutlined, SaveOutlined } from '@ant-design/icons';
import dataAll from "../../../utils/dataAll";

const { Option } = Select;

interface ReferencePageBuilderProps {
  module?: any;  // Optional module prop
}

const ReferencePageBuilder: React.FC<ReferencePageBuilderProps> = () => {
  const location = useLocation();
  const [module, setModule] = useState(location.state?.module || null);  // Store module in local state
  const [sections, setSections] = useState([{ id: `section-${Date.now()}`, title: 'Section 1', type: 'section', fields: [] }]);
  const [selectedSection, setSelectedSection] = useState(sections[0]?.id || '');
  const [functions, setFunctions] = useState([]);
  const [pageTitle, setPageTitle] = useState('New Page');
  const [moduleName, setModuleName] = useState(pageTitle);
  const [version, setVersion] = useState('1.0.0');
  const [status, setStatus] = useState('draft');
  const { tenantId, companyId } = useTenant();
  // const [processFlow, setProcessFlow] = useState<any[]>([]); // State to store the process flow
  const { user } = useUser();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { currentStep, setCurrentStep } = useTour(); // Access setCurrentStep from context
  // const [newlyAddedFieldId, setNewlyAddedFieldId] = useState<string | null>(null); // Track the newly added field


  // const canViewFunctions = true
  const userPermissions = (() => {
    if (user?.roles.includes('quickbuildadmin')) {
      return { canViewFunctions: true };
    }
    return { canViewFunctions: false };
  })();


  const initialSectionsRef = useRef([]);


  useEffect(() => {
    if (module) {
      setPageTitle(module.moduleName || 'Editable Page Title');
      setModuleName(module.moduleName || 'Module Name');
      setFunctions(module.functions || []);
      setVersion(module.version || '1.0.0');
      setStatus(module.status || 'draft');

      const updatedSections = (module.sections || []).map((section: { fields: any[]; }) => {
        const updatedFields = section.fields.map(field => {

          return field; // For other field types, just return as is
        });

        return {
          ...section,
          fields: updatedFields
        };
      });

      setSections(updatedSections); // Finally set the updated sections
      setSelectedSection(updatedSections[0]?.id || '');
      initialSectionsRef.current = updatedSections;
      setHasUnsavedChanges(false)
    }
  }, [module]);

   // Function to check for changes
   const hasChanges = () => {
    // Compare moduleName
    // if (moduleName !== initialModuleNameRef.current) return true;

    // // Compare version
    // if (version !== initialVersionRef.current) return true;

    // // Compare status
    // if (status !== initialStatusRef.current) return true;

    // // Compare functions
    // if (functions.length !== initialFunctionsRef.current.length) return true;
    // for (let i = 0; i < functions.length; i++) {
    //   if (JSON.stringify(functions[i]) !== JSON.stringify(initialFunctionsRef.current[i])) return true;
    // }

    // Compare sections
    if (sections.length !== initialSectionsRef.current.length) return true;
    for (let i = 0; i < sections.length; i++) {
      const currentSection = sections[i];
      const initialSection = initialSectionsRef.current[i];

      if (
        currentSection.id !== initialSection.id ||
        currentSection.title !== initialSection.title ||
        currentSection.type !== initialSection.type ||
        currentSection.fields.length !== initialSection.fields.length
      ) {
        return true;
      }

      for (let j = 0; j < currentSection.fields.length; j++) {
        if (
          JSON.stringify(currentSection.fields[j]) !==
          JSON.stringify(initialSection.fields[j])
        ) {
          return true;
        }
      }
    }

    return false;
  };

  // console.log(functions)
  const onAddField = (fieldType: string, sectionId: string) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        // Define newField as a base field
        let newField: { id: string; type: string; name: string;} = {
          id: `field-${Date.now()}`,
          type: fieldType,
          name: `${fieldType}_${Date.now()}`
        };

        // setNewlyAddedFieldId(newField.id); // Track the new field's ID

        if (fieldType === 'TextField') {
          const nextStep = tourSteps.referencePageBuilder.find((step) => step.id === 'textfield-added-step');
          if (nextStep) {
            nextStep.selector = `#${newField.id}`; // Dynamically set the selector
          }
          setCurrentStep(currentStep + 1); // Advance the tour step
        }

        return { ...section, fields: [...section.fields, newField] };
      }
      return section;
    });

    // console.log('Updated sections:', updatedSections);
    setSections(updatedSections);
  };

  const onFieldUpdate = (sectionId: string, fieldId: string, updatedField: any) => {
    const updatedSections = sections.map((section) => {
      if (section.id === sectionId) {
        if (fieldId === 'all') {
          // Replace the entire fields array
          return { ...section, fields: updatedField };
        } else {
          // Update a single field
          const updatedFields = section.fields.map((field) =>
            field.id === fieldId ? { ...field, ...updatedField } : field
          );
          return { ...section, fields: updatedFields };
        }
      }
      return section;
    });
    setSections(updatedSections);
  };

  const onFieldDelete = (sectionId: string, fieldId: string) => {
    const updatedSections = sections.map(section => {
      if (section.id === sectionId) {
        const updatedFields = section.fields.filter(field => field.id !== fieldId);
        return { ...section, fields: updatedFields };
      }
      return section;
    });
    setSections(updatedSections);
  };

  // const onSaveFunction = (newFunction: any) => {
  //   setFunctions((prevFunctions) => [...prevFunctions, newFunction]);  // Append the new function to the array
  //   setIsProcessModalVisible(false);  // Close the modal
  // };
  //
  // const handleDeleteFunction = (index: number) => {
  //   const updatedFunctions = functions.filter((_, funcIndex) => funcIndex !== index);
  //   setFunctions(updatedFunctions); // Update the state after deleting the function
  // };
  //
  // const handleEditFunction = (index: string | number) => {
  //   const funcToEdit = functions[index];
  //   setFunctionToEdit(funcToEdit);  // Set the function to edit
  //   setIsProcessModalVisible(true); // Open the modal
  // };

  // const processFunctions = (functions: any[]) => {
  //   return functions.map((func: { functionId: any; name: any; steps: { stepId: any; type: any; typeDetails: any; }[]; }) => ({
  //     functionId: func.functionId,
  //     name: func.name,
  //     steps: func.steps.map((step: { stepId: any; type: any; typeDetails: any; }) => ({
  //       stepId: step.stepId,
  //       type: step.type,
  //       typeDetails: step.typeDetails,
  //     })),
  //   }));
  // };

  const processAutoIncrementFields = async (sections: any[]) => {
    const autoIncrementFields = [];
    sections.forEach((section) => {
      if (section.type === 'section') { // Only FormSection has 'fields'
        section.fields.forEach((field: { type: string; }) => {
          if (field.type === 'AutoIncrementId') {
            autoIncrementFields.push(field);
          }
        });
      }
    });


  };

  const saveSchema = async () => {
    // Prepare the schema
    const schema = {
      pageTitle: moduleName,
      moduleName,
      version,
      status,
      sections,
      tenantId,
      companyId,
      pageType: 'Reference',
      oneToManyFields: null,
      functions: null,
    };

    // Handle AutoIncrement fields (if applicable)
    await processAutoIncrementFields(sections);

    try {
      let savedModule
      if (module && module._id) {
        savedModule = await dataAll.dataPostUtility.overwriteData('module', module._id, schema)
      } else {
        savedModule = await dataAll.dataPostUtility.postData('module', schema);
      }

      // const updatedModule = await dataAll.dataPostUtility.overwriteData('module', module._id, schema)
      setModule(savedModule)
      notification.success({
        message: 'Schema saved',
        description: 'Click "Builder" to view in library',
        placement: 'top',
        duration: 3
      })
      setHasUnsavedChanges(false)

      // if (savedModule) {
      //   const updatedModule = dataAll.dataExtractUtility.getModuleData(savedModule._id)
      //   setModule(updatedModule)
      // } <-- don't think this is needed. It's just to refresh the savedModule, which should have just been defined earlier.

      } catch (error) {
      console.error(error);
    }

    // try {
    //   const url = module?._id
    //     ? `${API_URL}/modules/${module._id}`
    //     : `${API_URL}/modules/save`;
    //
    //   const method = module?._id ? 'PUT' : 'POST';
    //
    //   const response = await fetch(url, {
    //     method,
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(schema),
    //   });
    //   // console.log('response: ', response)
    //
    //   const result = await response.json();
    //   if (response.ok) {
    //     // console.log('Schema saved successfully!', result);
    //     const fetchUrl = `${API_URL}/modules/${result._id || module?._id}`;
    //     const fetchResponse = await fetch(fetchUrl);
    //     const updatedModule = await fetchResponse.json();
    //     setModule(updatedModule)
    //     notification.success({
    //       message: 'Schema saved',
    //       description: 'Click "Builder" to view in library',
    //       placement: 'top',
    //       duration: 3
    //     })
    //     setHasUnsavedChanges(false)
    //
    //   } else {
    //     console.error('Failed to save schema:', result);
    //   }
    // } catch (error) {
    //   console.error('Error saving schema:', error);
    // }
  };

  let availableFields = ['TextField', //'ParagraphTextField',
    'AutoIncrementId', 'PhoneNumber', 'OneToManyField',
    'EmailField', 'Dropdown', 'NumberField', 'ReferenceLinkField'];

  if (userPermissions.canViewFunctions) {
    availableFields.push('AggregateField')
  }

  const handleSectionSelect = (sectionId: React.SetStateAction<string>) => {
    setSelectedSection(sectionId);
  };

  const addSection = () => {
    const newSection = {
      id: `section-${Date.now()}`,
      title: `Section ${sections.length + 1}`,
      type: 'section',
      fields: [],
    };
    setSections([...sections, newSection]);
    setSelectedSection(newSection.id); // Automatically select the new section
  };

  // const functionColumns = [
  //   {
  //     title: 'Function ID',
  //     dataIndex: 'functionId',  // Display the functionId
  //     key: 'functionId',
  //   },
  //   {
  //     title: 'Function Name',
  //     dataIndex: 'name',  // Display the function name
  //     key: 'name',
  //   },
  //   {
  //     title: 'Actions',
  //     key: 'actions',
  //     render: (text, record, index) => (
  //       <>
  //         <Button type="link" onClick={() => handleEditFunction(index)}>Edit</Button>
  //         <Button type="link" onClick={() => handleDeleteFunction(index)}>Delete</Button>
  //       </>
  //     ),
  //   },
  // ];

  const deleteSection = (sectionId: string) => {
    const updatedSections = sections.filter((section) => section.id !== sectionId);
    setSections(updatedSections);
  };

  useEffect(() => {
    if (hasChanges()) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  }, [sections, functions, moduleName, version, status]);


  return (
    <div>
      {window.location.pathname === '/builder/reference-page-builder' && sections.length > 0 && (
        <TourManager steps={tourSteps.referencePageBuilder} />
      )}
      <h1>Reference Page Builder</h1>

      {hasUnsavedChanges && (
      <div className='unsaved-changes-banner'>
        <strong>Unsaved Changes:</strong> Don't forget to click "Save" or your changes will be lost
      </div>
      )}

      <Input
        value={pageTitle}
        onChange={(e) => {
          setPageTitle(e.target.value);
          setModuleName(e.target.value); // Keep moduleName in sync with pageTitle
        }}
        placeholder="Editable Page Title"
        style={{ marginBottom: '10px', width: '50%' }}
      />

      <Select
        value={selectedSection}
        onChange={handleSectionSelect}
        className={`section-selector ${sections.length > 1 ? 'multiple-sections' : ''}`}
        style={{ marginBottom: '20px', width: '200px' }}>
          {sections.map(section => (
            <Option key={section.id} value={section.id}>
              {section.title}
            </Option>
          ))}
      </Select>

    <div>
          {/* Add Section Button */}
    <Button
      type="primary"
      onClick={addSection}
      icon={<PlusSquareOutlined/>}
      style={{ marginBottom: '10px', marginRight: '10px' }}
    >
      Section
    </Button>

    <Button
        type="primary"
        onClick={saveSchema}
        style={{ marginTop: '10px',
          backgroundColor: 'green',
         }}
        icon={<SaveOutlined/>}
        className='save-schema-button'
      >
        Save
      </Button>
      </div>

      <FieldPalette
          onAddField={(fieldType: string) => onAddField(fieldType, selectedSection)}
          availableFields={availableFields}
        />
      {sections.map((section) => {
        return (
          <div key={section.id}>
              <PageOrganizer
                sections={[section]}
                setSections={setSections}
                onFieldUpdate={onFieldUpdate}
                onFieldDelete={onFieldDelete}
                pageType={'Reference'}
                // onRenameSection={onRenameSection}
              />
            <Button
              type='primary'
              style={{background: 'red',
                marginBottom: '30px',
                marginTop: '-10px'
              }}
              icon={<DeleteOutlined/>}
              onClick={() => deleteSection(section.id)
              }
                >Section</Button>
          </div>
        )
      })}


      {/*{userPermissions.canViewFunctions && (*/}
      {/*  <div>*/}
      {/*  <Button onClick={() => setIsProcessModalVisible(true)} style={{ marginLeft: '10px' }}>*/}
      {/*    Add Function*/}
      {/*  </Button>*/}

      {/*  <h3>Functions</h3>*/}
      {/*  <Table dataSource={functions} columns={functionColumns} rowKey="id" />*/}

      {/*  /!* Modal for adding new functions using ProcessBuilder *!/*/}
      {/*  <Modal*/}
      {/*    title="Process Builder"*/}
      {/*    open={isProcessModalVisible}*/}
      {/*    onCancel={() => setIsProcessModalVisible(false)}*/}
      {/*    footer={null}  // Custom footer is not needed because ProcessBuilder will handle saving*/}
      {/*  >*/}
      {/*  <ProcessBuilder*/}
      {/*    existingFunction={functionToEdit}  // Pass the existing functions*/}
      {/*    onSaveFunction={(newFunction) => {*/}
      {/*      onSaveFunction(newFunction);  // Save the function*/}
      {/*      setIsProcessModalVisible(false);  // Close the modal*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  </Modal>*/}
      {/*  </div>*/}
      {/*)}*/}


    </div>
  );
};

export default ReferencePageBuilder;
