import React, { useEffect, useState } from 'react';
import { Select, Spin, Alert, Menu, Layout, Button, Tooltip } from 'antd';
import { useTenant } from '../contexts/TenantContext';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { signOut } from "supertokens-auth-react/recipe/session";
import { AuditOutlined , HomeOutlined, LogoutOutlined, SettingOutlined} from '@ant-design/icons';
import SideMenuRender from './SideMenuRender';
import '../styles/profileSelector.css'
import TourManager from '../contexts/tour/tourManager';
import { tourSteps } from '../contexts/tour/tourSteps'; // Import your tour steps
import '../styles/tourStyles.css'
// import dataAll from "../utils/dataAll";

const { Option } = Select;
const { Sider } = Layout;

interface Tenant {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
  code?: string;
  tenant_uuid?: string;
  Companies?: any; // Adjust the type of Companies if known
}


interface TenantsData {
  tenants: Tenant[];
}

const ProfileSelector: React.FC = () => {
  const { tenantId, setTenantId, companyId, setCompanyId } = useTenant();
  const [tenants, setTenants] = useState<TenantsData | null>(null); // Handle initial null case
  const [companies, setCompanies] = useState([]);
  const [loadingTenants, setLoadingTenants] = useState(true);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [user, setUser] = useState<{
    defaultTenantId: number;
    email: string;
    roles: string[];
    uuid: string;
  } | null>(null);


  useEffect(() => {
    const foundUser = localStorage.getItem('user')
        ? JSON.parse(localStorage.getItem('user') || '{}')
        : null;
    setUser(foundUser);
  }, []);

  const navigate = useNavigate();
  const location = useLocation();  // To highlight the selected menu item

  useEffect(() => {
    // Check if tenantId exists in localStorage when the component mounts
    const storedTenantId = localStorage.getItem('tenantId');
    if (storedTenantId) {
      setTenantId(parseInt(storedTenantId, 10)); // Parse the stored tenantId
    }
    const storedCompanyId = localStorage.getItem('companyId');
    if (storedCompanyId) {
      setCompanyId(parseInt(storedCompanyId, 10)); // Parse the stored companyId
    }
  }, [setTenantId, setCompanyId]);


  // Fetch companies when tenantId changes
  useEffect(() => {
    if (!tenantId) return;

    const fetchCompanies = async () => {
      setLoadingCompanies(true);
      try {
        const response = await axios.get(`${API_URL}/company/bytenant/${tenantId}`);
        setCompanies(response.data);
        setLoadingCompanies(false)
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [tenantId]);

  // Handle tenant change
  const handleTenantChange = (selectedTenantId: number) => {
    if (tenantId !== selectedTenantId) {
      setTenantId(selectedTenantId);
      localStorage.setItem('tenantId', selectedTenantId.toString());
      setCompanyId(null);
    }
};

  // Handle company change
  const handleCompanyChange = (selectedCompanyId: number) => {
    setCompanyId(selectedCompanyId);
    localStorage.setItem('companyId', selectedCompanyId.toString()); // Store companyId in localStorage
  };

  let menuItems = [];
  if (user?.roles.includes('quickbuildadmin')) {
    menuItems = [
      // All admin specific items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/home'), className: 'side-menu-home' },
      { key: 'builder', icon: <AuditOutlined />, label: 'Builder', onClick: () => navigate('/builder'), className: 'side-menu-builder' },
    ];
  }

  else if (user?.roles.includes('builder')) {
    menuItems = [
      // Only user accessible items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/home'), className: 'side-menu-home' },
      { key: 'builder', icon: <AuditOutlined />, label: 'Builder', onClick: () => navigate('/builder'), className: 'side-menu-builder' },
    ];
  }

  else if (user?.roles.includes('freetrial')) {
    menuItems = [
      // Only user accessible items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/home'), className: 'side-menu-home' },
      { key: 'builder', icon: <AuditOutlined />, label: 'Builder', onClick: () => navigate('/builder'), className: 'side-menu-builder' },
    ];
  }

  else  { //if (user?.roles.includes('user')) {
    menuItems = [
      // Only user accessible items
      { key: 'home', icon: <HomeOutlined />, label: 'Home', onClick: () => navigate('/home'), className: 'side-menu-home' },
    ];
  }

  async function handleLogout() {
    // Clear authentication data
    await signOut();
    localStorage.removeItem('companyId')
    localStorage.removeItem('tenantId')
    localStorage.removeItem('authToken');
    window.location.href = "/home";
  };



  useEffect(() => {
    const isAdmin = user?.roles.includes('quickbuildadmin');
    // If user is admin, fetch all tenants
    if (isAdmin) {
      // const fetchTenants = async () => {
      //   setLoadingTenants(true);
      //   try {
      //     const tenantData = await dataAll.dataExtractUtility.getTenants()
      //     setTenants(tenantData);
      //     setLoadingTenants(false);
      //   } catch (error) {
      //     console.error(error);
      //   }
      // }

      const fetchTenants = async () => {
        setLoadingTenants(true);
        try {
          const response = await axios.get(`${API_URL}/tenants`);
          console.log('Tenants:', response.data);

          setTenants(response.data);
          setLoadingTenants(false);
        } catch (err) {
          console.error('Error fetching tenants:', err);
          setError('Failed to fetch tenants.');
          setLoadingTenants(false);
        }
      };
      fetchTenants();
    } else {
      // For non-admin users, set tenantId to defaultTenantId from UserContext
      if (user?.defaultTenantId) {
        setTenantId(user.defaultTenantId);
      }
    }
  }, [user]);

  return (
    <Layout className="profile-content" style={{ minHeight: '100vh' }}>
      {location.pathname === '/home' && <TourManager steps={tourSteps.profileSelector} />}
      <Sider className='scrollable-slider' width={200}>
        <div className="profile-selector" style={{ padding: '10px' }}>
          {/* Conditionally render tenant and company selectors for admins */}
          {user?.roles.includes('quickbuildadmin') && (
            <>
              {loadingTenants ? (
                <Spin tip="Loading tenants..." />
              ) : error ? (
                <Alert message="Error" description={error} type="error" showIcon />
              ) : (
                <>
                  <Select
                    placeholder="Select a Tenant"
                    style={{ width: '100%', marginBottom: 10 }}
                    value={tenantId || undefined}
                    onChange={handleTenantChange}
                  >
                    {tenants?.tenants?.map((tenant) => (
                      <Option key={tenant.id} value={tenant.id}>
                        {String(tenantId) && ": " && tenant.name}
                      </Option>
                    ))}
                  </Select>

                  <Select
                    placeholder="Select a Company"
                    style={{ width: '100%', marginBottom: 10 }}
                    value={companyId || undefined}
                    onChange={handleCompanyChange}
                    loading={loadingCompanies}
                    disabled={!tenantId || loadingCompanies}
                  >
                    {companies.map((company) => (
                      <Option key={company.id} value={company.id}>
                        {company.companyName}
                      </Option>
                    ))}
                  </Select>
                </>
              )}
            </>
          )}
        </div>

        <Menu
          mode="inline"
          theme="dark"  // Optional: Dark theme for the side menu
          selectedKeys={[location.pathname]}
          items={menuItems}
        />
        <SideMenuRender/> {/* Pass tenantId and companyId */}
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Tooltip title="Logout" placement="bottom">
          <Button
            className="logout-btn"
            onClick={handleLogout}
            icon={<LogoutOutlined />}
            style={{ marginTop: '20px', width: '90%', marginLeft: '5%' }}
          />
        </Tooltip>
        <Tooltip title="Settings" placement="bottom">
          <Button
            className="settings-btn"
            onClick={() => navigate('/settings')}
            icon={<SettingOutlined />}
            style={{ marginTop: '20px', width: '90%', marginLeft: '5%', marginRight: '5%' }}
          />
        </Tooltip>
        </div>
      </Sider>
            {/* Settings Modal */}
      {/*<SettingsPage*/}
      {/*  // isVisible={isSettingsVisible}*/}
      {/*  // onClose={closeSettings}*/}
      {/*/>*/}
    </Layout>
  );
};

export default ProfileSelector;
