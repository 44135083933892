export const applyFilters = (
    records,
    stageFilter,
    columnFilters,
    extractValue,
    moduleData,
    isDetailView,
    flattenLineItems,
    flattenParentData
) => {
    if (!Array.isArray(records)) {
        console.error('Records should be an array.');
        return [];
    }

    let tempRecords = [...records];

    if (isDetailView) {
        tempRecords = tempRecords.flatMap(record =>
            flattenLineItems(record, flattenParentData(record))
        );
    }

    if (stageFilter) {
        tempRecords = tempRecords.filter(record => record.stageId === stageFilter);
    }

    Object.keys(columnFilters).forEach(dataIndex => {
        const filter = columnFilters[dataIndex];
        if (filter && filter.selectedValue) {
            tempRecords = tempRecords.filter(record => {
                const recordValue = extractValue(record, dataIndex, moduleData.pageType);
                return recordValue === filter.selectedValue;
            });
        }
    });

    return tempRecords;
};
