// AutoIncrementIDRender.tsx
import React from 'react';
import { Form, Input } from 'antd';

interface AutoIncrementIDRenderProps {
  field: any;
  form: any;
  isEditing: boolean;
  value: any;
}

const AutoIncrementIDRender: React.FC<AutoIncrementIDRenderProps> = ({
  field,
  form,
  isEditing,
  value,
}) => {
  // console.log('auto increment val: ', value)
  return (
    <Form.Item 
        name={field.name} 
        label={field.label || field.name}
        initialValue={value}
      >
      {isEditing ? (
        <Input disabled />
      ) : (
        <Input disabled placeholder="Will Populate On Save" />
      )}
    </Form.Item>
  );
};

export default AutoIncrementIDRender;