// TourContext.tsx
import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context
interface TourContextProps {
  currentStep: number;
  setCurrentStep: (step: number) => void;
  totalSteps: number;
}

// Create the context with undefined as default
const TourContext = createContext<TourContextProps | undefined>(undefined);

// Custom hook to use the TourContext
export const useTour = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
};

// Define the props for TourProvider, including children
interface TourProviderProps {
  children: ReactNode;
}

// Implement TourProvider with typed props
export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const totalSteps = 2; // Update based on your tour steps

  return (
    <TourContext.Provider value={{ currentStep, setCurrentStep, totalSteps }}>
      {children}
    </TourContext.Provider>
  );
};