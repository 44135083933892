// utilityFunctions.js

import dataAll from "./dataAll";
import {message, Modal} from "antd";
import axios from "axios";
import dayjs from "dayjs";
import { v4 as uuidv4 } from 'uuid';
import {renderFieldObjects} from "./renderFieldObjects";


const initializePage = {
    async initializePage(moduleId, setModuleData, setRecords) {
        try {
            const pageModData = await dataAll.dataExtractUtility.getModuleData(moduleId);
            const thisModuleData = pageModData.moduleData;
            setModuleData(thisModuleData);

            let recordsResponse;
            const tenantId = thisModuleData.tenantId;
            const companyId = thisModuleData.companyId;
            const pageType = thisModuleData.pageType;

            if (pageType === 'Reference') {
                recordsResponse = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, moduleId);
            } else if (pageType === 'Transaction') {
                const rawRecords = await dataAll.dataExtractUtility.getTransactionData(tenantId, companyId, moduleId);
                recordsResponse = rawRecords.filter(record => !record.movedToNextStage);
            }

            const recordsWithAggregates = await this.fetchAggregateValues(recordsResponse,thisModuleData); // await fetchAggregateValues(recordsResponse, thisModuleData);
            setRecords(recordsWithAggregates);
        } catch (error) {
            console.error('Failed to fetch module data: ', error);
        }
    },

    initializeTransactionForm(moduleData, existingRecord, form, setTableData, rowCounters) {
        const formValues: Record<string, any> = {};
        moduleData.sections.forEach((section) => {
            if (section.type === 'section') {
                section.fields.forEach((fld) => {
                    // Find the corresponding field in the existing record
                    const getFieldEntry = (record, fieldId) => {
                        if (record?.data) {
                            // Header view scenario
                            return record?.data?.find((item) => item.Key === fieldId);
                        } else {
                            // Detail view scenario (fields are directly within the record)
                            return record?.[fieldId] !== undefined ? { Value: record[fieldId] } : null;
                        }
                    };
                    const fieldEntry = getFieldEntry(existingRecord, fld.id);
                    // const fieldEntry = existingRecord?.data?.find((item) => item.Key === fld.id);
                    if (fld.type === 'DateField') {
                        const rawDate = fieldEntry ? renderFieldObjects(fld.type, fieldEntry.Value) : null;
                        formValues[fld.name] = rawDate ? dayjs(rawDate) : null;
                    }
                    // else if (fld.type === 'AutoIncrement') {
                    //     const rawDate = fieldEntry ? renderFieldObjects(fld.type, fieldEntry.Value) : null;
                    //     formValues[fld.name] = rawDate ? dayjs(rawDate) : null;
                    // }
                    else if (fieldEntry && Array.isArray(fieldEntry.Value)) {
                        formValues[fld.name] = renderFieldObjects(fld.type, fieldEntry.Value);
                    } else {
                        formValues[fld.name] = renderFieldObjects(fld.type, fieldEntry?.Value);
                    }
                });
            } else if (section.type === 'table') {
                let tableEntry;
                let tableRows = [];

                if (Array.isArray(existingRecord?.[section.id])) {
                    // New schema: Directly store arrays for each section
                    const tableEntry = existingRecord[section.id];
                    const valueEntry = tableEntry.find((item) => item.Key === "value");
                    tableRows = valueEntry?.Value || [];
                } else {
                    // Legacy schema: Nested inside data array
                    tableEntry = existingRecord?.data?.find((item) => item.Key === section.id);
                    tableRows = tableEntry?.Value.find((v) => v.Key === 'value')?.Value || [];
                }

                // Flatten the table row data
                const formattedRows = tableRows.map((row, index) => {
                    const flattenedRow = { id: `row_${section.id}_${index}` };

                    row.forEach((colObj) => {
                        const colId = colObj.Key;
                        if (Array.isArray(colObj.Value)) {
                            flattenedRow[colId] = renderFieldObjects(
                                moduleData.tableData?.find((table) => table.id === section.id)?.columns.find((col) => col.id === colId)?.type,
                                colObj.Value
                            );
                        } else {
                            flattenedRow[colId] = colObj.Value ?? null;
                        }
                    });
                    return flattenedRow;
                });
                setTableData((prev) => ({ ...prev, [section.id]: formattedRows }));
                rowCounters.current[section.id] = formattedRows.length;
            }
        });

        form.setFieldsValue(formValues);
    },

    async fetchAggregateValues(records, moduleData) {
        const aggregateFields = moduleData.sections
            .filter((section) => section.type === 'section')
            .flatMap((section) => section.fields)
            .filter((field) => field.type === 'AggregateField');

        if (aggregateFields.length === 0) {
            return records;
        }

        const tenantId = moduleData.tenantId;
        const companyId = moduleData.companyId;
        const moduleId = moduleData._id;

        const updatedRecords = await Promise.all(
            records.map(async (record) => {
                const itemId = record._id;

                for (const field of aggregateFields) {
                    try {
                        const response = await axios.get(`${API_URL}/aggregate/value`, {
                            params: {
                                tenantId,
                                companyId,
                                moduleId,
                                fieldId: field.id,
                                itemId,
                            },
                        });
                        const value = response.data.value;
                        record.data = record.data || {};
                        record.data[field.name] = value;
                    } catch (error) {
                        console.error('Failed to fetch aggregate value', error);
                        record.data[field.name] = null;
                    }
                }

                return record;
            })
        );

        return updatedRecords;
    },

    async setFormValues(form, record, moduleData, setTableData) {
        const formValues = {};
        moduleData.sections.forEach(section => {
            if (section.type === 'section') {
                section.fields.forEach(field => {
                    const savedValue = record?.data[field.id]?.value || '';
                    formValues[field.id] = savedValue;
                });
            } else if (section.type === 'table') {
                setTableData(prev => ({ ...prev, [section.id]: record?.data[section.id]?.value || [] }));
            }
        });
        form.setFieldsValue(formValues);
    },

    fetchReferenceData (moduleData, referenceData, setReferenceData, tenantId, companyId){
        const modIds = moduleData.sections
            .flatMap((section) =>
                section.type === 'section'
                    ? section.fields
                    : moduleData.tableData?.find((table) => table.id === section.id)?.columns || []
            )
            .filter((fld) => fld.type === 'ReferenceLinkField' && fld.fieldOptions?.modules)
            .flatMap((fld) => fld.fieldOptions.modules.map((mod) => mod.moduleId))
            .filter((id, index, self) => id && self.indexOf(id) === index);

        modIds.forEach(async (mId) => {
            if (!referenceData[mId]) {
                try {
                    const refData = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, mId);
                    setReferenceData((prev) => ({ ...prev, [mId]: refData.map((r) => ({ ...r.data, _id: r._id })) }));
                } catch (error) {
                    console.error(error);
                }
            }
        });
    },

    async applyFilters (records: any[], stageFilter: string | null, columnFilters: Record<string, any>, extractValue: Function, moduleData: any, isDetailView: boolean, flattenLineItems: Function, flattenParentData: Function) {
        if (!Array.isArray(records) || records.length === 0) {
            return [];
        }

        let filteredRecords = [...records];

        if (isDetailView) {
            filteredRecords = filteredRecords.flatMap(record =>
                flattenLineItems(record, flattenParentData(record))
            );
        }

        // Apply Stage Filter
        if (stageFilter) {
            filteredRecords = filteredRecords.filter(record => record.stageId === stageFilter);
        }

        // Apply Column Filters
        Object.keys(columnFilters).forEach(dataIndex => {
            const filter = columnFilters[dataIndex];
            if (filter && filter.selectedValue) {
                filteredRecords = filteredRecords.filter(record => {
                    const recordValue = extractValue(record, dataIndex, moduleData.pageType);
                    return recordValue === filter.selectedValue;
                });
            }
        });

        return filteredRecords;
    },

    async processTransactionData(form, tableData, moduleData, existingRecord, API_URL) {
        const standardData = {};
        for (const section of moduleData.sections.filter(s => s.type === 'section')) {
            for (const field of section.fields) {
                let finalValue = form.getFieldValue(field.id) || '';
                if (field.type === 'AutoIncrementId' && !existingRecord) {
                    finalValue = await dataAll.dataExtractUtility.getNextAutoIncrement(
                        moduleData.tenantId,
                        moduleData.companyId,
                        moduleData._id,
                        field.id
                    );
                }
                standardData[field.id] = { label: field.name, value: finalValue };
            }
        }

        const tableBlocks = {};
        moduleData.sections.filter(s => s.type === 'table').forEach(section => {
            const rows = tableData[section.id] || [];
            tableBlocks[section.id] = {
                label: section.title || section.id,
                value: rows.map(row => Object.fromEntries(Object.entries(row).map(([key, val]) => [key, { label: key, value: val }]))),
            };
        });

        return { ...standardData, ...tableBlocks };
    },

    async buildMovedStageData(formVals: any, nextStage: any, moduleData: any) {
        const movedStageData: Record<string, any> = {};
        // Loop over sections to process form values
        for (const section of moduleData.sections.filter((s: any) => s.type === 'section')) {
            for (const fld of section.fields) {
                const rawVal = formVals[fld.name];
                let finalVal = rawVal;

                if (fld.type === 'DateField' && rawVal) {
                    finalVal = dayjs(rawVal).isValid() ? dayjs(rawVal).toISOString() : null;
                } else if (rawVal?.value !== undefined) {
                    finalVal = rawVal.value;
                }

                movedStageData[fld.id] = {
                    label: fld.name,
                    value: finalVal ?? '',
                };
            }
        }
        // Add new stage ID to the record
        movedStageData.stageId = nextStage.name
        // {
        //     label: 'Stage',
        //     value: nextStage.name,
        // }
        return movedStageData;
    },

    async deleteTransactionData(recordId, pageType, setRecords) {
        Modal.confirm({
            title: 'Are you sure you want to delete this record?',
            content: 'This action cannot be undone.',
            okText: 'Yes, delete it',
            okType: 'danger',
            cancelText: 'No',
            async onOk() {
                try {
                    await dataAll.dataPostUtility.deleteData(pageType, recordId);
                    setRecords((prev) => prev.filter((r) => r._id !== recordId));
                    message.success('Record deleted successfully.');
                } catch (error) {
                    message.error('Failed to delete the record.');
                }
            },
        });
    },

    async submitRecord(recordData, existingRecord, setRecords, closeModal, API_URL) {
        try {
            if (existingRecord?._id) {
                await dataAll.dataPostUtility.overwriteData('transaction', existingRecord._id, recordData);
            } else {
                await dataAll.dataPostUtility.postData('transaction', recordData);
            }
            const updatedRecords = await dataAll.dataExtractUtility.getTransactionData(recordData.tenantId, recordData.companyId, recordData.moduleId);
            setRecords(updatedRecords);
            closeModal();
        } catch (error) {
            console.error('Error saving record:', error);
        }
    },

    flattenLineItems: (
        record: any,
        flattenedData: any,
        moduleData: any,
        renderFieldValue: (fieldType: string, value: any) => any
    ) => {
        const lineItems: any[] = [];

        if (!moduleData?.sections) {
            console.warn('Module data is missing sections.');
            return [flattenedData];
        }

        const tableSections = moduleData.sections.filter(
            (section: any) => section.type === 'table'
        );

        tableSections.forEach((tableSection: any) => {
            const tableId = tableSection.id;

            // Find the table data within the record
            const tableValueObj = record.data?.find((item: any) => item.Key === tableId);
            const tableRecords =
                tableValueObj?.Value.find((item: any) => item.Key === 'value')?.Value || [];

            // Check if the table definition exists
            const tableDefinition = moduleData.tableData?.find((item: any) => item.id === tableId);

            if (!tableDefinition || !Array.isArray(tableDefinition.columns)) {
                console.warn(`Table definition or columns missing for tableId: ${tableId}`);
                return; // Skip this table if the definition or columns are invalid
            }

            // Flatten each record in the table
            tableRecords.forEach((lineItem: any, index: number) => {
                const flattenedLineItem: any = { ...flattenedData };

                tableDefinition.columns.forEach((col: any) => {
                    const colValueObj = lineItem?.find((v: any) => v.Key === col.id)?.Value;
                    let colValue;
                    if (Array.isArray(colValueObj)) {
                        colValue = colValueObj.find((v: any) => v.Key === 'value')?.Value ?? null;
                    } else {
                        colValue = colValueObj ?? null;
                    }
                    // Use centralized field value render function
                    flattenedLineItem[col.id] = renderFieldValue(col.type, colValue);
                });

                // Assign a unique lineItemId
                flattenedLineItem.lineItemId = `${record._id}-${tableId}-${index}`;
                lineItems.push(flattenedLineItem);
            });
        });

        // Return line items or fallback to the flattenedData if no items exist
        return lineItems.length > 0 ? lineItems : [flattenedData];
    },

    async buildTransactionData (moduleData, formVals, tableData, existingRecord) {
        const standardData = {};
        for (const section of moduleData.sections.filter((s) => s.type === 'section')) {
            for (const fld of section.fields) {
                const rawVal = formVals[fld.name];
                let finalVal = rawVal;
                if (fld.type === 'AutoIncrementId' && !existingRecord) {
                    finalVal = await dataAll.dataExtractUtility.getNextAutoIncrement(moduleData.tenantId, moduleData.companyId, moduleData._id, fld.id)
                } else if (fld.type === 'DateField') {
                    finalVal = dayjs(rawVal).isValid() ? dayjs(rawVal).toISOString() : null;
                }
                standardData[fld.id] = { label: fld.name, value: finalVal ?? '' };
            }
        }

        const tableBlocks = {};
        for (const section of moduleData.sections.filter((s) => s.type === 'table')) {
            tableBlocks[section.id] = {
                label: section.title || section.id,
                value: tableData[section.id] || [],
            };
        }

        return {
            ...standardData,
            ...tableBlocks,
            transactionId: { label: 'Transaction ID', value: existingRecord?.transactionId || uuidv4() },
        };
    },
};

export { initializePage };
