import { InputNumber } from "antd";

export const NumberFieldConfig: React.FC<{
    fieldOptions: any;
    setFieldOptions: (options: any) => void;
  }> = ({ fieldOptions, setFieldOptions }) => {
    const numberPrecision = Number(fieldOptions.decimalPrecision?.$numberInt || 0);

    return (
      <div style={{ marginBottom: '10px' }}>
        <label>Decimal Precision:</label>
        <InputNumber
          min={0}
          max={10}
          value={numberPrecision}
          placeholder="How many decimals should this allow? 0, 2, and 4 are common. No more than 10"
          onChange={(value) => setFieldOptions({ ...fieldOptions, decimalPrecision: value })}
          style={{ width: '100%' }}
        />
      </div>
    );
  };