import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { Modal, Form, Button, Typography, message, Table } from 'antd';
import { LinkOutlined } from '@ant-design/icons';
import { renderField } from './_RenderField';
import '../../../styles/fields.css'; 
import { v4 as uuidv4 } from 'uuid'; 
import { OneToManyFieldRenderProps } from './OneToManyTypes';
import { parsePhoneNumberFromString } from 'libphonenumber-js';


const { Text } = Typography;

const OneToManyFieldRender: React.FC<OneToManyFieldRenderProps> = ({
  field,
  form,
  referenceData,
  handleFieldChange,
  moduleData,
  tableData,
  fieldValue = [], // Default to empty array if not provided
  renderType = 'section',
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [subRecords, setSubRecords] = useState(fieldValue || []);
    const [currentEditRecord, setCurrentEditRecord] = useState(null);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [otmForm] = Form.useForm();

    // 1) On mount or when fieldValue changes, reset subRecords
    useEffect(() => {
        setSubRecords(fieldValue || []);
    }, [fieldValue]);


    // Ensure parent form syncs with `subRecords` on every render or when subRecords changes
    // useEffect(() => {
    //     if (subRecords != fieldValue) {
    //         handleFieldChange(field.id, subRecords); // Sync `subRecords` to parent
    //     }
    // }, [subRecords, handleFieldChange, field.id, fieldValue]);

    const handleEditRecord = useCallback((record) => {
        setCurrentEditRecord(record);
        setEditModalVisible(true);
    }, []);

    const handleDeleteRecord = useCallback(
        (recordId) => {
            const updated = subRecords.filter((r) => r.id !== recordId);
            setSubRecords(updated);
            handleFieldChange(field.id, updated); // sync to parent
            message.success('Record deleted successfully.');
        },
        [field.id, handleFieldChange, subRecords]
    );

    const sections = field.fieldOptions?.otmSections || field.fieldOptions?.sections;
    const columns = useMemo(() => {
        if (!sections?.[0]?.fields) return [];

        const cols = sections[0].fields.map((f) => ({
            title: f.name,
            dataIndex: f.id,
            key: f.id,
            render: (_, record) => {
                const val = record[f.id]?.value;
                if (f.type === 'ReferenceLinkField') {
                    return val?.label || '';
                } else if (f.type === 'OneToManyField') {
                    return Array.isArray(val) ? val.length : '';
                } else if (f.type === 'PhoneNumber') {
                    if (val?.phone) {
                        const phoneNumber = parsePhoneNumberFromString(val.phone, 'US');
                        return phoneNumber ? phoneNumber.formatNational() : val.phone;
                    }
                    return '';
                }
                return val || '';
            },
        }));

        // Add actions column once, here in the memo
        cols.push({
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
                <>
                    <Button type="link" onClick={() => handleEditRecord(record)}>Edit</Button>
                    <Button type="link" danger onClick={() => handleDeleteRecord(record.id)}>Delete</Button>
                </>
            ),
        });
        return cols;
    }, [sections, handleEditRecord, handleDeleteRecord]);


  // if (columns.length > 0) {
  //   columns.push({
  //     title: 'Actions',
  //     key: 'actions',
  //     render: (_: any, record: any) => (
  //       <>
  //         <Button type="link" onClick={() => handleEditRecord(record)}>
  //           Edit
  //         </Button>
  //         <Button type="link" danger onClick={() => handleDeleteRecord(record.id)}>
  //           Delete
  //         </Button>
  //       </>
  //     ),
  //   });
  // }

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);
  const closeEditModal = () => {
    setCurrentEditRecord(null);
    setEditModalVisible(false);
    otmForm.resetFields();
  };

  const handleSaveRecord = () => {
    otmForm.validateFields()
      .then(values => {
        let updatedRecords = [...subRecords];

        // Transform the form values into the schema format
        const transformedRecord: any = {};
        const sections = field.fieldOptions?.otmSections || field.fieldOptions?.sections;
        sections?.[0]?.fields.forEach((fieldDef: any) => {
            const fieldValue = values[fieldDef.name]; // Use field name from the form
            transformedRecord[fieldDef.id] = {
              label: fieldDef.name,
              value: fieldValue || null,
            };
        });

        if (currentEditRecord && currentEditRecord.id) {
          // Update an existing record
          const index = updatedRecords.findIndex(r => r.id === currentEditRecord.id);
          if (index !== -1) {
            updatedRecords[index] = { ...updatedRecords[index], ...transformedRecord, id: currentEditRecord.id };
            message.success('Record updated successfully.');
          }
        } else {
          // Add a new record
          const newRecord = {
            ...transformedRecord,
            id: uuidv4(), // Add a unique ID
          };
          updatedRecords.push(newRecord);
          message.success('Record added successfully.');
        }

        setSubRecords(updatedRecords);
        handleFieldChange(field.id, updatedRecords); // Update parent field with the new records
        closeEditModal();
      })
      .catch(error => console.error('Validation Failed:', error));
  };


  return (
    <>
      <Form.Item
        label={field.name}
        required={field.fieldOptions?.required || false}
        style={{ marginBottom: '16px' }}
      >
        <Button type="link" icon={<LinkOutlined />} onClick={openModal}>
          Open {field.name}
        </Button>
      </Form.Item>

      <Modal
        title={`Configure ${field.name}`}
        open={isModalVisible}
        onCancel={closeModal}
        onOk={() => {
            handleFieldChange(field.id, subRecords);
            setIsModalVisible(false); // Close the modal after updating
          }}
        width={800}
        destroyOnClose
      >

        {sections?.length > 0 ? (
          <>
            <div style={{ marginBottom: '16px' }}>
              <Button type="primary" onClick={() => handleEditRecord(null)}>
                + New
              </Button>
            </div>
            <Table
              dataSource={subRecords}
              columns={columns}
              rowKey="id"
              pagination={false}
            />
          </>
        ) : (
          <Text>No sections defined for this field.</Text>
        )}
      </Modal>

      <Modal
        title={currentEditRecord ? 'Edit Record' : 'Add New Record'}
        open={editModalVisible}
        onCancel={closeEditModal}
        onOk={handleSaveRecord}
        destroyOnClose
      >
        <Form form={otmForm} layout="vertical" initialValues={currentEditRecord || {}}>
          {sections?.[0]?.fields.map((f: any) =>
            renderField(
              f,
              field.id,
              otmForm,
              referenceData,
              value => otmForm.setFieldsValue({ [f.id]: value }),
              moduleData,
              tableData,
              undefined,
              undefined,
              false,
              undefined,
              undefined,
              undefined,
              undefined,
              f.fieldOptions?.required || false,
              'section',
              undefined,
              true,
              currentEditRecord?.[f.id]?.value // Pass the current value
            )
          )}
        </Form>
      </Modal>
    </>
  );
};

export default OneToManyFieldRender;
