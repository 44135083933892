import dataPostUtility from './dataPost'
import dataCleanUtility from './dataClean';
import dataPrepUtility from './dataPrep';
import dataExtractUtility from './dataExtract';

const dataAll = {
    dataPostUtility,
    dataCleanUtility,
    dataPrepUtility,
    dataExtractUtility,
};

export default dataAll;