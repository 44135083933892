// Tooltip.tsx
import React from 'react';
import '../../styles/tourStyles.css'

interface TooltipProps {
  content: string;
  position: 'left' | 'right' | 'top' | 'bottom';
  targetRect: DOMRect | null;
  // onNext: () => void;
  onSkip: () => void;
}

const Tooltip: React.FC<TooltipProps> = ({ content, position, targetRect, onSkip }) => {
  if (!targetRect) return null;

  const calculateStyle = (): React.CSSProperties => {
    const style: React.CSSProperties = {
      position: 'absolute',
      padding: '10px',
      backgroundColor: '#333',
      color: '#fff',
      borderRadius: '4px',
      maxWidth: '300px',
      zIndex: 1050,
      pointerEvents: 'auto',
    };

    switch (position) {
      case 'right':
        style.left = targetRect.right + 10 + 'px';
        style.top = targetRect.top + window.scrollY + 'px';
        break;
      case 'left':
        style.left = targetRect.left - 310 + 'px'; // 300px width + 10px padding
        style.top = targetRect.top + window.scrollY + 'px';
        break;
      case 'top':
        style.left = targetRect.left + 'px';
        style.top = targetRect.top - 60 + window.scrollY + 'px'; // 50px height + 10px padding
        break;
      case 'bottom':
        style.left = targetRect.left + 'px';
        style.top = targetRect.bottom + window.scrollY + 'px';
        break;
      default:
        style.left = targetRect.right + 10 + 'px';
        style.top = targetRect.top + window.scrollY + 'px';
    }

    return style;
  };

  return (
    <div style={calculateStyle()}>
      <p style={{ 
        color: '#ddd',
        width: 200, 
         }}>{content}</p>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        <button
          onClick={onSkip}
          style={{
            backgroundColor: '#f00',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            padding: '5px 10px',
            cursor: 'pointer',
          }}
        >
          Skip Tutorial
        </button>
      </div>
    </div>
  );
};

export default Tooltip;