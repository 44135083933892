import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface PhoneNumberRenderProps {
  field: any;
  handleFieldChange: (value: { phone: string; ext: string }) => void;
  value?: any //{ phone: string; ext?: string };
  renderType?: 'table' | 'section';
  required?: boolean;
  form?: any;
}

const PhoneNumberRender: React.FC<PhoneNumberRenderProps> = ({
  field,
  handleFieldChange,
  value = { phone: '', ext: '' },
  renderType,
  required,
  form
}) => {

  const [localValue, setLocalValue] = useState({ phone: value.phone || '', ext: value.ext || '' });

  // useEffect(() => {
  //     if (form) {
  //         form.setFieldsValue({ [field.id]: localValue });
  //     }
  // }, [localValue]);

  const handlePhoneChange = (phone: string) => {
    const updatedValue = { ...localValue, phone };
    setLocalValue(updatedValue); // Update local state
    handleFieldChange(updatedValue); // Pass updated value to parent
    updateForm(updatedValue); // Sync with form and parent
  };

  // const handleExtensionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const ext = e.target.value.slice(0, 5); // Limit to 5 characters
  //   const updatedValue = { ...localValue, ext };
  //   setLocalValue(updatedValue); // Update local state
  //   handleFieldChange(updatedValue); // Pass updated value to parent
  //   // console.log('updated after ext: ', updatedValue)
  //   updateForm(updatedValue); // Sync with form and parent
  // };

  const updateForm = (updatedValue: { phone: string; ext: string }) => {
    if (form) {
      form.setFieldsValue({ [field.name]: updatedValue }); // Update the form state
    }
    // console.log('phone number form values: ', form.getFieldsValue())
  };


  if (renderType === 'table') {
    return (
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <PhoneInput
          country="us"
          value={localValue.phone || ''}
          onChange={handlePhoneChange}
          inputStyle={{ width: '92%' }}
          placeholder="Phone number"
        />
        {/* <Input
          value={localValue.ext}
          onChange={handleExtensionChange}
          placeholder="Ext"
          maxLength={5}
          style={{ width: '30%' }}
        /> */}
      </div>
    );
  }

  return (
    <Form.Item
      label={field.name}
      name={field.name} // Ensures the form recognizes this field
      initialValue={value} // Pass initial values
      required={required}
    >
      <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
        <PhoneInput
          country="us"
          value={localValue.phone || ''}
          onChange={handlePhoneChange}
          inputStyle={{ width: '92%' }}
          placeholder="Phone number"
        />
        {/* <Input
          value={localValue.ext}
          onChange={handleExtensionChange}
          placeholder="Ext"
          maxLength={5}
          style={{ width: '20%' }}
        /> */}
      </div>
    </Form.Item>
  );
};

export default PhoneNumberRender;