// ImportDataModal.tsx
import React, {useEffect, useState} from 'react';
import { Upload, Button, Progress, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import { processSavePayload } from '../../utils/saveUtility'
import * as XLSX from "xlsx";

type ModuleDataType = {
    tenantId?: string;
    companyId?: string;
    _id?: string;
    sections?: {
        fields: {
            id: string;
            name: string;
            type: string;
        }[];
    }[];
};

const ImportDataModal = ({ moduleId, tenantId, companyId, onClose, onImportSuccess }) => {
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [moduleData, setModuleData] = useState({});

    useEffect(() => {
        fetchModuleData();
    }, []);

    const fetchModuleData = async () => {
        try {
            const moduleResponse = await axios.get(
                `${API_URL}/modules/${moduleId}`
            );
            setModuleData(moduleResponse.data);

        } catch (err: any) {
            if (err.response && err.response.status === 404) {
            } else {
                message.error(err.message);
            }
            }
    };


    const parseExcelFile = async (file: File): Promise<any[]> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = new Uint8Array(event.target?.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const rows = XLSX.utils.sheet_to_json(worksheet);
                    resolve(rows);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    };

    const parseWorkbook = async (file: File): Promise<XLSX.WorkBook> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = new Uint8Array(event.target?.result as ArrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    resolve(workbook);
                } catch (error) {
                    reject(error);
                }
            };
            reader.onerror = (error) => reject(error);
            reader.readAsArrayBuffer(file);
        });
    };

    const parseExcelSheet = (workbook: XLSX.WorkBook, sheetName: string): any[] | null => {
        const worksheet = workbook.Sheets[sheetName];
        if (!worksheet) {
            console.warn(`Sheet "${sheetName}" not found in the workbook.`);
            return null;
        }
        return XLSX.utils.sheet_to_json(worksheet);
    };

    const processAutoIncrementFields = async (row: any, moduleData: ModuleDataType) => {
        const autoIncrementFields: { id: string; name: string; type: string }[] = [];

        if (Array.isArray(moduleData.sections)) {
            moduleData.sections.forEach((section) => {
                if (Array.isArray(section.fields)) {
                    section.fields.forEach((field) => {
                        if (field.type === 'AutoIncrementId') {
                            autoIncrementFields.push(field);
                        }
                    });
                }
            });
        }

        for (const field of autoIncrementFields) {
            try {
                const response = await axios.get(`${API_URL}/autoincrement/next`, {
                    params: {
                        tenantId: moduleData.tenantId,
                        companyId: moduleData.companyId,
                        moduleId: moduleData._id,
                        fieldId: field.id,
                    },
                });
                const nextValue = response.data.nextValue;
                row[field.name] = nextValue; // Add auto-increment value to the row
            } catch (error) {
                console.error('Error fetching auto-increment value:', error);
                throw new Error(`Failed to fetch auto-increment value for field: ${field.name}`);
            }
        }
    };

    const processOneToManyFields = async (
        row: any,
        moduleData: ModuleDataType,
        File: File,
        data: any
    ) => {
 // Utility to parse Excel into rows

        const mdSections = moduleData.sections || []
        const otmFlatMap = Array.isArray(mdSections)
            ? mdSections.flatMap((section: any) => section.fields || [])
            : [];
        const oneToManyFields = otmFlatMap.filter(field => field.type === 'OneToManyField')
        const workbook = await parseWorkbook(File);

        oneToManyFields.forEach((otmField) => {
            const sheetName = otmField.name; // Assume OTM field name matches the sheet name
            const otmRows = parseExcelSheet(workbook, sheetName);

            // const otmSheet = workbook.Sheets[sheetName];

            if (!otmRows) {
                console.warn(`No sheet found for OneToManyField: ${otmField.name}`);
                return; // Skip this iteration
            }

            const otmData = XLSX.utils.sheet_to_json(otmRows);

            // if (!Array.isArray(otmData) || otmData.length === 0) {
            //     console.warn(`Sheet "${sheetName}" is empty or invalid.`);
            //     return; // Skip this iteration
            // }
            const firstRow = otmRows[0];
            const matchColumn = Object.keys(firstRow)[0]; // Gets the first key of the first row

            if (!matchColumn) {
                console.warn(`Sheet "${sheetName}" has no valid columns.`);
                return; // Skip this iteration
            }

            const matchedRows = otmRows.filter((otmRow: any) => {
                const matchValue = row[matchColumn];
                if (!matchValue) {
                    console.warn(`No matching value for field: ${otmField.name}`);
                    return false;
                }
                return otmRow[matchColumn] === matchValue; // Compare matchColumn values
            });


            const otmValue = matchedRows.map((matchedRow: any) => {
                const entry: any = {};

                if (
                    !otmField.fieldOptions ||
                    !Array.isArray(otmField.fieldOptions.sections) ||
                    otmField.fieldOptions.sections.length === 0
                ) {
                    console.warn(`Invalid fieldOptions for field: ${otmField.name}`);
                    return null; // Skip if fieldOptions is invalid
                }

                otmField.fieldOptions.sections[0].fields.forEach((innerField: any) => {
                    const fieldValue = matchedRow[innerField.name]; // Map the column name to the value
                    entry[innerField.id] = {
                        label: innerField.name,
                        value: fieldValue || null, // Set null if the value is missing
                    };
                });

                return entry; // Return the constructed entry
            }).filter((entry: any) => entry !== null); // Remove null entries

            data[otmField.id] = {
                label: otmField.name,
                value: otmValue,
            };
        });
    };

    async function processReferenceLinkFields(row, moduleData, data) {
        const referenceDataCache: { [key: string]: any[] } = {};

        async function fetchReferenceData(moduleId) {
            if (!referenceDataCache[moduleId]) {
                try {
                    const response = await axios.get(
                        `${API_URL}/referencedata?moduleId=${moduleId}`
                    );
                    referenceDataCache[moduleId] = response.data.map((record: any) => ({
                        _id: record._id,
                        ...record.data,
                    }));
                } catch (error) {
                    console.error(`Failed to fetch reference data for moduleId: ${moduleId}`, error);
                    referenceDataCache[moduleId] = []; // Prevent further unnecessary calls
                }
            }
            return referenceDataCache[moduleId];
        }

        const mdSections = moduleData.sections || []
        const fieldMap = mdSections.flatMap((section: any) => section.fields || [])
        const referenceLinkFields = fieldMap.filter(field => field.type === 'ReferenceLinkField')


        for (const field of referenceLinkFields) {
            if (field.type === "ReferenceLinkField") {
                const importedValue = row[field.name]; // Excel-imported Cell Value
                const selectedFields = field.fieldOptions?.modules?.[0]?.selectedFields || [];
                const returnFields = field.fieldOptions?.modules?.[0]?.returnFields || [];
                const referenceData = await fetchReferenceData(field.fieldOptions?.modules?.[0]._id); // Fetch from DB

                // Attempt to find a match in referenceData based on the importedValue
                const match = referenceData.find((record) => {
                    // Build the label from selectedFields
                    const label = selectedFields.map((fieldId) => record[fieldId]?.value).join(" - ");
                    return label === importedValue;
                });

                if (match) {
                    // Add the matched returnFields to the payload
                    const value = {};
                    for (const fieldId of returnFields) {
                        value[fieldId] = match[fieldId];
                    }
                    data[field.id] = { label: importedValue, value };
                } else {
                    console.warn(`No match found for ${importedValue} in ReferenceLinkField ${field.name}`);
                }
            }
        }
    }


    const mapRowToSchema = (row: any, moduleData: ModuleDataType, data: any) => {
        if (Array.isArray(moduleData.sections)) {
            moduleData.sections.forEach((section) => {
                if (Array.isArray(section.fields)) {
                    section.fields.forEach((field) => {
                        if (field.type !== 'OneToManyField') {
                            const fieldValue = row[field.name] || null;
                            data[field.id] = {
                                label: field.name,
                                value: fieldValue,
                            };
                        }
                    });
                }
            });
        }
    };

    const handleSave = async (row: any, moduleData: ModuleDataType, workbook: XLSX.WorkBook) => {
        if (!moduleData?.tenantId || !moduleData?.companyId || !moduleData?._id) {
            console.error('Invalid module data:', moduleData);
            throw new Error('Module data is incomplete or missing required fields.');
        }

        const data: any = {};

        // Step 1: Process Auto-Increment Fields
        await processAutoIncrementFields(row, moduleData);

        // Step 2: Process OneToMany Fields
        await processOneToManyFields(row, moduleData, fileList[0], data);

        await processReferenceLinkFields(row, moduleData, data);

        // Step 3: Map Headers to Schema and Build Payload for non-OTM fields
        mapRowToSchema(row, moduleData, data);

        // Step 4: Create the Payload
        const payload = {
            tenantId: moduleData.tenantId,
            companyId: moduleData.companyId,
            moduleId: moduleData._id,
            data,
        };

        // Step 5: Post the Payload to the API
        try {
            const response = await axios.post(`${API_URL}/referencedata`, payload);
            console.log('Record saved successfully:', response.data);
        } catch (error) {
            console.error('Save failed:', error);
            throw new Error('Failed to save the record.');
        }
    };

    const handleUpload = async () => {
        if (fileList.length === 0) {
            message.error('Please select a file to upload.');
            return;
        }

        setUploading(true);

        try {
            // Step 1: Parse the Excel file into an array of rows
            const file = fileList[0];
            const rows = await parseExcelFile(file); // Utility to parse Excel into rows


            // Step 2: Loop through rows and save each record
            let successCount = 0;
            let errorCount = 0;

            for (const row of rows) {
                try {
                    await handleSave(row, moduleData, file); // Save each row using your save logic
                    successCount++;
                } catch (error) {
                    console.error('Error saving row:', row, error);
                    errorCount++;
                }
            }

            // Step 3: Display results
            if (errorCount === 0) {
                message.success(`Successfully saved all ${successCount} records.`);
                onImportSuccess(successCount);
            } else {
                message.warning(
                    `Completed with errors. Successfully saved ${successCount} records. Failed to save ${errorCount} records.`
                );
            }
        } catch (error) {
            message.error('Failed to process the file.');
            console.error('Error processing file:', error);
        } finally {
            setUploading(false);
            setFileList([]);
        }
    };

    const props = {
      onRemove: (file) => {
        setFileList([]);
      },
      beforeUpload: (file) => {
        setFileList([file]);
        return false; // Prevent automatic upload
      },
      fileList,
    };

    return (
      <div>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Select File</Button>
        </Upload>
        {progress > 0 && <Progress percent={progress} />}
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={fileList.length === 0}
          loading={uploading}
          style={{ marginTop: 16 }}
        >
          {uploading ? 'Uploading' : 'Start Import'}
        </Button>
        <Button onClick={onClose} style={{ marginLeft: 8 }}>
          Cancel
        </Button>
      </div>
    );
  };

  export default ImportDataModal;
