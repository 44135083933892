// src/contexts/UserContext.tsx

import React, {createContext, useContext, useState, useEffect} from 'react';
import axios from "axios";


interface User {
    id: number;
    email: string;
    roles: string[];
    defaultTenantId: number; // Added defaultTenantId
}

interface UserContextType {
    user: User | null;
    loading: boolean;
}

const UserContext = createContext<UserContextType | null>(null);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // New loading state

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await getUser();  // Fetch the user data
                setUser(userData);  // Set the user data in state
            } catch (err) {
                console.error('Error fetching user:', err);
            }
        };

        fetchUser();  // Call the function to fetch the user data
        setLoading(false); // Set loading to false after checking the token
    }, []);  // The empty dependency array ensures this runs once on mount

    return (
        <UserContext.Provider value={{user, loading}}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = (): UserContextType => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};

export async function getUser(): Promise<User> {
    try {
        const response = await axios.get(`${API_URL}/users/current`);
        return response.data;
    } catch (err) {
        return null
    }
}
