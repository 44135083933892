import React from 'react';
import { InputNumber, Form } from 'antd';

interface NumberFieldRenderProps {
  field: any;
  handleFieldChange: Function;
  value?: number;
  renderType?: 'table' | 'section';
  required?: boolean
}

const NumberFieldRender: React.FC<NumberFieldRenderProps> = ({
  field,
  handleFieldChange,
  value,
  renderType,
  required
}) => {
  if (renderType === 'table') {
    return (
      <InputNumber
        value={value}
        onChange={(val) => handleFieldChange(val)}
        style={{ width: '100%' }}
      />
    );
  } else {
    return (
      <Form.Item 
        name={field.name} 
        label={field.name}
        required={required}
        initialValue={value}
      >

        <InputNumber
          style={{ width: '100%' }}
          onChange={(val) => handleFieldChange(val)}
        />
      </Form.Item>
    );
  }
};

export default NumberFieldRender;