import React from 'react';
import { Input, Form } from 'antd';

interface EmailFieldRenderProps {
  field: any;
  handleFieldChange: Function;
  value?: string;
  renderType?: 'table' | 'section';
  required?: boolean;
}

const EmailFieldRender: React.FC<EmailFieldRenderProps> = ({
  field,
  handleFieldChange,
  value,
  renderType,
  required
}) => {
  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (email: string) => {
    handleFieldChange(email);
  };

  if (renderType === 'table') {
    return (
      <Input
        value={value}
        onChange={(e) => handleEmailChange(e.target.value)}
        style={{ width: '100%' }}
        type="email"
        placeholder="Enter a valid email"
      />
    );
  } else {
    return (
      <Form.Item
        name={field.name}
        label={field.name}
        required={required}
        initialValue={value}
        rules={[{ type: 'email', message: 'Enter a valid email address!' }]}
      >
        <Input
          onChange={(e) => handleEmailChange(e.target.value)}
          value={value}
          type="email"
          placeholder="Enter a valid email"
        />
      </Form.Item>
    );
  }
};

export default EmailFieldRender;