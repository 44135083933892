// BasePageRender.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {Button, Table, Modal, message, Input, Radio, Select} from 'antd';
import { DeleteOutlined, EditOutlined, ExportOutlined, FilterOutlined,} from '@ant-design/icons';
import axios from 'axios';
import ReferencePageRender from './ReferencePageRender';
// import NestedPageRender from './NestedPageRender';
import TransactionPageRender from './TransactionPageRender';
import ImportDataModal from './ImportDataModal';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import dataAll from "../../utils/dataAll";
import {initializePage} from "../../utils/initializeRender";
import {applyFilters} from "../../utils/applyFilters";
import {renderFieldValue} from "../../utils/renderFieldValues";
import {init} from "supertokens-auth-react";


const BasePageRender: React.FC = () => {
  const { moduleId } = useParams();
  const [moduleData, setModuleData] = useState<any>(null);
  const [records, setRecords] = useState<any[]>([]);
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false); // Added loading state
  const [isDetailView, setIsDetailView] = useState(false);
  const [stageFilter, setStageFilter] = useState<string | null>(null);
  const [columnFilters, setColumnFilters] = useState<Record<string, any>>({});
  const [isScrollEnabled, setIsScrollEnabled] = useState(true); // State to control scrolling
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
  const [commonIdentifier, setCommonIdentifier] = useState(null);

  // const fetchAggregateValues = async (records: any[], moduleData: any) => {
  //   const aggregateFields = moduleData.sections
  //     .filter((section: any) => section.type === 'section')
  //     .flatMap((section: any) => section.fields)
  //     .filter((field: any) => field.type === 'AggregateField');
  //
  //   if (aggregateFields.length === 0) {
  //     return records;
  //   }
  //
  //   const tenantId = moduleData.tenantId;
  //   const companyId = moduleData.companyId;
  //   const moduleId = moduleData._id;
  //
  //   const updatedRecords = await Promise.all(
  //     records.map(async (record) => {
  //       const itemId = record._id;
  //
  //       for (const field of aggregateFields) {
  //         try {
  //           const response = await axios.get(`${API_URL}/aggregate/value`, {
  //             params: {
  //               tenantId,
  //               companyId,
  //               moduleId,
  //               fieldId: field.id,
  //               itemId,
  //             },
  //           });
  //           const value = response.data.value;
  //           record.data = record.data || {};
  //           record.data[field.name] = value;
  //         } catch (error) {
  //           console.error('Failed to fetch aggregate value', error);
  //           record.data[field.name] = null;
  //         }
  //       }
  //
  //       return record;
  //     })
  //   );
  //
  //   return updatedRecords;
  // };

  // const fetchAggregateValues = async (records, moduleData) => {
  //   await initializePage.fetchAggregateValues(records, moduleData).then(setRecords);
  // };

  // const isUsingNewSchema = (record: any): boolean => {
  //   if (!record?.data) return false;
  //
  //   return Object.values(record.data).every((field: any) => {
  //     // Check if the field is an object with `label` and `value`
  //     return (
  //       typeof field === 'object' &&
  //       field !== null &&
  //       'label' in field &&
  //       'value' in field
  //     );
  //   });
  // };

  // const fetchModuleData = async () => {
  //   setLoading(true);
  //   try {
  //     const pageModData = await dataAll.dataExtractUtility.getModuleData(moduleId);
  //     const thisModuleData = pageModData.moduleData;
  //     setModuleData(thisModuleData);
  //     let recordsResponse;
  //     const tenantId: number = thisModuleData.tenantId;
  //     const companyId: number = thisModuleData.companyId;
  //     if (thisModuleData.pageType === 'Reference') {
  //       recordsResponse = await dataAll.dataExtractUtility.getReferenceData(tenantId, companyId, moduleId)
  //     } else if (thisModuleData.pageType === 'Transaction') {
  //       const rawRecords = await dataAll.dataExtractUtility.getTransactionData(tenantId, companyId, moduleId)
  //       recordsResponse = rawRecords.filter((record: any) => !record.movedToNextStage);
  //     }
  //
  //     const recordsWithAggregates = await fetchAggregateValues(recordsResponse, thisModuleData);
  //     setRecords(recordsWithAggregates);
  //   } catch (error) {
  //     console.error('Failed to fetch module data: ', error);
  //   }
  //   finally {
  //     setLoading(false); // End loading
  //   }
  // };

  // useEffect(() => {
  //   fetchModuleData().then(r => console.log(r));
  // }, [moduleId]);

  useEffect(() => {
    initializePage.initializePage(moduleId, setModuleData, setRecords);
  }, [moduleId]);

  useEffect(() => {
    if (moduleData?.pageType !== 'Transaction') {
      setStageFilter(null);
      setIsDetailView(false);
    }
  }, [moduleData?.pageType]);

  const toggleView = () => {
    if (moduleData.pageType === 'Transaction') {
      setIsDetailView(prev => !prev);
    } else {
      setIsDetailView(false);
    }
  };

  const filterRecordsByStage = (stageId: string | null) => {
    setStageFilter(stageId);
  };

  const resetAllFilters = () => {
    setColumnFilters({}); // Resets all column filters
    setStageFilter(null); // Resets the stage filter if used
    // Add any other state resets related to filtering here
  };

  const extractValue = (record, dataIndex, pageType) => {
    if (record.hasOwnProperty('lineItemId')) {
      // For flattened line items, data is directly on the record
      let value = record[dataIndex] || '';
      if (value && typeof value === 'object') {
        return value?.value || value?.label || value?.value || '';
      }
      return value || '';
    } else {
      let value = record?.data[dataIndex]?.value || '';
      let extractedValue

      if (value && typeof value === 'object') {
        extractedValue = value?.value || value?.label || '';
      }
      else {
        extractedValue = value || ''
      }
      return extractedValue
    }
  };

  // const getColumnUniqueValues = (dataIndex: string, data: any[], pageType: string) => {
  //   const values = data.map(record => extractValue(record, dataIndex, pageType));
  //   // Filter out undefined values and return unique values
  //   return Array.from(new Set(values.filter(value => value !== undefined)));
  // };

  const getColumnUniqueValues = (dataIndex: string, data: any[], pageType: string) => {
    if (!Array.isArray(data)) {
      console.warn(`getColumnUniqueValues: Expected an array but received`, data);
      return [];  // Return an empty array if data is undefined or not an array
    }

    const values = data.map(record => extractValue(record, dataIndex, pageType));
    return Array.from(new Set(values.filter(value => value !== undefined && value !== null)));
  };

  const getColumnFilterProps = (dataIndex, fieldType) => {
    const uniqueValues = moduleData?.pageType && Array.isArray(records)
        ? getColumnUniqueValues(dataIndex, records, moduleData.pageType)
        : [];

    const onFilter = (value, record) => {
      if (!record?.data) return false;

      const checkMatch = (fieldValue, filterValue) => {
        if (typeof fieldValue === 'object' && fieldValue !== null) {
          if ('label' in fieldValue) {
            return fieldValue.label === filterValue;
          } else if ('value' in fieldValue) {
            return typeof fieldValue.value === 'string'
                ? fieldValue.value === filterValue
                : fieldValue.value.label === filterValue;
          }
        }
        return fieldValue === filterValue;
      };

      const recordValue = record.data[dataIndex];
      if (recordValue && checkMatch(recordValue, value)) {
        return true;
      }

      if (moduleData?.pageType === 'Transaction' && record.data.tableData) {
        for (const tableKey in record.data.tableData) {
          const tableRecords = record.data.tableData[tableKey];
          for (const lineItem of tableRecords) {
            for (const key in lineItem) {
              const field = lineItem[key];
              if (field && field.label === dataIndex && checkMatch(field, value)) {
                return true;
              }
            }
          }
        }
      }

      return false;
    };

    return {
      filterDropdown: ({ confirm }) => (
          <div style={{ padding: 8 }}>
            <Input
                placeholder={`Search ${dataIndex}`}
                value={columnFilters[dataIndex]?.searchText || ''}
                onChange={e => {
                  const currValue = e.target.value;
                  setColumnFilters(prev => ({
                    ...prev,
                    [dataIndex]: {
                      ...prev[dataIndex],
                      searchText: currValue,
                    },
                  }));
                }}
                style={{ marginBottom: 8, display: 'block' }}
            />
            <div style={{ maxHeight: 200, overflowY: 'auto' }}>
              <Radio.Group
                  onChange={e => {
                    setColumnFilters(prev => ({
                      ...prev,
                      [dataIndex]: {
                        ...prev[dataIndex],
                        selectedValue: e.target.value,
                      },
                    }));
                  }}
                  value={columnFilters[dataIndex]?.selectedValue}
                  style={{ display: 'flex', flexDirection: 'column' }}
              >
                {uniqueValues.map((value, index) => (
                    <Radio key={index} value={value}>
                      {value}
                    </Radio>
                ))}
              </Radio.Group>
            </div>
            <Button
                type="primary"
                onClick={() => confirm()}
                size="small"
                style={{ width: 90, marginTop: 8, marginRight: 8 }}
            >
              Apply
            </Button>
            <Button
                onClick={() => {
                  setColumnFilters(prev => ({
                    ...prev,
                    [dataIndex]: {
                      searchText: '',
                      selectedValue: null,
                    },
                  }));
                  confirm();
                }}
                size="small"
                style={{ width: 90, marginTop: 8 }}
            >
              Reset
            </Button>
          </div>
      ),
      filterIcon: filtered => (
          <span
              style={{
                color: filtered ? '#fff' : undefined,
                fontSize: filtered ? '16px' : '14px',
                backgroundColor: filtered ? '#1890ff' : undefined,
                borderRadius: '50%',
                padding: filtered ? '5px' : '0',
                border: filtered ? '2px solid #1890ff' : 'none',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
          >
        <FilterOutlined />
      </span>
      ),
      onFilter: (value, record) => onFilter(value, record),
    };
  };

  // Function to generate filter properties for columns
  // const getColumnFilterProps = (dataIndex, fieldType) => {
  //   const uniqueValues = getColumnUniqueValues(dataIndex, records, moduleData.pageType);
  //   const onFilter = (value, record) => {
  //     // Function to check value match in nested objects
  //     const checkMatch = (fieldValue, filterValue) => {
  //       if (typeof fieldValue === 'object' && fieldValue !== null) {
  //         if ('label' in fieldValue) {
  //           return fieldValue.label === filterValue;
  //         } else if ('value' in fieldValue) {
  //           if (typeof fieldValue.value === 'string') {
  //             return fieldValue.value === filterValue;
  //           } else if ('label' in fieldValue.value) {
  //             return fieldValue.value.label === filterValue;
  //           }
  //         }
  //       }
  //       return fieldValue === filterValue;
  //     };
  //
  //     // First, check top-level data fields
  //     let recordValue = record.data[dataIndex];
  //     if (recordValue && checkMatch(recordValue, value)) {
  //       return true;
  //     }
  //
  //     // Second, handle nested tableData fields for transaction pages
  //     if (moduleData.pageType === 'Transaction' && record.data.tableData) {
  //       for (const tableKey in record.data.tableData) {
  //         const tableRecords = record.data.tableData[tableKey];
  //         for (const lineItem of tableRecords) {
  //           for (const key in lineItem) {
  //             const field = lineItem[key];
  //             if (field && field.label === dataIndex && checkMatch(field, value)) {
  //               return true;
  //             }
  //           }
  //         }
  //       }
  //     }
  //
  //     return false; // Default to false if no matches found
  //   };
  //
  //   return {
  //     filterDropdown: ({ confirm }) => (
  //       <div style={{ padding: 8 }}>
  //         <Input
  //           placeholder={`Search ${dataIndex}`}
  //           value={columnFilters[dataIndex]?.searchText || ''}
  //           onChange={e => {
  //             const currValue = e.target.value;
  //             setColumnFilters(prev => ({
  //               ...prev,
  //               [dataIndex]: {
  //                 ...prev[dataIndex],
  //                 searchText: currValue,
  //               },
  //             }));
  //           }}
  //           style={{ marginBottom: 8, display: 'block' }}
  //         />
  //         <div style={{ maxHeight: 200, overflowY: 'auto' }}>
  //           <Radio.Group
  //             onChange={e => {
  //               setColumnFilters(prev => ({
  //                 ...prev,
  //                 [dataIndex]: {
  //                   ...prev[dataIndex],
  //                   selectedValue: e.target.value,
  //                 },
  //               }));
  //             }}
  //             value={columnFilters[dataIndex]?.selectedValue}
  //             style={{ display: 'flex', flexDirection: 'column' }}
  //           >
  //             {uniqueValues.map((value, index) => (
  //               <Radio key={index} value={value}>
  //                 {value}
  //               </Radio>
  //             ))}
  //           </Radio.Group>
  //         </div>
  //         <Button
  //           type="primary"
  //           onClick={() => confirm()}
  //           size="small"
  //           style={{ width: 90, marginTop: 8, marginRight: 8 }}
  //         >
  //           Apply
  //         </Button>
  //         <Button
  //           onClick={() => {
  //             setColumnFilters(prev => ({
  //               ...prev,
  //               [dataIndex]: {
  //                 searchText: '',
  //                 selectedValue: null,
  //               },
  //             }));
  //             confirm();
  //           }}
  //           size="small"
  //           style={{ width: 90, marginTop: 8 }}
  //         >
  //           Reset
  //         </Button>
  //       </div>
  //     ),
  //     filterIcon: filtered => (
  //       <span style={{
  //         color: filtered ? '#fff' : undefined,
  //         fontSize: filtered ? '16px' : '14px',
  //         backgroundColor: filtered ? '#1890ff' : undefined,
  //         borderRadius: '50%',
  //         padding: filtered ? '5px' : '0',
  //         border: filtered ? '2px solid #1890ff' : 'none',
  //         display: 'inline-flex', // Ensures the span behaves as an inline element with flex properties
  //         justifyContent: 'center', // Centers the icon horizontally in the span
  //         alignItems: 'center', // Centers the icon vertically in the span
  //       }}>
  //         <FilterOutlined />
  //       </span>
  //     ),
  //     onFilter: (value, record) => onFilter(value, record),
  //   };
  // };

  // Flatten parent data function
  const flattenParentData = (record: any) => {
    const flattenedData: any = {};
    // Determine the schema version
    const isNewSchema = true //isUsingNewSchema(record);

    if (record.data && moduleData.pageType === 'Reference') {
      for (const [key, field] of Object.entries(record.data)) {
        if (key !== 'tableData') {
          if (isNewSchema) {
            if (field && typeof field === 'object'
                  && 'label' in field
                  && 'value' in field
                  && typeof field.label === 'string')
            {
              flattenedData[key] = field.value; // key is field.id

            }
          } else {
            // Old Schema: Use the key as both label and value
            flattenedData[key] = field;
          }
        }
      }

      // Add `_id` and `originalRecord` for both schemas
      flattenedData._id = record._id;
      flattenedData.originalRecord = record;
      return flattenedData;
    }

    if (record.data && moduleData.pageType === 'Transaction' && Array.isArray(record.data)) {
      record.data.forEach((field: any) => {
        const fieldId = field.Key; // Extract the field ID (e.g., "field-1735938162252")

        if (fieldId === "transactionId" || fieldId.startsWith("table")) {
          flattenedData[fieldId] = field.Value;
          return
        }

        // Find the corresponding field type from moduleData
        const fieldType = moduleData.sections
            .flatMap((section: any) => section.fields)
            .find((f: any) => f.id === fieldId)?.type || null;

        if (!fieldType) {
          return;
        }

        // Extract the value object
        const valueObj = field.Value.find((val: any) => val.Key === 'value');

        // Handling different field types
        let fieldValue;
        switch (fieldType) {
          case 'ReferenceLinkField':
            if (Array.isArray(valueObj?.Value)) {
              const labelEntry = valueObj.Value.find((entry: any) => entry.Key === 'label');
              fieldValue = labelEntry?.Value || '';
            } else {
              fieldValue = '';
            }
            break;

          case 'PhoneNumber':
            if (Array.isArray(valueObj?.Value)) {
              const flattenedValues = valueObj.Value.flat(); // Flatten the nested arrays
              const phoneEntry = flattenedValues.find(
                  (entry: any) => entry.Key === 'Key' && entry.Value === 'phone'
              );
              fieldValue = phoneEntry ? flattenedValues.find((entry: any) => entry.Key === 'Value')?.Value || '' : '';
            } else {
              fieldValue = '';
            }
            break;

          default:
            fieldValue = valueObj?.Value || ''; // Default processing
            break;
        }

        flattenedData[fieldId] = fieldValue; // Add the processed value to the flattened object
      });

      // Add stage name to the flattened data
      if (record.stageId) {
        flattenedData.stageId = record.stageId;
      }
    }

    // Add `_id` and `originalRecord` for both schemas
    flattenedData._id = record._id;
    return flattenedData;
  };


  // const flattenLineItems = (record: any, flattenedData: any) => {
  //   const lineItems: any[] = [];
  //   const tableSections = moduleData.sections.filter(
  //       (section: any) => section.type === 'table'
  //   );
  //
  //   tableSections.forEach((tableSection: any) => {
  //     const tableId = tableSection.id;
  //
  //     // Find the table data within the record
  //     const tableValueObj = record.data?.find((item: any) => item.Key === tableId);
  //     const tableRecords = tableValueObj?.Value.find((item: any) => item.Key === 'value')?.Value || [];
  //
  //     // Check if the tableDefinition exists
  //     const tableDefinition = moduleData.tableData?.find((item: any) => item.id === tableId);
  //
  //     if (!tableDefinition || !Array.isArray(tableDefinition.columns)) {
  //       console.warn(`Table definition or columns missing for tableId: ${tableId}`);
  //       return; // Skip this table if definition or columns are invalid
  //     }
  //
  //     // Flatten each record in the table
  //     tableRecords.forEach((lineItem: any, index: number) => {
  //       const flattenedLineItem: any = { ...flattenedData };
  //
  //       tableDefinition.columns.forEach((col: any) => {
  //         const colValueObj = lineItem?.find((v: any) => v.Key === col.id).Value
  //         const colValue = colValueObj?.find((v: any) => v.Key === 'value')?.Value;
  //
  //         // Handle different field types based on column type
  //         switch (col.type) {
  //           case 'ReferenceLinkField':
  //             const labelEntry = colValue.find((entry) => entry.Key === 'label');
  //             flattenedLineItem[col.id] = labelEntry?.Value || '';
  //             break;
  //
  //           case 'Dropdown':
  //             flattenedLineItem[col.id] = colValue;
  //             break;
  //
  //           case 'PhoneNumber':
  //             flattenedLineItem[col.id] = colValue?.phone || '';
  //             break;
  //
  //           case 'OneToManyField':
  //             flattenedLineItem[col.id] = Array.isArray(colValue) ? colValue.length : 0;
  //             break;
  //
  //           case 'DateField':
  //             flattenedLineItem[col.id] = colValue
  //                 ? new Date(colValue).toLocaleDateString()
  //                 : '';
  //             break;
  //
  //           default:
  //             flattenedLineItem[col.id] =
  //                 typeof colValue === 'object' && colValue !== null
  //                     ? JSON.stringify(colValue)
  //                     : colValue || '';
  //         }
  //       });
  //
  //       // Assign a unique lineItemId
  //       flattenedLineItem.lineItemId = `${record._id}-${tableId}-${index}`;
  //       lineItems.push(flattenedLineItem);
  //     });
  //   });
  //
  //   // Return line items or fallback to the flattenedData if no items exist
  //   return lineItems.length > 0 ? lineItems : [flattenedData];
  // };


  // Function to apply both Stage Filters and Column Filters
  // const applyFilters = () => {
  //   let tempRecords;
  //
  //   if (isDetailView) {
  //     // Flatten all records into line items
  //     tempRecords = records.flatMap(record =>
  //       flattenLineItems(record, flattenParentData(record))
  //     );
  //   } else {
  //     tempRecords = [...records];
  //   }
  //
  //   // Apply Stage Filter
  //   if (stageFilter) {
  //     tempRecords = tempRecords.filter(record => record.stageId === stageFilter);
  //   }
  //
  //   // Apply Column Filters
  //   Object.keys(columnFilters).forEach(dataIndex => {
  //     const filter = columnFilters[dataIndex];
  //     if (filter && filter.selectedValue) {
  //       tempRecords = tempRecords.filter(record => {
  //         const recordValue = extractValue(record, dataIndex, moduleData.pageType);
  //         return recordValue === filter.selectedValue;
  //       });
  //     }
  //   });
  //
  //   return tempRecords;
  // };
  //
  // const displayRecords: any[] = isDetailView
  // ? applyFilters()
  // : applyFilters().map(record => flattenParentData(record));

  const displayRecords: any[] = applyFilters(
      records,
      stageFilter,
      columnFilters,
      extractValue,
      moduleData,
      isDetailView,
      (record, flattenedData) => initializePage.flattenLineItems(record, flattenedData, moduleData, renderFieldValue),
      flattenParentData
  );

  const getColumns = (isDetail: boolean) => {
    if (!moduleData) return [];

    const parentFields = moduleData.sections
        .filter((section: any) => section.type === 'section')
        .flatMap((section: any) => section.fields);

    // Define the stage column
    const stageColumn = {
      title: 'Stage',
      dataIndex: 'stageId',
      key: 'stageId',
      responsive: ['lg'],
      render: (stageId: any, record: any) => {
        return record.stageId ? stageId : 'N/A';
      },
    };

    // Generate parent columns using centralized field renderer
    const parentColumns = parentFields.map((field: any) => {
      let column: any = {
        title: field.name,
        dataIndex: field.id,
        key: field.id,
        responsive: field.responsive || ['lg'],
        render: (text: any, record: any) => {
          let fieldData;

          // Check for array-based schema (transaction pages)
          if (Array.isArray(record?.data)) {
            fieldData = record.data.find((item: any) => item.Key === field.id);
            if (fieldData) {
              const fieldValue = fieldData.Value.find((val: any) => val.Key === 'value')?.Value;
              return renderFieldValue(field.type, fieldValue ?? text);
            }
          }
          // Check for object-based schema (reference pages)
          if (record?.data && record.data[field.id]) {
            fieldData = record.data[field.id];
            return renderFieldValue(field.type, fieldData.value ?? text);
          }
          return text ?? '';
        },
      };

      if (
          ['AutoIncrementId', 'Dropdown', 'ReferenceLinkField', 'OneToManyField'].includes(field.type)
      ) {
        column = {
          ...column,
          ...getColumnFilterProps(field.id, field.type),
        };
      }

      return column;
    });

    let columns;

    if (isDetail) {
      const tableSections = moduleData.sections.filter(
          (section: any) => section.type === 'table'
      );

      const tableColumns = tableSections.flatMap((tableSection: any) => {
        const tableDefinition = moduleData.tableData?.find((table: any) => table.id === tableSection.id);
        if (!tableDefinition || !Array.isArray(tableDefinition.columns)) {
          console.warn(`Table definition or columns not found for section: ${tableSection.id}`);
          return []; // Return an empty array to avoid undefined entries
        }
        return tableDefinition.columns.map((col: any) => {
          let column: any = {
            title: col.name,
            dataIndex: col.id,
            key: col.id,
            responsive: col.responsive || ['lg'],
            render: (text: any) => renderFieldValue(col.type, text),
          };
          return column;
        });
      });

      columns = moduleData.pageType === 'Transaction'
          ? [stageColumn, ...parentColumns, ...tableColumns, getActionColumn()]
          : [...parentColumns, ...tableColumns, getActionColumn()];
    } else {
      columns = moduleData.pageType === 'Transaction'
          ? [stageColumn, ...parentColumns, getActionColumn()]
          : [...parentColumns, getActionColumn()];
    }

    // If "Fit" mode is active (isScrollEnabled is false), adjust the column widths
    if (!isScrollEnabled) {
      const totalWidth = window.innerWidth - 50; // Account for some padding
      const columnWidth = Math.floor(totalWidth / columns.length);

      // Set the width for each column
      columns = columns.map(col => ({
        ...col,
        width: columnWidth,
      }));
    }

    return columns;
  };

  // const getColumns = (isDetail: boolean) => {
  //   if (!moduleData) return [];
  //
  //   const parentFields = moduleData.sections
  //     .filter((section: any) => section.type === 'section')
  //     .flatMap((section: any) => section.fields);
  //
  //   // Define the stage column
  //   const stageColumn = {
  //     title: 'Stage',
  //     dataIndex: 'stageId',
  //     key: 'stageId',
  //     responsive: ['lg'],
  //     render: (stageId: any, record: any) => {
  //       const stage = record.stageId;
  //       return stage ? stageId : 'N/A';
  //     },
  //   };
  //
  //   // Generate parent columns
  //   const parentColumns = parentFields.map((field: any) => {
  //     let column: any = {
  //       title: field.name,
  //       dataIndex: field.id,
  //       key: field.id,
  //       responsive: field.responsive || ['lg'],
  //       render: (text: any) => {
  //         if (field.type === 'ReferenceLinkField') {
  //           return text?.label || '';
  //         } else if (field.type === 'OneToManyField') {
  //           if (Array.isArray(text)) {
  //             return text.length
  //           }
  //           return '';
  //         } else if (field.type === 'DateField') {
  //           const dateFormat = 'YYYY-MM-DD';
  //           const dateValue = text
  //               ? dayjs(text).isValid()
  //                   ? dayjs(text).format(dateFormat)
  //                   : null
  //               : null; // Handle blank or null text
  //           return dateValue;
  //         } else if (field.type === 'PhoneNumber') {
  //           const phoneNumber = parsePhoneNumberFromString(text, 'US'); // Adjust 'US' to the appropriate default country
  //           return phoneNumber ? phoneNumber.formatNational() : ''; // Format the number or return as-is
  //         }
  //         return text || '';
  //       },
  //     };
  //
  //     if (
  //       ['AutoIncrementId', 'Dropdown', 'ReferenceLinkField', 'OneToManyField'].includes(field.type)
  //     ) {
  //       column = {
  //         ...column,
  //         ...getColumnFilterProps(field.id, field.type),
  //       };
  //     }
  //
  //     return column;
  //   });
  //
  //   let columns;
  //
  //   if (isDetail) {
  //     const tableSections = moduleData.sections.filter(
  //       (section: any) => section.type === 'table'
  //     );
  //
  //     const tableColumns = tableSections.flatMap((tableSection: any) => {
  //       // const tableDefinition = moduleData.tableData[tableSection.id];
  //       const tableDefinition = moduleData.tableData?.find((table: any) => table.id === tableSection.id);
  //       if (!tableDefinition || !Array.isArray(tableDefinition.columns)) {
  //         console.warn(`Table definition or columns not found for section: ${tableSection.id}`);
  //         return []; // Return an empty array to avoid undefined entries
  //       }
  //       return tableDefinition?.columns.map((col: any) => {
  //         let column: any = {
  //           title: col.name,
  //           dataIndex: col.id,
  //           key: col.key,
  //           responsive: col.responsive || ['lg'], // Use the field's responsive property if defined, otherwise default to ['lg']
  //           render: (text: any) => {
  //             switch (col.type) {
  //               case 'ReferenceLinkField':
  //                 return text || '';
  //               case 'OneToManyField':
  //                 return text || 0;
  //               case 'PhoneNumber':
  //                 if (text) {
  //                   const phoneNumber = parsePhoneNumberFromString(text, 'US'); // Adjust 'US' as needed
  //                   return phoneNumber ? phoneNumber.formatNational() : text;
  //                 }
  //                 return '';
  //               case 'DateField':
  //                 return text || '';
  //               default:
  //                 if (typeof text === 'string' && text.startsWith('{')) {
  //                   // Attempt to parse JSON strings
  //                   try {
  //                     const parsed = JSON.parse(text);
  //                     return JSON.stringify(parsed, null, 2); // Pretty-print
  //                   } catch (e) {
  //                     return text;
  //                   }
  //                 }
  //                 return text || '';
  //             }
  //           },
  //         };
  //         return column;
  //       });
  //     });
  //
  //     columns = moduleData.pageType === 'Transaction'
  //       ? [stageColumn, ...parentColumns, ...tableColumns, getActionColumn()]
  //       : [...parentColumns, ...tableColumns, getActionColumn()];
  //   } else {
  //     columns = moduleData.pageType === 'Transaction'
  //       ? [stageColumn, ...parentColumns, getActionColumn()]
  //       : [...parentColumns, getActionColumn()];
  //   }
  //
  //   // If "Fit" mode is active (isScrollEnabled is false), adjust the column widths
  //   if (!isScrollEnabled) {
  //     const totalWidth = window.innerWidth - 50; // Account for some padding
  //     const columnWidth = Math.floor(totalWidth / columns.length);
  //
  //     // Set the width for each column
  //     columns = columns.map(col => ({
  //       ...col,
  //       width: columnWidth,
  //     }));
  //   }
  //
  //   return columns;
  // };

  const getActionColumn = () => ({
    title: 'Actions',
    key: 'actions',
    fixed: 'right',
    responsive: ['lg'],
    render: (_: any, record: any) => (
      <>
        <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
        />
        <Button
          icon={<DeleteOutlined />}
          onClick={() => handleDelete(record)}
          danger
          style={{ marginLeft: 8 }}
        />
      </>
    ),
  });

  const handleDelete = (record: any) => {
    initializePage.deleteTransactionData(record._id, moduleData.pageType, setRecords);
  };

  // const handleDelete = (record: any) => {
  //   Modal.confirm({
  //     title: 'Are you sure you want to delete this record?',
  //     content: 'This action cannot be undone.',
  //     okText: 'Yes, delete it',
  //     okType: 'danger',
  //     cancelText: 'No',
  //     onOk: async () => {
  //       try {
  //         await dataAll.dataPostUtility.deleteData(moduleData.pageType,record._id)
  //         setRecords(prev => prev.filter(r => r._id !== record._id));
  //         message.success('Record deleted successfully.');
  //       } catch (error) {
  //         message.error('Failed to delete the record.');
  //       }
  //     },
  //   });
  // };

  const handleEdit = (record: any) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  if (!moduleData) return <p>Loading moduleData...</p>;
  if (error) {
    const err = error;
    setError(null) // clear the error so it doesn't propagate to the next tab
    return <p>{err}</p>
  }

  // CSV Export Functions
  function convertToCSV(objArray, columns) {
    let str = columns.map(column => `"${column.title}"`).join(',') + '\r\n';

    objArray.forEach(obj => {
      let line = columns.map(column => {
        let value = obj[column.title];
        if (Array.isArray(value)) {
          return value.length
        }
        else if (value && typeof value === 'object') {
          return `"${value.value ?? value.label ?? ''}"`;
        }
        return `"${value !== undefined ? value : ''}"`;
      }).join(',');
      str += line + '\r\n';
    });

    return str;
  }

  function resolveComplexFields(records: any[]) {
    return records.map(record => {
      const resolvedRecord = { ...record };
      Object.keys(resolvedRecord).forEach(key => {
        if (typeof resolvedRecord[key] === 'object' && resolvedRecord[key] !== null) {
          if (resolvedRecord[key].hasOwnProperty('label')) {
            resolvedRecord[key] = resolvedRecord[key].label;
          } else if (Array.isArray(resolvedRecord[key])) {
            resolvedRecord[key] = resolvedRecord[key]
              .map((item: any) => item.label)
              .join(', ');
          } else {
            resolvedRecord[key] = JSON.stringify(resolvedRecord[key]);
          }
        }
      });
      return resolvedRecord;
    });
  }



  function downloadCSV(records: any[], columns: any[], filename = 'output.csv') {
    const processedRecords = records.map((record) => {
      const flattenedData = {};
      // Extracting each field from the data object
      for (const key in record.data) {
        if (record.data.hasOwnProperty(key)) {
          flattenedData[record.data[key].label] = record.data[key].value ?? '';
        }
      }
      // Include additional fields if necessary
      // flattenedData['tenantId'] = record.tenantId ?? '';
      // flattenedData['companyId'] = record.companyId ?? '';
      // flattenedData['moduleId'] = record.moduleId ?? '';
      // flattenedData['stageId'] = record.stageId ?? '';
      // flattenedData['createdAt'] = record.createdAt ?? '';
      // flattenedData['updatedAt'] = record.updatedAt ?? '';

      return flattenedData;
    });
    const csvData = convertToCSV(processedRecords, columns);
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const exportTemplate = () => {
    try {
      const headers = moduleData.sections
          .filter((section) => section.type === "section")
          .flatMap((section) => section.fields)
          .filter(
              (field) =>
                  !["OneToManyField", "AggregateField", "CalculationField", "AutoIncrementId", "ReferenceLinkField"].includes(
                      field.type
                  )
          )
          .map((field) => field.name);

      // console.log("Extracted Headers:", headers);

      const worksheetData = [headers];
      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

      const oneToManyFields = moduleData.sections
          .flatMap((section) => section.fields)
          .filter((field) => field.type === "OneToManyField");

      oneToManyFields.forEach((field) => {
        // const subFieldHeaders = field.fieldOptions?.subFields.map((subField) => subField.name) || [];
        const subFieldHeaders = field.fieldOptions?.sections
            ?.flatMap((section) => section.fields.map((subField) => subField.name)) || [];
        if (commonIdentifier) {
          subFieldHeaders.unshift(commonIdentifier); // Add the common identifier to child sheets
        }
        const oneToManyWorksheetData = [subFieldHeaders];
        const oneToManyWorksheet = XLSX.utils.aoa_to_sheet(oneToManyWorksheetData);
        XLSX.utils.book_append_sheet(workbook, oneToManyWorksheet, field.name);
      });

      const fileName = `${moduleData.moduleName}_Import.xlsx`;
      XLSX.writeFile(workbook, fileName);
    } catch (error) {
      console.error("Error exporting template:", error);
    }
  };

  const handleDownloadTemplate = async () => {
    if (!moduleData) return;

    const oneToManyFields = moduleData.sections
        .filter((section) => section.type === "section")
        .flatMap((section) => section.fields)
        .filter((field) => field.type === "OneToManyField");

    if (oneToManyFields.length > 0) {
      // Show modal if one-to-many fields exist
      setIsDownloadModalVisible(true);
    } else {
      // Directly export template if no one-to-many fields exist
      exportTemplate();
    }
  };


  const renderDownloadModal = () => {
    const textFields = moduleData.sections
        .flatMap((section) => section.fields)
        .filter((field) => field.type === "TextField");

    return (
        <Modal
            title="Select Common Identifier"
            open={isDownloadModalVisible}
            onCancel={() => setIsDownloadModalVisible(false)}
            onOk={() => {
              if (!commonIdentifier) {
                message.error("Please select a common identifier.");
                return;
              }
              exportTemplate(); // Call export function after selecting identifier and before moving modal
              setIsDownloadModalVisible(false);

            }}
            okText="Download"
        >
          <p>Select a common identifier to link related data:</p>
          <Select
              placeholder="Select Parent Identifier"
              style={{ width: "100%" }}
              onChange={(value) => setCommonIdentifier(value)}
          >
            {textFields.map((field) => (
                <Select.Option key={field.id} value={field.name}>
                  {field.name}
                </Select.Option>
            ))}
          </Select>
        </Modal>
    );
  };


  const showImportModal = () => {
    setIsImportModalVisible(true);
  };

  const handleImportCancel = () => {
    setIsImportModalVisible(false);
  };

  const handleImportSuccess = (importResult) => {
    message.success(`Successfully imported ${importResult.insertedCount} records.`);
    setIsImportModalVisible(false);
    // Re-initialize page
    initializePage.initializePage(moduleId, setModuleData, setRecords);
  };

  const handleNewButton = () => {
    if (moduleData.pageType === 'Transaction' && (!moduleData.stages || moduleData.stages.length === 0)) {
      message.warning('You must configure stages before transaction pages are visible.');
      return;
    }
    setSelectedRecord(null);
    setIsModalVisible(true);
  }

  return (
    <div>
      <h1>{moduleData?.moduleName || 'Loading...'}</h1>
      <div style={{ marginBottom: 16 }}>
        <Button onClick={() => setIsScrollEnabled(!isScrollEnabled)}>
          {isScrollEnabled ? '  Fit  ' : 'Expand'}
        </Button>
        <Button onClick={toggleView}>
          View: {isDetailView ? 'Detail' : 'Header'}
        </Button>
        <Button
          type="primary"
          onClick={handleNewButton}
          style={{ marginLeft: 8 }}
        >
          + New
        </Button>
        <Button
          onClick={() =>
            downloadCSV(displayRecords, getColumns(isDetailView), 'exported_data.csv')
          }
          type="primary"
          style={{ marginLeft: 8 }}
          icon={<ExportOutlined />}
        >
          Export
        </Button>

        <Button
          type="primary"
          onClick={resetAllFilters} // Using the reset function
          style={{ marginLeft: 8 }}
        >
          Reset All Filters
        </Button>
        {moduleData.pageType=== 'Reference' && (
          <>
          <Button
            onClick={handleDownloadTemplate}
            style={{ marginLeft: 8 }}
          >
            Download Import Template
          </Button>
          {renderDownloadModal()}
          <Button
            onClick={showImportModal}
            style={{ marginLeft: 8 }}
          >
            Import Data
          </Button>
          </>
        )}
      </div>

      {moduleData.pageType === 'Transaction' && (
        <div style={{ marginBottom: 16 }}>
          <Button
            onClick={() => filterRecordsByStage(null)}
            type={!stageFilter ? 'primary' : 'default'}
          >
            All Stages
          </Button>
          {moduleData.stages?.map((stage: any) => (
            <Button
              key={stage.id}
              onClick={() => filterRecordsByStage(stage.name)}
              type={stageFilter === stage.name ? 'primary' : 'default'}
            >
              {stage.name}
            </Button>
          ))}
        </div>
      )}
      <Table
        columns={getColumns(isDetailView)}
        dataSource={displayRecords}
        rowKey={isDetailView ? 'lineItemId' : '_id'}
        scroll={isScrollEnabled ? { x: 'max-content' } : {}}
        loading={loading} // Ant Design's loading prop
        pagination={{
          // pageSize: 10, // Default number of records per page
          showSizeChanger: true, // Always show the page size changer
          pageSizeOptions: ['10', '20', '50', '100'], // Options for number of records per page
          showTotal: (total) => `${total} records`,
        }}
      />

      {isModalVisible && (
        <>
          {moduleData.pageType === 'Reference' && (
            <ReferencePageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
          {moduleData.pageType === 'Transaction' && (
            <TransactionPageRender
              moduleData={moduleData}
              existingRecord={selectedRecord}
              records={records}
              setRecords={setRecords}
              closeModal={() => setIsModalVisible(false)}
            />
          )}
        </>
      )}
      {isImportModalVisible && (
        <Modal
          title="Import Data"
          open={isImportModalVisible}
          onCancel={handleImportCancel}
          footer={null}
        >
          <ImportDataModal
            moduleId={moduleId}
            tenantId={moduleData.tenantId}
            companyId={moduleData.companyId}
            // moduleData={moduleData} // Pass moduleData to ImportDataModal
            onClose={handleImportCancel}
            onImportSuccess={handleImportSuccess}
          />
        </Modal>
      )}
    </div>
  );
};

export default BasePageRender;
