import React, { useState } from 'react';
// import PhoneInput from 'react-phone-input-2';
// import 'react-phone-input-2/lib/style.css';
import { Select } from 'antd';

const { Option } = Select;

interface PhoneNumberProps {
  id?: string;
  name?: string;
  value?: string;
  fieldOptions?: any;
  setFieldOptions?: any;
  onChange?: (value: string) => void;
}

const PhoneNumberConfig: React.FC<PhoneNumberProps> = ({ id, name, value, onChange }) => {
  const [format, setFormat] = useState<'US' | 'International'>('US');

  const handleFormatChange = (newFormat: 'US' | 'International') => {
    setFormat(newFormat);
  };

  return (
    <div>
      <Select
        value={format}
        onChange={handleFormatChange}
        style={{ width: '100%', marginBottom: '10px' }}
      >
        <Option value="US">US Format</Option>
        {/* <Option value="International">International Format</Option> */}
      </Select>
      {/*<PhoneInput*/}
      {/*  country={format === 'US' ? 'us' : ''}*/}
      {/*  inputProps={{*/}
      {/*    id,*/}
      {/*    name,*/}
      {/*  }}*/}
      {/*  value={value}*/}
      {/*  onChange={(value) => onChange && onChange(value)}*/}
      {/*  inputStyle={{ width: '100%' }}*/}
      {/*/>*/}
    </div>
  );
};

export default PhoneNumberConfig;