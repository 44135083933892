import React, { useState, useEffect } from 'react';
import {Select, Input, Button, Divider, Radio, Table} from 'antd';
import axios from 'axios';
import { useTenant } from '../../../contexts/TenantContext';
import dataAll from "../../../utils/dataAll";

const { Option } = Select;

interface AggregateRule {
  moduleId: string;
  aggregateField: {
    aggregateFieldId: string;
    aggregateFieldLabel: string;
  }
  stage: string;
  operation: 'Increase' | 'Decrease';
  stageMatchType: string;
  matchField: {
    sourceFieldId: string;
    sourceFieldLabel: string;
  };
  condition?: {
    conditionField: {
        conditionFieldId: string;
        conditionFieldLabel: string;
    };
    operator: string;
    value?: any;
  };
}

interface AggregateFieldProps {
  id?: string;
  name?: string;
  value?: string;
  fieldOptions?: any;
  setFieldOptions?: (options: any) => void;
  onChange?: (fieldOptionKey: string, value: any) => void;
}

const AggregateField: React.FC<AggregateFieldProps> = ({
  id,
  name,
  fieldOptions = {},
  setFieldOptions,
  onChange,
}) => {
  const [aggregateRules, setAggregateRules] = useState<AggregateRule[]>(fieldOptions.aggregationRules || []);
  const [selectedModule, setSelectedModule] = useState<string>('');
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedStage, setSelectedStage] = useState<string>('');
  const [selectedOperation, setSelectedOperation] = useState<'Increase' | 'Decrease'>('Increase');
  const [stageMatchType, setStageMatchType] = useState<'equals' | 'from-stage-onward'>('equals');
  const [selectedConditionField, setSelectedConditionField] = useState<string>('');
  const [selectedConditionOperator, setSelectedConditionOperator] = useState<string>('');
  const [conditionValue, setConditionValue] = useState<any>('');
  const [modules, setModules] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [stages, setStages] = useState<any[]>([]);
  const [sourceField, setSourceField] = useState<string>('');  // New state for source field
  // const [targetField, setTargetField] = useState<string>('');  // New state for target field
  const [sourceFieldId, sourceFieldLabel] = sourceField.split('|');  // Extract both parts


  const { tenantId, companyId } = useTenant();
  const pageType = 'Transaction';

  useEffect(() => {
    const getModules = async () => {
      try{
        const moduleData = await dataAll.dataExtractUtility.getAllModulesByType(tenantId, companyId, 'transaction')
        setModules(moduleData);
        } catch (error) {
        console.log('failed to fetch modules', error);
      }
    };

    getModules();

    // const fetchModules = async () => {
    //   try {
    //     const response = await axios.get(
    //       `${API_URL}/modules/transaction-modules?tenantId=${tenantId}&companyId=${companyId}&pageType=${pageType}`
    //     );
    //     setModules(response.data);
    //   } catch (error) {
    //     console.error('Failed to fetch page modules:', error);
    //   }
    // };
    // fetchModules();
  }, [tenantId, companyId, pageType]);

  useEffect(() => {
    const fetchFieldsAndStages = async () => {
      try {
        const moduleFields: any  = await dataAll.dataExtractUtility.getModuleDataFields(selectedModule);
        const moduleStages: any = await dataAll.dataExtractUtility.getModuleDataStages(selectedModule);
        setFields(moduleFields);
        setStages(moduleStages);
      } catch (error) {
        console.log('failed to fetch modules', error);
      }
    };


    if (selectedModule) {
      fetchFieldsAndStages();
    }

      // const fetchFieldsAndStages = async () => {
      //   try {
      //     const fieldsResponse = await axios.get(`${API_URL}/modules/${selectedModule}/fields`);
      //     setFields(fieldsResponse.data);
      //
      //     const stagesResponse = await axios.get(
      //       `${API_URL}/modules/${selectedModule}/stages`
      //     );
      //     setStages(stagesResponse.data);
      //   } catch (error) {
      //     console.error('Failed to fetch fields or stages:', error);
      //   }
      // };
      // fetchFieldsAndStages();
  }, [selectedModule]);

  useEffect(() => {
    if (fieldOptions && fieldOptions.aggregationRules) {
      setAggregateRules(fieldOptions.aggregationRules);
    }
  }, [fieldOptions]);

  const addAggregateRule = () => {
    const newRule: AggregateRule = {
      moduleId: selectedModule,
      aggregateField: {
        aggregateFieldId: selectedField.split('|')[0],
        aggregateFieldLabel: selectedField.split('|')[1],
      },
      stage: selectedStage,
      operation: selectedOperation,
      stageMatchType,
      matchField: {
        sourceFieldId: sourceFieldId,
        sourceFieldLabel: sourceFieldLabel,
      },
      condition: selectedConditionField
      ? {
          conditionField: {
            conditionFieldId: selectedConditionField.split('|')[0],
            conditionFieldLabel: selectedConditionField.split('|')[1],
          },
          operator: selectedConditionOperator,
          value: conditionValue,
        }
      : undefined,
    };

    const updatedRules = [...aggregateRules, newRule];
    setAggregateRules(updatedRules);

    const updatedFieldOptions = {
      ...fieldOptions,
      aggregationRules: updatedRules,
    };
    setFieldOptions && setFieldOptions(updatedFieldOptions);
    onChange && onChange('fieldOptions', updatedFieldOptions);

    resetSelections();
  };

  const deleteAggregateRule = (index: number) => {
    const updatedRules = aggregateRules.filter((_, i) => i !== index);
    setAggregateRules(updatedRules);

    const updatedFieldOptions = {
      ...fieldOptions,
      aggregationRules: updatedRules,
    };
    setFieldOptions && setFieldOptions(updatedFieldOptions);
    onChange && onChange('fieldOptions', updatedFieldOptions);
  };

  const resetSelections = () => {
    setSelectedModule('');
    setSelectedField('');
    setSelectedStage('');
    setSelectedOperation('Increase');
    setSelectedConditionField('');
    setSelectedConditionOperator('');
    setConditionValue('');
    setSourceField('');
    // setTargetField('');
    setStageMatchType('equals');
  };

  const columns = [
    {
      title: 'Module',
      dataIndex: 'moduleId',
      render: (value) => modules.find((mod) => mod._id === value)?.moduleName || value,
    },
    {
      title: 'Field to Aggregate',
      dataIndex: 'aggregateField',
      render: (field) => field?.aggregateFieldLabel || 'Not set',
    },
    {
      title: 'Match Field',
      dataIndex: 'matchField',
      render: (field) => field?.sourceFieldLabel || 'Not set',
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
    },
    {
      title: 'Stage Config',
      dataIndex: 'stageMatchType',
    },
    {
      title: 'Increase/Decrease',
      dataIndex: 'operation',
    },
    {
      title: 'Condition',
      dataIndex: 'condition',
      render: (condition) => condition ? `${condition.conditionField.conditionFieldLabel} ${condition.operator} ${condition.value || ''}` : 'None',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (_, record, index) => (
          <Button type="link" danger onClick={() => deleteAggregateRule(index)}>
            Delete
          </Button>
      ),
    },
  ];

  return (
      <div>
        <h3>Aggregate Field Builder</h3>
        <Table columns={columns} dataSource={aggregateRules} rowKey={(record, index) => index} />
        <Divider />
        <Button type="primary" onClick={addAggregateRule}>
          Add New Rule
        </Button>
      </div>
  );


  // return (
  //   <div>
  //     <h3>Aggregate Field Builder</h3>
  //     {/* <label>Aggregate Field Name (irrelevant):</label>
  //     <Input
  //       placeholder="Enter Aggregate Field Name"
  //       value={name || ''}
  //       onChange={(e) => onChange && onChange('name', e.target.value)}
  //       style={{ marginBottom: '10px' }}
  //     /> */}
  //   <label>Select Module</label>
  //     <Select
  //       placeholder="Select Module"
  //       value={selectedModule}
  //       onChange={(value) => setSelectedModule(value)}
  //       style={{ width: '100%', marginBottom: '8px' }}
  //     >
  //       {modules.map((module) => (
  //         <Option key={module._id} value={module._id}>
  //           {module.moduleName}
  //         </Option>
  //       ))}
  //     </Select>
  //     <label>Select Field to Aggregate</label>
  //     <Select
  //       placeholder="Select Field to Aggregate"
  //       value={selectedField}
  //       onChange={(value) => setSelectedField(value)}
  //       style={{ width: '100%', marginBottom: '8px' }}
  //       disabled={!selectedModule}
  //       >
  //       {fields.map((field) => (
  //           <Option key={field.id} value={`${field.id}|${field.name}`}>
  //           {field.name}
  //           </Option>
  //       ))}
  //       </Select>
  //
  //     {/* Select for Source Field */}
  //     <label>Select Source Field</label>
  //   <Select
  //   placeholder="Select Source Field"
  //   value={sourceField}
  //   onChange={(value) => setSourceField(value)
  //   }
  //   style={{ width: '100%', marginBottom: '8px' }}
  //   disabled={!selectedModule}
  //   >
  //   {fields.map((field) => (
  //       <Option key={field.id} value={field.id + '|' + field.name}>
  //       {field.name} ({field.id})
  //       </Option>
  //   ))}
  //   </Select>
  //
  //
  //     <label>Select Stage</label>
  //     <Select
  //       placeholder="Select Stage"
  //       value={selectedStage}
  //       onChange={(value) => setSelectedStage(value)}
  //       style={{ width: '100%', marginBottom: '8px' }}
  //       disabled={!selectedField}
  //     >
  //       {stages.map((stage) => (
  //         <Option key={stage.id} value={stage.name}>
  //           {stage.name}
  //         </Option>
  //       ))}
  //     </Select>
  //
  //     <Radio.Group
  //       onChange={(e) => setSelectedOperation(e.target.value)}
  //       value={selectedOperation}
  //       style={{ marginBottom: '8px' }}
  //     >
  //       <Radio value="Increase">Increase</Radio>
  //       <Radio value="Decrease">Decrease</Radio>
  //     </Radio.Group>
  //
  //     <Select
  //       placeholder="Stage Match Type"
  //       value={stageMatchType}
  //       onChange={(value) => setStageMatchType(value)}
  //       style={{ width: '100%', marginBottom: '8px' }}
  //     >
  //       <Option value="equals">Equals</Option>
  //       <Option value="from-stage-onward">From Stage Onward</Option>
  //     </Select>
  //
  //     <Divider />
  //
  //     <h4>Condition (Optional)</h4>
  //
  //     <Select
  //       placeholder="Condition Field"
  //       value={selectedConditionField}
  //       onChange={(value) => setSelectedConditionField(value)}
  //       style={{ width: '100%', marginBottom: '8px' }}
  //       disabled={!selectedModule}
  //       >
  //       {fields.map((field) => (
  //           <Option key={field.id} value={`${field.id}|${field.name}`}>
  //           {field.name}
  //           </Option>
  //       ))}
  //       </Select>
  //
  //     <Select
  //       placeholder="Condition Operator"
  //       value={selectedConditionOperator}
  //       onChange={(value) => setSelectedConditionOperator(value)}
  //       style={{ width: '100%', marginBottom: '8px' }}
  //       disabled={!selectedConditionField}
  //     >
  //       <Option value="next-7-days">Next 7 Days</Option>
  //       <Option value="next-30-days">Next 30 Days</Option>
  //       <Option value="equals">Equals</Option>
  //       <Option value="greater-than">Greater Than</Option>
  //       <Option value="less-than">Less Than</Option>
  //     </Select>
  //
  //     {selectedConditionOperator === 'equals' && (
  //       <Input
  //         placeholder="Enter Value"
  //         value={conditionValue}
  //         onChange={(e) => setConditionValue(e.target.value)}
  //         style={{ width: '100%', marginBottom: '8px' }}
  //       />
  //     )}
  //
  //     <Button
  //       type="primary"
  //       onClick={addAggregateRule}
  //       disabled={!selectedModule || !selectedField || !selectedStage || !sourceField}
  //     >
  //       Add Rule
  //     </Button>
  //
  //     <Divider />
  //
  //     <div>
  //       <h4>Current Rules:</h4>
  //       {aggregateRules.map((rule, index) => (
  //           <div key={index} style={{ marginBottom: '8px' }}>
  //           <strong>Module:</strong> {modules.find((mod) => mod._id === rule.moduleId)?.moduleName || rule.moduleId} <br />
  //           <strong>Field:</strong> {rule.aggregateField.aggregateFieldLabel} <br />
  //           <strong>Stage:</strong> {rule.stage} <br />
  //           <strong>Operation:</strong> {rule.operation} <br />
  //           <strong>Source Field:</strong> {rule.matchField ? rule.matchField.sourceFieldLabel : 'Not set'} <br />
  //           {rule.condition && (
  //               <>
  //               <strong>Condition:</strong> {rule.condition.conditionField.conditionFieldLabel} {rule.condition.operator}{' '}
  //               {rule.condition.value || ''} <br />
  //               </>
  //           )}
  //           <Button type="link" danger onClick={() => deleteAggregateRule(index)}>
  //               Delete
  //           </Button>
  //           <Divider dashed />
  //           </div>
  //       ))}
  //       </div>
  //   </div>
  // );
};

export default AggregateField;
