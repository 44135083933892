import React from 'react';
import { Select, Form } from 'antd';

const { Option } = Select;

interface DropdownRenderProps {
  fieldId: string;
  fieldname: string;
  fieldOptions: { options: string[] };
  value?: string;
  onChange: (value: string) => void;
  required?: boolean;
  renderType?: 'table' | 'section';
}

const DropdownRender: React.FC<DropdownRenderProps> = ({
                                                         fieldId,
                                                         fieldname,
                                                         fieldOptions,
                                                         value,
                                                         onChange,
                                                         required = false,
                                                         renderType = 'section',
                                                       }) => {
  // ----- For table usage: fully controlled by your parent’s state -----
  if (renderType === 'table') {
    return (
        <Select
            placeholder={`Select ${fieldname}`}
            style={{ width: '100%' }}
            showSearch
            value={value}         // parent state controls this
            onChange={onChange}   // update parent state
        >
          {fieldOptions?.options?.map((opt) => (
              <Option key={opt} value={opt}>
                {opt}
              </Option>
          ))}
        </Select>
    );
  }

  // ----- For normal (form) usage: let AntD Form manage the value -----
  return (
      <Form.Item
          label={fieldname}
          initialValue={value}
          name={fieldname}  // Must match how you set form.setFieldsValue({ [fieldId]: '...' })
          rules={[
            {
              required,
              message: `Please select a value for ${fieldname}`,
            },
          ]}
      >
        <Select
            placeholder={`Select ${fieldname}`}
            showSearch
            onChange={(selectedValue) => {
              // If you need an extra callback, you can do:
              onChange(selectedValue);
            }}
        >
          {fieldOptions?.options?.map((opt) => (
              <Option key={opt} value={opt}>
                {opt}
              </Option>
          ))}
        </Select>
      </Form.Item>
  );
};

export default DropdownRender;