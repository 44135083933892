import React, { useEffect, useState } from 'react';
import { List, Typography } from 'antd'; // Import Ant Design components
import { useNavigate } from 'react-router-dom';
import dataAll from "../utils/dataAll";
import {useTenant} from "../contexts/TenantContext";
const { Text } = Typography;


const SideMenuRender = () => {
  const [publishedModules, setPublishedModules] = useState([]);
  const navigate = useNavigate()
  const { tenantId, companyId } = useTenant()

  useEffect(() => {
    const fetchPublishedModules = async () => {
      if (!tenantId || !companyId) {
        return;
      }

      try {
        // const publishedModules = await dataAll.dataExtractUtility.getPublishedModules(tenantId, companyId);
        // console.log('publishedModules', publishedModules);
        const response = await fetch(`${API_URL}/modules/published?tenantId=${tenantId}&companyId=${companyId}`);
        const publishedModuleData = await response.json();
        const sortedData = publishedModuleData.sort((a, b) => a.moduleName.localeCompare(b.moduleName, undefined, { sensitivity: 'base' }));
        if (Array.isArray(sortedData)) {
          setPublishedModules(sortedData); // Ensure it only sets if data is an array
        } else {
          setPublishedModules([]); // Handle unexpected data format
        }
      } catch (error) {
        console.error('Error fetching published modules:', error);
        setPublishedModules([]); // Fallback to an empty array on error
      }
    };

    fetchPublishedModules()
  }, [tenantId, companyId]); // Re-fetch whenever tenantId or companyId changes

  // Handle clicking a module to navigate to its URL based on type
  const handleModuleClick = (module) => {
      navigate(`/module/${module._id}/${module.pageType.toLowerCase()}`);  // Pass the moduleType in the URL
      };

  return (
      <div>
        <h2 style={{ color: 'white', paddingLeft: '16px' }}>Pages</h2>
        {publishedModules.length === 0 ? (
          <p style={{ color: 'white', paddingLeft: '16px' }}>No published modules available.</p>
        ) : (
          <List
            dataSource={publishedModules}
            renderItem={(module) => (
              <List.Item
                style={{ paddingLeft: '16px', cursor: 'pointer' }}
                onClick={() => handleModuleClick(module)}
              >
                <Text style={{ color: 'white' }}>{module.moduleName}</Text>
              </List.Item>
            )}
          />
        )}
      </div>
    );
  };

export default SideMenuRender;
