import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTenant } from '../contexts/TenantContext';
import { useUser } from '../contexts/UserContext';

const QBHome: React.FC = () => {
  // const [tenants, setTenants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { tenantId, setTenantId, companyId, setCompanyId } = useTenant();  // Use tenant context
  const { user, loading: userLoading } = useUser(); // Access the logged-in user


  // Fetch user data if defaultTenantId is not present in UserContext
  const fetchUserData = async (email: string): Promise<number> => {
    try {
      const response = await axios.get(`${API_URL}/users/${email}`);
      const fetchedUser = response.data;
      if (!fetchedUser || !fetchedUser.defaultTenantId) {
        throw new Error('Default Tenant ID not found for the user.');
      }

      return fetchedUser.defaultTenantId;
    } catch (err: any) {
      console.error('Error fetching user data:', err);
      throw new Error(err.response?.data?.message || 'Failed to fetch user data.');
    }
  };


  // Fetch tenant and company data based on tenantId
  const fetchTenantAndCompany = async (tenantId: number) => {
    try {
      // Fetch tenant details
      const tenantResponse = await axios.get(`${API_URL}/tenants/${tenantId}`);

      const fetchedTenantId = tenantResponse.data.id;
      setTenantId(fetchedTenantId);
      localStorage.setItem('tenantId', fetchedTenantId)

      // Fetch companies associated with the tenant
      // const companies = await dataAll.dataExtractUtility.getCompanies(tenantId);
      const companiesResponse = await axios.get(`${API_URL}/company/bytenant/${fetchedTenantId}`);
      const companies = companiesResponse.data;

      if (companies && companies.length > 0) {
        setCompanyId(companies[0].id); // Select the first company as default
        const newcompanyId = companies[0].id;
        localStorage.setItem('companyId', newcompanyId)
      } else {
        throw new Error('No companies found for the tenant.');
      }

      setLoading(false);
    } catch (err: any) {
      console.error('Error fetching tenant and company data:', err);
      setError(err.message || 'Failed to fetch tenant and company data.');
      setLoading(false);
    }
  };

  // Initialize tenant and company data
  const initializeTenantAndCompany = async () => {
    try {
      let tenantId: number | undefined = user?.defaultTenantId;

      // If defaultTenantId is not present in UserContext, fetch it via API
      if (!tenantId) {
        if (!user?.email) {
          // TODO: IDK why exiting early still works, does this logic retry?
          setLoading(false);
          return;
        }
        tenantId = await fetchUserData(user?.email);
      }
      // Proceed to fetch tenant and company data
      await fetchTenantAndCompany(tenantId);
    } catch (err: any) {
      console.error('Initialization error:', err);
      setError(err.message || 'Failed to initialize tenant and company data.');
      setLoading(false);
    }
  };



  useEffect(() => {
    // Wait until UserContext loading is complete
    if (!userLoading) {
      initializeTenantAndCompany();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoading]); // Depend on userLoading to ensure user data is ready

  if (loading || userLoading) {
    return <div>Loading tenant and company information...</div>;
  }

  return (
      <div style={{width: '40vw'}}>
        <h1>
          Welcome to QuickBuild!
        </h1>
        <p>We'll add something really cool here eventually. </p>
        <p>If you just signed up, you now have access to a handful of default pages: Items, Customers, Vendors, Sales,
          Purchases.</p>
        <p>But that's just the beginning. </p>
        <br></br>
        <p>The magic of Quickbuild is customizing the experience to YOU. Click the Builder Link to get started making it
          your own:</p>
        <ul>
          <li>Create new reference pages to store data in a central place.</li>
          <li>Create new fields - text, dropdown, phone numbers, dates, etc.</li>
          <li>Configure transactions and process flows to match your business.</li>
          <p> Quote {'-->'} Order {'-->'} Invoice, or Order {'-->'} Build {'-->'} Ship {'-->'} Invoice. Or anything else!</p>
        </ul>
        <p>Some builder functions (like calculations and aggregates) are not currently available. These will be available in future releases.
          Don't hesitate to reach out if you need some workarounds. </p>
        <p>Please submit any bugs or feature requests <a href='https://forms.gle/2kYKBirrNxKHTNZs6' target="_blank"
                                                         rel="noopener noreferrer">here.</a></p>
        <br></br>
        <br></br>
        <p>Much love and appreciation,</p>
        <p>Nathan Cunningham, QuickBuild</p>
        <p>PS. Need support? Book time <a href='https://tidycal.com/ncunninghamservices/quickbuild-support'
                                          target="_blank" rel="noopener noreferrer">here</a></p>
      </div>
  );
};

export default QBHome;
