import dataExtractUtility from "../utils/dataExtract"
import dataCleanUtility from "utils/dataClean";

const dataPrepUtility = {
    /**
     * Prep Reference Data for Posting
     * Converts user-friendly names and values into robust key-value objects.
     */
    async prepReferenceData(data: any[], moduleData: any) {
        const preppedData = data.map((record: any) => {
            const preppedRecord: any = { data: {} };
            moduleData.sections.forEach((section: any) => {
                section.fields.forEach((field: any) => {
                    const fieldKey = field.id;
                    const fieldLabel = field.name;
                    const fieldValue = record[fieldLabel] || null;

                    preppedRecord.data[fieldKey] = {
                        label: fieldLabel,
                        value: fieldValue,
                    };
                });
            });
            return preppedRecord;
        });

        return preppedData;
    },

    /**
     * Prep Transaction Data for Posting
     * Converts transaction data into the robust key-value structure.
     */
    async prepTransactionData(data: any[], moduleData: any) {
        const preppedData = data.map((record: any) => {
            const preppedRecord: any = { data: {}, tableData: {} };
            moduleData.sections.forEach((section: any) => {
                if (section.type === "table") {
                    // Process table data
                    const tableKey = section.id;
                    const tableRows = record[section.title] || [];
                    preppedRecord.tableData[tableKey] = tableRows.map((row: any) => {
                        const cleanedRow: any = {};
                        section.fields.forEach((field: any) => {
                            const fieldKey = field.id;
                            const fieldLabel = field.name;
                            const fieldValue = row[fieldLabel] || null;

                            cleanedRow[fieldKey] = {
                                label: fieldLabel,
                                value: fieldValue,
                            };
                        });
                        return cleanedRow;
                    });
                } else {
                    // Process standard fields
                    section.fields.forEach((field: any) => {
                        const fieldKey = field.id;
                        const fieldLabel = field.name;
                        const fieldValue = record[fieldLabel] || null;

                        preppedRecord.data[fieldKey] = {
                            label: fieldLabel,
                            value: fieldValue,
                        };
                    });
                }
            });
            return preppedRecord;
        });

        return preppedData;
    },

    /**
     * Prep Module Data for Posting
     * Converts module data into the robust key-value structure.
     */
    async prepModuleData(data: any) {
        const preppedData: any = {
            _id: data._id || null,
            tenantId: data.tenantId || null,
            companyId: data.companyId || null,
            sections: [],
        };

        data.sections.forEach((section: any) => {
            const preppedSection: any = {
                id: section.id || null,
                title: section.title || null,
                type: section.type || null,
                fields: [],
            };

            section.fields.forEach((field: any) => {
                const preppedField: any = {
                    id: field.id || null,
                    name: field.name || null,
                    type: field.type || null,
                    fieldOptions: field.fieldOptions || {},
                };

                preppedSection.fields.push(preppedField);
            });

            preppedData.sections.push(preppedSection);
        });

        return preppedData;
    },


};

export default dataPrepUtility;