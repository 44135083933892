import { init } from "supertokens-auth-react";
import Session from "supertokens-auth-react/recipe/session";
import ThirdParty, {Google} from "supertokens-auth-react/recipe/thirdparty";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import {getUser} from "./contexts/UserContext";
import axios from "axios";

export function initSuperTokens() {
    init({
        appInfo: {
            // learn more about this on https://supertokens.com/docs/thirdpartyemailpassword/appinfo
            appName: "QuickBuild",
            apiDomain: process.env.REACT_APP_ST_BACKEND_URL,
            websiteDomain: process.env.REACT_APP_ST_WEBSITE_DOMAIN,
            apiBasePath: "/api/auth",
            websiteBasePath: "/auth"
        },
        recipeList: [
            // ThirdParty.init({
            //     signInAndUpFeature: {
            //         providers: [
            //             Google.init(),
            //         ]
            //     }
            // }),
            EmailPassword.init(),
            Session.init()
        ],
        getRedirectionURL: async (context) => {
            if (context.action === "SUCCESS" && context.newSessionCreated) {
                const fetchTenantAndCompany = async (tenantId: number) => {
                    try {
                        // Fetch tenant details
                        const tenantResponse = await axios.get(`${API_URL}/tenants/${tenantId}`);
                        const fetchedTenantId = tenantResponse.data.id;
                        localStorage.setItem('tenantId', tenantResponse.data.id)

                        // Fetch companies associated with the tenant
                        const companiesResponse = await axios.get(`${API_URL}/company/bytenant/${fetchedTenantId}`);
                        const companies = companiesResponse.data;
                        if (companies && companies.length > 0) {
                            const newcompanyId = companies[0].id;
                            localStorage.setItem('companyId', newcompanyId)
                        } else {
                            throw new Error('No companies found for the tenant.');
                        }
                    } catch (err: any) {
                        console.error('Error fetching tenant and company data:', err);
                    }
                };
                let user = await getUser();
                localStorage.setItem('user', JSON.stringify(user));
                // set the user data so we can retrieve later
                await fetchTenantAndCompany(user.defaultTenantId);

                return "/home";
            }
            return undefined;
        },
    });
}
